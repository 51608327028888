import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store'

export type SessionTimeoutDialogState = {
  show: boolean
}

const initialState: SessionTimeoutDialogState = {
  show: false,
}

export const sessionTimeoutDialogSlice = createSlice({
  name: 'sessionTimeoutDialog',
  initialState,
  reducers: {
    open: state => {
      state.show = true
    },
    close: state => {
      state.show = false
    },
  },
})

export const { open, close } = sessionTimeoutDialogSlice.actions

export const selectSessionTimeoutDialogStatus = (state: RootState) => ({ ...state.sessionTimeoutDialog })

export default sessionTimeoutDialogSlice.reducer
