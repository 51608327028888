import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'

import { getTenantAPIStatus, selectTenantsStatus } from 'slices/tenantsSlice'

import { Chart, PeriodSelect } from 'components/common'
import { PERIOD_LIST } from 'components/common/utils'
import { createLineChartOptions } from 'components/common/Chart/Chart'

type Props = {
  tenantId: number
}

const TenantApiState: React.FC<Props> = ({ tenantId }) => {
  const [period, setPeriod] = React.useState<{ start: Date; end: Date }>({
    start: moment().subtract(PERIOD_LIST.DAYS_15, 'days').toDate(),
    end: moment().subtract(1, 'days').toDate(),
  })

  const dispatch = useDispatch()
  React.useEffect(() => {
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getTenantAPIStatus(tenantId, from, to))
  }, [dispatch, period, tenantId])
  const { apiStatus } = useSelector(selectTenantsStatus, shallowEqual)
  const lastCountedAt = React.useMemo(
    () => (apiStatus ? moment(apiStatus.lastCountedAt).format('YYYY/MM/DD HH:mm:ss') : ''),
    [apiStatus]
  )

  const chartOptions = React.useMemo(() => {
    const dates = apiStatus ? apiStatus.data.map(value => value.date) : []
    const counts = apiStatus ? apiStatus.data.map(value => value.count) : []
    const tickInterval = Math.ceil(dates.length / 7)
    const options = createLineChartOptions({
      xAxis: {
        data: dates,
      },
      yAxis: [
        {
          type: 'area',
          color: '#0075e3',
          data: counts,
          name: '',
        },
      ],
    })
    _.merge(options, {
      xAxis: {
        tickInterval,
      },
    })
    return options
  }, [apiStatus])

  return (
    <Card className="mb-3">
      <CardBody>
        <CardTitle className="font-large fw-bold">APIご利用状況</CardTitle>
        <div>テナントの日別のAPI呼び出し回数</div>

        <div className="d-flex justify-content-end">
          <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
        </div>

        <CardBody>
          <Chart options={chartOptions} />
        </CardBody>
      </CardBody>

      <CardBody className="d-flex text-muted align-self-end pt-0">
        <i className="icf-updated align-self-center pe-1" />
        <small>{lastCountedAt}</small>
      </CardBody>
    </Card>
  )
}

export default TenantApiState
