import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import { confirmPasswordReset, selectUsersStatus, clearErrorMessage } from 'slices/usersSlice'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import styles from './styles.module.scss'

type Props = {
  email: string
}

const NewPasswordSet: React.FC<Props> = (props: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [confirmationCode, setConfirmationCode] = React.useState<string | undefined>(undefined)
  const [newPassword, setNewPassword] = React.useState<string | undefined>(undefined)
  const [newPasswordAgain, setNewPasswordAgain] = React.useState<string | undefined>(undefined)
  const [confirmationCodeValidity, setConfirmationCodeValidity] = React.useState(false)
  const [newPasswordValidity, setNewPasswordValidity] = React.useState(false)
  const [newPasswordAgainValidity, setNewPasswordAgainValidity] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const onSubmitNewPassword = (e: React.MouseEvent) => {
    e.preventDefault()
    setSubmitted(true)
    dispatch(confirmPasswordReset({ email: props.email, code: confirmationCode!, password: newPassword! }))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      navigate('/password-reset-complete')
    } else if (errorMessage === UNREACHABLE_ERROR_STATUS_CODE) {
      // UNREACHABLE_ERROR_STATUS_CODEのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage(undefined)
    } else {
      // ログイン不要ページでUNAUTHORIZED_ERROR_STATUS_CODEのときはNotificationを出す
      setModalErrorMessage('パスワードの再設定に失敗しました。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, navigate])

  const disabled = React.useMemo(
    () =>
      !(
        confirmationCode &&
        confirmationCodeValidity &&
        newPassword &&
        newPasswordValidity &&
        newPasswordAgain &&
        newPasswordAgainValidity
      ),
    [
      confirmationCode,
      confirmationCodeValidity,
      newPassword,
      newPasswordValidity,
      newPasswordAgain,
      newPasswordAgainValidity,
    ]
  )

  return (
    <SinglePageCard
      subtitle="パスワード再設定"
      errorMessage={modalErrorMessage}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
    >
      <Container>
        <Row>
          <Col className="text-center">
            入力いただいたメールに確認コードを送信しました。メールを確認の上、確認コードを入力し、新しいパスワードを作成してください。
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <InputFormat
              label="確認コード"
              placeholder="確認コード"
              className="mt-4"
              value={confirmationCode}
              size="middle"
              maxLength={100}
              onChange={value => setConfirmationCode(value)}
              validations={[Rules.Required]}
              onValidate={setConfirmationCodeValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center">新しいパスワードを作成してください。</Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <InputFormat
              label="新しいパスワード"
              placeholder="新しいパスワード"
              className="mt-4"
              value={newPassword}
              type="password"
              size="middle"
              onChange={value => setNewPassword(value)}
              validations={[Rules.Required]}
              onValidate={setNewPasswordValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <InputFormat
              label="パスワード（確認）"
              placeholder="パスワード（確認）"
              className="mt-4"
              value={newPasswordAgain}
              type="password"
              size="middle"
              onChange={value => setNewPasswordAgain(value)}
              validations={[Rules.Required, Rules.PasswordConfirmation(newPassword)]}
              onValidate={setNewPasswordAgainValidity}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button disabled={disabled} color="primary" className={styles.button} onClick={onSubmitNewPassword}>
              パスワード再設定
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default NewPasswordSet
