import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { DropdownItem, Nav, Navbar, NavbarText, NavbarToggler } from 'reactstrap'

import { TenantStatus } from 'api/tenants'
import { Role } from 'api/users'

import { selectSessionStatus } from 'slices/sessionSlice'

import { DropdownList } from 'components/common'
import logo from 'components/common/SinglePageCard/logo.svg'

import styles from './HeaderNavbar.module.scss'

type Menu = {
  label: string
  action: () => void
}
export type DropdownMenu = {
  name: string
  menu: Menu[]
}
type Props = {
  label: string | undefined
  dropdownMenu: DropdownMenu
  onNavbarTogglerClick: () => void
}

const HeaderNavbar: React.FC<Props> = props => {
  const {
    label,
    onNavbarTogglerClick,
    dropdownMenu: { name, menu },
  } = props
  const [open, setOpen] = React.useState(false)
  const [nameStyles, setNameStyles] = React.useState<React.CSSProperties>({
    minWidth: 220,
    maxWidth: window.innerWidth / 3,
  })

  React.useEffect(
    () =>
      window.addEventListener('resize', () =>
        setNameStyles({
          minWidth: 220,
          maxWidth: window.innerWidth / 3,
        })
      ),
    []
  )
  const toggleContent = (
    <div className="text-end text-truncate" style={nameStyles} role="button">
      {name}
    </div>
  )

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const isStoppedTenant = user.role !== Role.TenantAdmin && user.tenants[0]?.status !== TenantStatus.Active

  return (
    <Navbar color="white" light className="sticky-top shadow-sm">
      <NavbarToggler className={styles.navbarToggler} hidden={isStoppedTenant} onClick={onNavbarTogglerClick} />
      <Nav className="me-auto" navbar>
        {label ? (
          <NavbarText tag="h6" className="ps-3 pb-0">
            {label}
          </NavbarText>
        ) : null}
      </Nav>

      <img className={styles.logo} src={logo} alt="Smile Board Connect" />

      <NavbarText tag="h6" className="pb-0">
        <DropdownList open={open} setOpen={() => setOpen(!open)} content={toggleContent}>
          {menu.map((m, index) => (
            <React.Fragment key={m.label}>
              <DropdownItem onClick={m.action}>{m.label}</DropdownItem>
              {index < menu.length - 1 && <DropdownItem divider />}
            </React.Fragment>
          ))}
        </DropdownList>
      </NavbarText>
    </Navbar>
  )
}
export default HeaderNavbar
