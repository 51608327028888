import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'

import { CELL_WIDTH } from 'components/common/utils'
import type { ColorType } from 'components/common/types'

import styles from './ShiftInputScale.module.scss'

export type ScheduleType = {
  scheduleTypeId: number
  name: string
  color: ColorType | 'secondary'
}

export type InputScheduleType = {
  workerId: number
  startX: number
  endX: number
}

type Props = {
  show: boolean
  businessStartTime: string
  businessEndTime: string
  shiftBarWidth: number
  inputSchedule?: InputScheduleType
  selectedScheduleType?: ScheduleType
  activeRange?: Array<[number, number]>
  onClick: (x: number) => void
}

const TimeScalePopup: React.FC<Props> = ({
  show,
  businessStartTime,
  businessEndTime,
  shiftBarWidth,
  inputSchedule,
  selectedScheduleType = { scheduleTypeId: 0, name: '', color: 'secondary' },
  activeRange = [[0, shiftBarWidth - 1]],
  onClick,
}) => {
  const start = moment(`2000-01-01 ${businessStartTime}:00`)
  const end = moment(`2000-01-01 ${businessEndTime}:00`)
  const blocks = (end.unix() - start.unix()) / 900

  const visible = React.useCallback(
    (index: number) => activeRange.some(range => range[0] <= index && index <= range[1]),
    [activeRange]
  )

  const getOverBarItems = React.useCallback(
    (range: [number, number]) => {
      const inputStart = inputSchedule?.startX
      const inputEnd = inputSchedule?.endX

      if (!inputStart || !inputEnd) {
        return undefined
      }
      if ((inputStart < range[0] && inputEnd < range[0]) || (range[1] < inputStart && range[1] < inputEnd)) {
        return undefined
      }
      return {
        startX: inputStart <= range[0] ? range[0] : inputStart,
        endX: range[1] <= inputEnd ? range[1] : inputEnd,
      }
    },
    [inputSchedule?.endX, inputSchedule?.startX]
  )

  const overBarItems = React.useMemo(() => {
    const items = _.compact(activeRange.map(range => getOverBarItems(range)))
    return items.length > 1 ? [items[0]] : items
  }, [activeRange, getOverBarItems])

  const blockItems = React.useMemo(
    (): Array<{ bg?: string; style?: React.CSSProperties }> =>
      // eslint-disable-next-line no-shadow
      [...Array(blocks)].map((_, index) => {
        if (visible(index) && index === inputSchedule?.startX) {
          return { bg: `bg-${selectedScheduleType.color}` }
        } else if (visible(index)) {
          return { bg: 'bg-secondary', style: { opacity: 0.4 } }
        }
        return {}
      }),
    [blocks, inputSchedule?.startX, selectedScheduleType.color, visible]
  )

  const onClickHandler = (index: number, bg?: string) => {
    bg && onClick(index)
  }

  return (
    <>
      {show && (
        <>
          <div className={styles.container} style={{ width: CELL_WIDTH * blocks }}>
            {blockItems.map((item, index) => (
              <div
                key={`block-${index}`}
                className={`${styles.box} ${item?.bg}`}
                style={item?.style}
                onClick={() => onClickHandler(index, item.bg)}
              />
            ))}
          </div>
          {overBarItems.map((item, index) => (
            <div
              key={`bar-${index}`}
              className={`bg-${selectedScheduleType.color} ${styles.inputBox}`}
              style={{
                width: (item.endX - item.startX + 1) * CELL_WIDTH,
                left: item.startX * CELL_WIDTH,
              }}
            >
              {selectedScheduleType.name}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default TimeScalePopup
