import * as React from 'react'
import { Button } from 'reactstrap'

export type MultipleFooterItem = {
  stepNumber?: number
  stepText: string
  disabled: boolean
  decisionButtonClick: () => void
}
type Props = MultipleFooterItem & {
  selectorOpen: boolean
  onCancel: () => void
}
const MultipleFooter: React.FC<Props> = props => {
  const { stepNumber, stepText, disabled, selectorOpen, onCancel, decisionButtonClick } = props

  return (
    <div className="bg-black position-sticky fixed-bottom" style={{ zIndex: 300 }}>
      <div className="d-flex justify-content-between align-items-center mx-4 py-3">
        <div className="bg-white rounded">
          <Button outline onClick={onCancel}>
            キャンセル
          </Button>
        </div>
        <div className="flex-grow-1 text-center fw-bold font-x-large">
          {!!stepNumber && <span className="text-primary px-2">STEP{stepNumber}.</span>}
          <span className="text-white px-2">{stepText}</span>
        </div>
        {!selectorOpen && (
          <div>
            <Button color="primary" className="px-4" disabled={disabled} onClick={decisionButtonClick}>
              決定
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default MultipleFooter
