import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectOptimizationStatus, clearError, createRoster } from 'slices/optimizationSlice'
import { getWorkerList, selectWorkersStatus } from 'slices/workersSlice'
import { getAllGroups, selectGroupsStatus } from 'slices/groupsSlice'
import { getAllScheduleTypes, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { showSuccess } from 'slices/notificationSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  apiKey: string
  magiQannealTenant: string
  magiQannealLocations: string[]
  onCancel: () => void
}

const WorkersOptEngineDialog: React.FC<Props> = ({
  isOpen,
  apiKey,
  magiQannealTenant,
  magiQannealLocations,
  onCancel,
}) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()

  const dispatch = useDispatch()
  const { optimizationError, isRequesting } = useSelector(selectOptimizationStatus, shallowEqual)
  const { workers } = useSelector(selectWorkersStatus, shallowEqual)
  const { allGroups } = useSelector(selectGroupsStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const workspaceIds = React.useMemo(() => workspaces.map(w => w.workspaceId), [workspaces])

  const onSubmit = () => {
    setSubmitted(true)
    magiQannealLocations.forEach(location =>
      dispatch(
        createRoster(
          apiKey,
          magiQannealTenant,
          location,
          moment().format(),
          workspaces,
          allGroups,
          workers,
          allScheduleTypes
        )
      )
    )
  }

  React.useEffect(() => {
    if (!isOpen || isRequesting) {
      return
    }

    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される

      dispatch(clearError())
      onCancel()
    } else if (submitted) {
      dispatch(showSuccess())
      onCancel()
    }
    setSubmitted(false)
  }, [dispatch, isOpen, isRequesting, onCancel, optimizationError, submitted])

  React.useEffect(() => {
    if (isOpen) {
      dispatch(getWorkerList())
      dispatch(getAllScheduleTypes(workspaceIds))
      dispatch(getAllGroups(workspaceIds))
      return
    }
    setModalErrorMessage(undefined)
  }, [apiKey, dispatch, isOpen, workspaceIds])

  return (
    <CustomModal
      isOpen={isOpen}
      title="magiQannealと連携"
      approveLabel="magiQannealと連携"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={onSubmit}
      approveDisabled={!apiKey}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">magiQannealとメンバーデータ（所属ワークスペース・グループ・人時生産性）を共有します。</div>
    </CustomModal>
  )
}

export default WorkersOptEngineDialog
