import axios from 'axios'

import type { UpdateTargetValuesType, WorkPlan } from 'api/works'

import type { CommonParams } from 'slices/utils'

export type WorkLiteResponse = {
  workId: number
  date: string
  workPlan: WorkPlan[]
  workers: number
}

export type WorkLiteListResponse = {
  works: WorkLiteResponse[]
}

export const getWorkLiteList = (
  params: CommonParams,
  workspaceId: number,
  from?: string,
  to?: string
): Promise<WorkLiteListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works_lite`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    const queryParams = from && to ? { from, to } : null
    axios
      .get(url, { headers, params: queryParams })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateTargetValuesLite = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: UpdateTargetValuesType
): Promise<WorkLiteResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/target_values_lite`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
