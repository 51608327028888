import * as React from 'react'
import { Button, ButtonGroup, Col, Label } from 'reactstrap'

import type { ConnectionType } from 'components/common/types'
import { connectionTypes, getConnectionTypeLabel } from 'components/common/utils'

type Props = {
  value: ConnectionType
  onChange: (value: ConnectionType) => void
}

const ConnectionTypeButton: React.FC<Props> = ({ value, onChange }) => {
  const button = React.useCallback(
    (connectionType: ConnectionType) => {
      return (
        <Button
          className="shadow-none"
          outline
          onClick={() => onChange(connectionType)}
          active={value === connectionType}
        >
          {getConnectionTypeLabel(connectionType)}
        </Button>
      )
    },
    [value, onChange]
  )

  return (
    <div className="d-flex align-items-center">
      <Label md={4} className="p-0">
        実績入力方法
      </Label>
      <Col md={8}>
        <ButtonGroup size="sm">
          {button(connectionTypes.None)}
          {button(connectionTypes.Auto)}
          {button(connectionTypes.Manual)}
        </ButtonGroup>
      </Col>
    </div>
  )
}
export default ConnectionTypeButton
