import React from 'react'

const ScrollToTopOnMount: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}

export default ScrollToTopOnMount
