import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, useMatch } from 'react-router-dom'

import { getTenantSummary, selectTenantsStatus, getTenant, getWorkspaceSummary } from 'slices/tenantsSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getDashboardFilter } from 'slices/usersSlice'
import { selectWorksStatus, getWorkByDate } from 'slices/worksSlice'
import { getWorkerCounts, getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BadgeLabel, CustomButton, DropdownButton, MoveDropdown, NavMenu } from 'components/common'

import TotalSummary from './TotalSummary'
import IndividualSummary from './IndividualSummary'
import WorkerCountsCard from './WorkerCountsCard'
import DashboardDateChangeButton from './DashboardDateChangeButton'
import ManualInputDialog from './ManualInputDialog/ManualInputDialog'
import { getManualInputWorkspaces } from './utils'
import CSVManualRecordsInputDialog from './CSVManualRecordsInputDialog'

import type { Moment } from 'moment'

const MANUAL_INPUT_AVAILABLE_PERIOD = 32

const Dashboard: React.FC = () => {
  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())
  const { workspaceId } = useParams<'workspaceId'>()
  const [menuType, setMenuType] = React.useState(workspaceId || 'summary')
  const [selectedCards, setSelectedCards] = React.useState<number[]>([])
  const [selectedGraphs, setSelectedGraphs] = React.useState<number[]>([])
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [workspaceIds, setWorkspaceIds] = React.useState<number[]>([])
  const [isOpenManualInputDialog, setIsOpenManualInputDialog] = React.useState(false)
  const [isOpenCSVManualRecordsInputDialog, setIsOpenCSVManualRecordsInputDialog] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const pathMatch = useMatch('/dashboard/:workspaceId/*')

  const { works } = useSelector(selectWorksStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { tenantSummary } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const tenantId = React.useMemo(() => user.tenants[0].tenantId, [user.tenants])
  const dateQuery = React.useMemo(() => new URLSearchParams(search).get('date'), [search])

  React.useEffect(() => {
    dispatch(getDashboardFilter())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getTenant(tenantId))
    dispatch(getWorkspaceList(tenantId))
  }, [dispatch, tenantId])

  React.useEffect(() => {
    const countsWorkspaceIds = workspaceIds.filter(id => !Number(menuType) || id === Number(menuType))
    dispatch(getWorkerCounts(countsWorkspaceIds, date))
  }, [dispatch, workspaceIds, date, menuType])

  React.useEffect(() => {
    if (menuType === 'summary' || (dateQuery && date !== dateQuery)) {
      return
    }
    dispatch(getWorkspaceSummary(Number(menuType), date))
  }, [dispatch, menuType, openMoment, date, dateQuery])

  React.useEffect(() => {
    if (dateQuery && date !== dateQuery) {
      return
    }
    if (menuType === 'summary') {
      dispatch(getTenantSummary(date, { dashboardFilter: true }))
    } else {
      // シフトが存在しない場合、移動の項目から作業計画を消す必要があるため、エラー時にworksを空にする
      dispatch(getWorkByDate(Number(menuType), date, false, true))
      dispatch(getTenantSummary(date, { dashboardFilter: true, workspaceId: Number(menuType) }))
    }
  }, [dispatch, menuType, date, dateQuery])

  React.useEffect(() => {
    // TotalSummary や IndividualSummary の中で navigate により遷移した場合でも displayItem が動作するよう
    // handleNavMenuClick の中ではなく pathMatch の useEffect で setMenuType を実行する
    setMenuType(pathMatch?.params.workspaceId || 'summary')
  }, [pathMatch])

  React.useEffect(() => {
    if (dateQuery) {
      setDate(dateQuery)
      return
    }
    setDate(openMoment.format('YYYY-MM-DD'))
  }, [dateQuery, openMoment])

  const updateGraphs = React.useCallback(() => {
    const scheduleTypeIds = menuType === 'summary' ? selectedGraphs : selectedCards
    scheduleTypeIds.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
  }, [date, dispatch, menuType, selectedGraphs, selectedCards])

  React.useEffect(() => {
    // 15 分毎に getTenantSummary を定期的に呼ぶ
    const timerId = setTimeout(
      () => {
        updateGraphs()
        setOpenMoment(moment())
      },
      15 * 60 * 1000
    )
    return () => clearTimeout(timerId)
  }, [dispatch, openMoment, menuType, selectedGraphs, selectedCards, date, updateGraphs])

  const menuItems = React.useMemo(() => {
    const items = _.sortBy(tenantSummary?.workspaceData, 'workspaceName').map(data => ({
      type: data.workspaceId.toString(),
      label: data.workspaceName,
    }))
    return [{ type: 'summary', label: 'サマリー' }].concat(items)
  }, [tenantSummary?.workspaceData])

  React.useEffect(() => {
    const ids = tenantSummary?.workspaceData.map(wd => wd?.workspaceId) || []
    if (_.isEqual(workspaceIds, ids)) {
      return
    }
    setWorkspaceIds(ids)
  }, [tenantSummary?.workspaceData, workspaceIds])

  const tenantSummaryData = React.useMemo(
    () => tenantSummary?.workspaceData.find(data => data.workspaceId === Number(menuType)),
    [tenantSummary?.workspaceData, menuType]
  )
  const handleNavMenuClick = (type: string) => {
    if (type === 'summary') {
      navigate(`/dashboard?date=${date}`)
      return
    }
    navigate(`/dashboard/${type}?date=${date}`)
  }

  const movePaths = React.useMemo(() => {
    const items = []
    if (!_.isEmpty(works)) {
      items.push({ label: '作業計画', onClick: () => navigate(`/schedules/${workspaceId}/${works[0].workId}`) })
    }
    if (date === moment().format('YYYY-MM-DD')) {
      items.push({ label: '人員配置', onClick: () => navigate(`/assignment/${workspaceId}`) })
    }
    return items
  }, [navigate, works, workspaceId, date])
  const displayItem = (): JSX.Element => {
    if (menuType === 'summary') {
      return (
        <>
          <TotalSummary
            tenantId={tenantId}
            tenantSummary={tenantSummary}
            selectedGraphs={selectedGraphs}
            setSelectedGraphs={setSelectedGraphs}
            date={date}
          />
          <WorkerCountsCard tenantId={tenantId} close total />
        </>
      )
    }
    if (tenantSummaryData) {
      return (
        <IndividualSummary
          tenantId={tenantId}
          workspaceId={tenantSummaryData.workspaceId}
          tenantSummary={tenantSummary}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          date={date}
        />
      )
    }
    return <div />
  }

  const showManualInput = React.useMemo(() => {
    const selectedWorkspace =
      menuType === 'summary' ? workspaces : workspaces.filter(w => w.workspaceId === Number(menuType))
    const existManualInput = !_.isEmpty(getManualInputWorkspaces(selectedWorkspace))
    const today = moment().startOf('d')
    const diff = moment(today).diff(date, 'd')
    const inPeriod = diff >= 0 && diff <= MANUAL_INPUT_AVAILABLE_PERIOD

    return existManualInput && inPeriod
  }, [workspaces, date, menuType])

  const handleManualInputSuccess = () => {
    setIsOpenManualInputDialog(false)
    updateGraphs()
  }
  const handleCSVManualRecordsInputSuccess = () => {
    setIsOpenCSVManualRecordsInputDialog(false)
    updateGraphs()
  }

  return (
    <NavMenu type={menuType} items={menuItems} onNavMenuClick={handleNavMenuClick} name="dashboard-nav-menu">
      <div>
        <div className="d-flex m-4">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold pe-2">ダッシュボード</div>
            <BadgeLabel label={`${menuType === 'summary' ? 'サマリー' : tenantSummaryData?.workspaceName}`} />
          </div>
          <div className="d-flex ms-auto">
            {menuType !== 'summary' && !_.isEmpty(movePaths) && (
              <div className="me-2">
                <MoveDropdown items={movePaths} name="individual-summary-move-dropdown"></MoveDropdown>
              </div>
            )}
            {showManualInput && (
              <DropdownButton
                buttonLabel="実績入力"
                onClickButton={() => setIsOpenManualInputDialog(true)}
                dropdownItems={[
                  { label: '実績CSV一括入力', onClick: () => setIsOpenCSVManualRecordsInputDialog(true) },
                ]}
                className="me-2"
              />
            )}
            {menuType !== 'summary' && (
              <CustomButton icon="edit" outline className="me-2" onClick={() => navigate(`/workspaces/${workspaceId}`)}>
                ワークスペース設定
              </CustomButton>
            )}
            <DashboardDateChangeButton onChange={setDate} />
          </div>
        </div>
        <div className="mt-3 mx-3">{displayItem()}</div>
        <ManualInputDialog
          isOpen={isOpenManualInputDialog}
          onCancel={() => setIsOpenManualInputDialog(false)}
          date={date}
          menuType={menuType}
          onSuccess={handleManualInputSuccess}
        />
        <CSVManualRecordsInputDialog
          isOpen={isOpenCSVManualRecordsInputDialog}
          onCancel={() => setIsOpenCSVManualRecordsInputDialog(false)}
          date={date}
          onSuccess={handleCSVManualRecordsInputSuccess}
        />
      </div>
    </NavMenu>
  )
}

export default Dashboard
