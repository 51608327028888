import * as React from 'react'

import styles from './styles.module.scss'

type TypedProps = {
  selectedIndex: number
  onChange: (index: number) => void
  children: React.ReactElement[]
}
const PivotOuterIndex: React.FC<TypedProps> = ({ selectedIndex, onChange, children }) => {
  return (
    <>
      <div className={styles.pivotMenu}>
        {children.map((child, i) => {
          return (
            <div
              key={'t' + i}
              className={`d-flex ${styles.label}`}
              onClick={() => onChange(i)}
              data-is-active={i === selectedIndex}
            >
              {child.props.icon && <i className={`icf-${child.props.icon} me-2 font-large`} />}
              {child.props.headerText}
            </div>
          )
        })}
      </div>
      <div className={styles.pivotBody}>
        {children
          .filter((_, i: number) => i === selectedIndex)
          .map((child, i) => {
            return (
              <div key={'i' + i} className={styles.pivotItem}>
                {child}
              </div>
            )
          })}
      </div>
    </>
  )
}

export default PivotOuterIndex
export const PivotItem: React.FC<{ headerText: string; icon?: string; children?: React.ReactNode }> = ({
  children,
}) => <>{children}</>
