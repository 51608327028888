import moment from 'moment'
import * as React from 'react'
import { Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { ja } from 'date-fns/locale/ja'

registerLocale('ja', ja)

type Props = {
  isOpen: boolean
  from?: Date
  to?: Date
  maxRange: number // 選択可能な日数の最大値
  minDate?: Date
  onCancel: () => void
  onChange: (from: Date, to: Date) => void
}

const formatDate = (date: Date) => moment(date).format('YYYY/MM/DD')
export const SingleDateRangePicker: React.FC<Props> = ({ isOpen, from, to, maxRange, minDate, onCancel, onChange }) => {
  const [startDate, setStartDate] = React.useState<Date>()
  const [endDate, setEndDate] = React.useState<Date>()

  React.useEffect(() => from && setStartDate(from), [from])
  React.useEffect(() => to && setEndDate(to), [to])

  const disabled = React.useMemo(() => !startDate || !endDate, [startDate, endDate])

  const handleChange = ([start, end]: [Date, Date]) => {
    // 選択可能範囲内の場合、終了日を設定する
    if (moment(end).diff(start, 'days') < maxRange) {
      setStartDate(start)
      setEndDate(end)
      return
    }

    // 開始日より後で、選択範囲外の場合、選択した日を開始日に設定する
    if (end) {
      setStartDate(end)
      setEndDate(undefined)
      return
    }

    // 開始日より前で、選択範囲外の場合、終了日を未設定にする
    setStartDate(start)
    setEndDate(undefined)
  }

  const handleApprove = () => {
    if (!startDate || !endDate) {
      return
    }
    onChange(startDate, endDate)
  }

  const footerDisplayDate = React.useMemo(() => {
    if (startDate && endDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`
    }
    if (startDate && !endDate) {
      return `${formatDate(startDate)} - `
    }
    return '開始日と終了日を設定'
  }, [startDate, endDate])

  // 選択は可能だが、範囲外は日付をグレーにする
  const disabledDate = (date: Date) => {
    if (!startDate) {
      return null
    }
    const isBeforeStartTime = moment(date).isBefore(startDate)
    const isOverMaxRange = moment(date).isAfter(moment(startDate).add(maxRange - 1, 'days'))
    return isBeforeStartTime || isOverMaxRange ? 'text-gray' : null
  }

  return (
    // このDropdownはDatePickerをpopupさせるためだけに使っていて、外部からのisOpenで開いているため、DropdownToggleは画面上に現れない
    <Dropdown isOpen={isOpen} toggle={onCancel} direction="start">
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={isOpen}>
        <div />
      </DropdownToggle>

      <DropdownMenu className="shadow">
        <div className="mx-2">
          <ReactDatePicker
            fixedHeight
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={moment().subtract(1, 'days').toDate()}
            inline
            selectsRange
            dateFormat="yyyy/MM/dd"
            dateFormatCalendar={'yyyy年MM月'}
            locale="ja"
            dayClassName={disabledDate}
          />
          <hr className="my-1" />
          <div className="text-center fw-bold font-small my-2">{footerDisplayDate}</div>
          <hr className="my-1" />
          <div className="d-flex justify-content-between mt-2">
            <Button
              outline
              size="sm"
              onClick={() => {
                onCancel()
                setStartDate(from)
                setEndDate(to)
              }}
            >
              キャンセル
            </Button>
            <Button color="primary" size="sm" className="ms-2" disabled={disabled} onClick={handleApprove}>
              適用
            </Button>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SingleDateRangePicker
