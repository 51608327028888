import axios from 'axios'

import type { CommonParams } from 'slices/utils'

type ShiftUploadResponse = {
  uploadUrl: string
}

export const shiftUploadUrl = (params: CommonParams, fileName: string): Promise<ShiftUploadResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/shift_upload_url`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { fileName }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const putUploadUrl = (params: CommonParams, url: string, csvContent: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'text/csv',
    }
    axios
      .put(url, csvContent, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}
