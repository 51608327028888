import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { Role } from 'api/users'
import { TenantStatus } from 'api/tenants'
import { UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'

import { login, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'

import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import AutoLogin from './AutoLogin'

import styles from './styles.module.scss'

const Login: React.FC = () => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [email, setEmail] = React.useState<string | undefined>(undefined)
  const [password, setPassword] = React.useState<string | undefined>(undefined)
  const [emailValidity, setEmailValidity] = React.useState(false)
  const [passwordValidity, setPasswordValidity] = React.useState(false)
  const dispatch = useDispatch()
  const { loggedIn, activated, user, errorMessage } = useSelector(selectSessionStatus, shallowEqual)
  const navigate = useNavigate()
  const onSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    if (email && password) {
      dispatch(login({ email, password }))
    }
  }

  React.useEffect(() => {
    if (!loggedIn) {
      return
    }

    // グループ管理画面向け遷移
    if (process.env.REACT_APP_TEAM_MODE === 'true') {
      if (
        (user.role === Role.Admin || user.role === Role.ProcessAdmin) &&
        user.tenants[0]?.status === TenantStatus.Active
      ) {
        navigate('/team-select-workspaces')
      } else {
        navigate('/suspension')
      }
      return
    }

    // 全体管理画面向け遷移
    if (!activated) {
      return navigate('/activate')
    }

    if (user.role === Role.TenantAdmin) {
      navigate('/tenants')
    } else {
      if (user.tenants[0]?.status === TenantStatus.Active) {
        navigate('/dashboard')
      } else {
        navigate('/suspension')
      }
    }
  }, [navigate, loggedIn, activated, user])

  React.useEffect(() => {
    // UNREACHABLE_ERROR_STATUS_CODEのときにはエラーダイアログが出るのでNotificationは出さない
    if (errorMessage === UNREACHABLE_ERROR_STATUS_CODE || errorMessage === '') {
      setModalErrorMessage(undefined)
    } else {
      // ログイン不要ページでUNAUTHORIZED_ERROR_STATUS_CODEのときはNotificationを出す
      setModalErrorMessage('メールアドレスかパスワードが間違っています。')
    }
  }, [errorMessage])

  const disabled = React.useMemo(
    () => !(email && emailValidity && password && passwordValidity),
    [email, emailValidity, password, passwordValidity]
  )

  return (
    <SinglePageCard
      errorMessage={modalErrorMessage}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
    >
      <Container>
        <InputFormat
          label="メールアドレス"
          type="email"
          placeholder="メールアドレス"
          value={email}
          size="large"
          maxLength={100}
          onChange={value => setEmail(value)}
          validations={[Rules.Required]}
          onValidate={setEmailValidity}
        />

        <InputFormat
          label="パスワード"
          type="password"
          placeholder="パスワード"
          value={password}
          size="large"
          maxLength={100}
          onChange={value => setPassword(value)}
          validations={[Rules.Required]}
          onValidate={setPasswordValidity}
        />

        <Row>
          <Col className="text-center mt-3">
            <Link className="text-decoration-none" to="/password-reset">
              パスワードを忘れた方
            </Link>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button disabled={disabled} color="primary" className={styles.button} onClick={onSubmit}>
              ログイン
            </Button>
          </Col>
        </Row>
      </Container>
      {process.env.NODE_ENV === 'development' && <AutoLogin setEmail={setEmail} setPassword={setPassword} />}
    </SinglePageCard>
  )
}

export default Login
