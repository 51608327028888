import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import Popup from 'reactjs-popup'

import type { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  label: string
  time: string
  deleteKey?: string
  onDelete?: (key: string) => void
}

const ShiftPopover: React.FC<Props> = props => {
  const { label, time, deleteKey, onDelete } = props
  const ref = React.useRef<PopupActions>(null)
  const trigger = <div>{label}</div>

  const onDeleteHandler = () => {
    if (deleteKey && onDelete) {
      onDelete(deleteKey)
    }
  }

  return (
    <Popup ref={ref} trigger={trigger} position="bottom center" contentStyle={{ width: '12.5rem' }}>
      <Card>
        <CardBody className="p-2 font-x-small">
          <div className="d-flex justify-content-end font-large">
            {onDelete && <i className="icf-delete" onClick={() => onDeleteHandler()} />}
            <i className="icf-close ps-1" onClick={() => ref.current?.close()} />
          </div>
          <div className="mb-1">{label}</div>
          <div className="mb-1">{time}</div>
        </CardBody>
      </Card>
    </Popup>
  )
}

export default ShiftPopover
