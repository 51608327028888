import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'

import type { PerformanceRateData, PerformanceRate } from 'api/works'

import { TimeScale, ShiftBar } from 'components/common'
import { getShiftBarWidthByDuration } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import PerformanceRatePopover from './PerformanceRatePopover'

import styles from './PerformanceRates.module.scss'

type Props = {
  date: string
  isPast: boolean
  displayRateData: DisplayPerformanceRate[]
  setDisplayRateData: (props: DisplayPerformanceRate[]) => void
  divElement: React.RefObject<HTMLDivElement>
  onScroll: () => void
}

export type DisplayPerformanceRate = PerformanceRate & {
  name: string
}
const ProductivitySetting: React.FC<Props> = ({
  date,
  isPast,
  displayRateData,
  setDisplayRateData,
  divElement,
  onScroll,
}) => {
  const { businessStartTime, businessDuration, getTimesByShiftBarX, getShiftBarXbyStartTime } = useBusinessTime()
  const onChangePerformanceRate = (value: number, scheduleTypeId: number, index: number) => {
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        const newData = rate.data.map((data, idx) => (idx === index ? { ...data, performanceRateValue: value } : data))
        return {
          ...rate,
          data: newData,
        }
      }
      return rate
    })
    setDisplayRateData(newDisplayRateData)
  }

  const onDeletePerformanceRate = (scheduleTypeId: number, index: number) => {
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        // eslint-disable-next-line no-shadow
        const newData = rate.data.filter((_, idx) => idx !== index)
        return {
          ...rate,
          data: newData,
        }
      }
      return rate
    })
    setDisplayRateData(newDisplayRateData)
  }

  const getShiftBarItems = (data: PerformanceRateData[], scheduleTypeId: number) => {
    return data.map((rate, index) => {
      const x = getShiftBarXbyStartTime(rate.startTime)
      const width = getShiftBarWidthByDuration(rate.duration)
      const strValue = rate.performanceRateValue === null ? '' : _.floor(rate.performanceRateValue, 2).toFixed(2)
      return {
        id: `sift-bar-item-${scheduleTypeId}-${rate.performanceRateId}-${index}`,
        content: (
          <PerformanceRatePopover
            startTime={rate.startTime}
            duration={rate.duration}
            performanceRateValue={strValue}
            disabled={isPast}
            onChange={value => onChangePerformanceRate(value, scheduleTypeId, index)}
            onDelete={() => onDeletePerformanceRate(scheduleTypeId, index)}
          />
        ),
        x,
        width,
      }
    })
  }

  const handleShiftBarChange = (index: number, x: number, width: number, scheduleTypeId: number) => {
    if (!date) {
      return
    }
    const time = getTimesByShiftBarX(x)
    const startTime = moment(`${date} ${time.hours}:${time.minutes}`, 'YYYY-MM-DD HH:mm').utc().format()
    const duration = width * 900

    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        return {
          ...rate,
          data: [
            ...rate.data.slice(0, index),
            {
              ...rate.data[index],
              startTime,
              duration,
            },
            ...rate.data.slice(index + 1),
          ],
        }
      }
      return rate
    })

    setDisplayRateData(newDisplayRateData)
  }

  const handleShiftBarAdd = (startPos: number, endPos: number, scheduleTypeId: number) => {
    if (!date) {
      return
    }

    const time = getTimesByShiftBarX(startPos)
    const startTime = moment(`${date} ${time.hours}:${time.minutes}`, 'YYYY-MM-DD HH:mm').utc().format()
    const newDisplayRateData = displayRateData.map(rate => {
      if (rate.scheduleTypeId === scheduleTypeId) {
        return {
          ...rate,
          data: [
            ...rate.data,
            {
              startTime,
              performanceRateId: null,
              performanceRateValue: 1.0,
              duration: (endPos - startPos) * 900,
            },
          ],
        }
      }
      return rate
    })

    setDisplayRateData(newDisplayRateData)
  }

  return (
    <div className={styles.container} ref={divElement} onScroll={onScroll}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableHeader}`}>作業</td>
            <td className="p-0">
              <TimeScale />
            </td>
          </tr>
        </thead>
        <tbody>
          {displayRateData.map(rate => (
            <tr className={styles.tableRow} key={`tr-${rate.scheduleTypeId}`}>
              <td className={`px-4 ${styles.tableHeader} `}>
                <div className="text-truncate">{rate.name}</div>
              </td>
              <td className={styles.tableContent}>
                <ShiftBar
                  key={rate.name}
                  items={getShiftBarItems(rate.data, rate.scheduleTypeId)}
                  businessStartTime={businessStartTime}
                  shiftBarWidth={businessDuration}
                  disabled={isPast}
                  onChange={(index, x, width) => handleShiftBarChange(index, x, width, rate.scheduleTypeId)}
                  onAdd={(startPos, endPos) => handleShiftBarAdd(startPos, endPos, rate.scheduleTypeId)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProductivitySetting
