import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalBody, ModalFooter, Button } from 'reactstrap'

import { selectSessionStatus } from 'slices/sessionSlice'
import { close, selectSessionTimeoutDialogStatus } from 'slices/sessionTimeoutDialogSlice'

import { Modal } from 'components/common'

import useLogout from 'hooks/useLogout'

const SessionTimeoutDialog: React.FC = () => {
  const { show } = useSelector(selectSessionTimeoutDialogStatus, shallowEqual)
  const { loggedIn } = useSelector(selectSessionStatus, shallowEqual)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logout } = useLogout()
  const toLogin = () => {
    dispatch(close())
    logout()
  }

  React.useEffect(() => {
    if (!loggedIn && !/^\/(login|signup|activate|password-reset)/.test(pathname)) {
      navigate('/login')
    }
  }, [pathname, loggedIn, navigate])

  return (
    <Modal isOpen={show} zIndex={3000}>
      <ModalBody className="font-large fw-bold">セッションタイムアウト</ModalBody>
      <ModalBody className="pb-4">無操作状態が続いたため、ログアウトしました。再度ログインしてください。</ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button color="primary" onClick={toLogin}>
          ログインページへ
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SessionTimeoutDialog
