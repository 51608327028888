import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { clearErrorMessage, createSkill, selectSkillsStatus } from 'slices/skillsSlice'

import { InputFormat, CustomModal } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const SkillCreate: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const [skillName, setSkillName] = React.useState<string | undefined>(undefined)
  const [skillNameValidity, setSkillNameValidity] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectSkillsStatus, shallowEqual)

  const disabled = React.useMemo(() => !(skillName && skillNameValidity), [skillName, skillNameValidity])

  const handleSaveClick = () => {
    if (skillName) {
      setSubmitted(true)
      dispatch(createSkill(skillName))
    }
  }
  const handleCancelClick = () => {
    setSkillName(undefined)
    setSkillNameValidity(false)
    setModalErrorMessage(undefined)
    onCancel()
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setSkillName(undefined)
      setSkillNameValidity(false)
      onSuccess()
    } else {
      setModalErrorMessage('保存できませんでした。')
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, errorMessage, isRequesting, onSuccess, dispatch])

  return (
    <CustomModal
      isOpen={isOpen}
      title="スキルの追加"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-2">
        スキルを追加する事で、作業の必須スキルを作業者が持っていない場合、作業をスケジュールする際にアラートメッセージを表示し、計画ミスを防ぐことができます。
        <div className="mt-4">
          <InputFormat
            label="スキル名"
            placeholder="スキル名"
            value={skillName}
            size="middle"
            maxLength={100}
            onChange={value => setSkillName(value)}
            validations={[Rules.Required]}
            onValidate={setSkillNameValidity}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default SkillCreate
