import * as React from 'react'
import { Button, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import type { ColorType } from 'components/common/types'

import styles from './ScheduleTypeSelector.module.scss'

export type ScheduleTypeGroup = {
  workspaceId: number
  workspaceName: string
  items: ScheduleTypeItem[]
}

export type ScheduleTypeItem = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
}

type Props = {
  groups: ScheduleTypeGroup[]
  onSelect: (item: ScheduleTypeItem) => void
  onClickButton: () => void
}

const ScheduleTypeSelector: React.FC<Props> = ({ groups, onSelect, onClickButton }) => {
  const [open, setOpen] = React.useState(false)
  const onClick = (item: ScheduleTypeItem) => {
    onSelect(item)
    setOpen(false)
  }

  return (
    <Dropdown isOpen={open} name="schedule-type-selector" toggle={() => setOpen(!open)} direction="start">
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={open}>
        <Button
          outline
          className="d-flex align-items-center p-1"
          onClick={() => {
            setOpen(true)
            onClickButton()
          }}
        >
          <i className="icf-compare font-large" />
        </Button>
      </DropdownToggle>

      <DropdownMenu className={`shadow ${styles.popup}`}>
        {groups.map((group, index) => (
          <React.Fragment key={group.workspaceId}>
            <div className="p-2">
              <div className="text-secondary text-truncate mb-1">{group.workspaceName}</div>
              {group.items.map(item => (
                <div
                  key={`${group.workspaceId}-${item.scheduleTypeId}`}
                  className="d-flex align-items-center"
                  role="button"
                  onClick={() => onClick(item)}
                >
                  <div>
                    <div className={`${styles.square} bg-${item.scheduleTypeColor} me-1`} />
                  </div>
                  <div className="text-truncate">{item.scheduleTypeName}</div>
                </div>
              ))}
            </div>
            {index !== groups.length - 1 && <hr className="m-0" />}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ScheduleTypeSelector
