import axios from 'axios'

const AssignmentMode = {
  progress: 'inProgress',
} as const
export type AssignmentModeType = (typeof AssignmentMode)[keyof typeof AssignmentMode]

export type PlanType = {
  workId: number // scheduleTypeId と紐づく
  quota: number
}

export type V2AttendanceType = {
  workerId: string // wmsMemberId と紐づく
  schedule: string
}

export type GetLocationConfigResponse = {
  works: {
    workId: number // scheduleTypeId と紐づく
    workName: string
  }[]
}

export type SkillType = {
  workId: number
  skill: number
}

export type RosterType = {
  workerId: string // wmsMemberId と紐づく
  workerName: string
  group: string | null
  skills: SkillType[]
  flags?: string[]
}

export type CreateAssignmentResponse = {
  assignmentId: string
}

// 作業計画画面で使うAPI用
type CreateTargetValuesRequestType = {
  datetime: string
  planned: PlanType[]
  processed: PlanType[]
  predicted: PlanType[]
}

type CreateAttendanceRequestType = {
  datetime: string
  attendance: V2AttendanceType[]
}

// メンバー一覧画面で使うAPI用
export type CreateRosterRequestType = {
  datetime: string
  roster: RosterType[]
}

type MagiQannealScheduleType = {
  workId: number // scheduleTypeId と紐づく
  schedule: string
}
export type MagiQannealScheduleResponse = {
  workerSchedules: {
    workerId: string // wmsMemberId と紐づく
    assignedSchedule: MagiQannealScheduleType[]
  }[]
  period: {
    datetimeFrom: string
    datetimeTo: string
  }
}

export const getLocationConfig = (
  apiKey: string,
  tenant: string,
  location: string,
  datetime: string
): Promise<GetLocationConfigResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/configs`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .get(url, { headers, params: { datetime } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createRoster = (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateRosterRequestType
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/rosters`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .post(url, request, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getMagiQannealSchedule = (
  apiKey: string,
  tenant: string,
  location: string,
  date: string
): Promise<MagiQannealScheduleResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/schedule`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .get(url, { headers, params: { date } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getLocation = (apiKey: string, tenant: string, location: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .get(url, { headers })
      .then(() => resolve())
      .catch(error => reject(error))
  })
}

export const createTargetValues = (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateTargetValuesRequestType
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/tvalues`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .post(url, request, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createAttendance = (
  apiKey: string,
  tenant: string,
  location: string,
  request: CreateAttendanceRequestType
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_OPTIMIZATION_SERVER}/api/v2/${tenant}/${location}/attendances`
    const headers = { Authorization: `Bearer ${apiKey}` }

    axios
      .post(url, request, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
