import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { ModalBody, ModalFooter, Button, Card, CardBody, CardText, FormGroup, Label, Row, Col, Input } from 'reactstrap'

import { selectWorksStatus } from 'slices/worksSlice'

import { BadgeLabel, Modal, TimeSelect } from 'components/common'
import type { ColorType, SelectedScheduleType } from 'components/common/types'
import { toFitDuringShiftTime, SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'
import SupportConfirm from 'components/SupportConfirm/SupportConfirm'

import useBusinessTime from 'hooks/useBusinessTime'

import TeamWorkerCard, { WorkerCardStatuses } from './TeamWorkerCard'

import styles from './TeamWorkerReassign.module.scss'

import type { WorkerCard } from './TeamWorkerCard'

export type Worker = WorkerCard & {
  scheduleId: number | null
  startAt: string
  duration: number
  skillIds: number[]
}

export type ScheduleEditType = {
  scheduleTypeId: number
  name: string
  color?: ColorType
  workers: Worker[]
  requiredSkills: number[]
}

type Props = {
  isOpen: boolean
  selectedWorkerIds: number[]
  selectedScheduleType: SelectedScheduleType | undefined
  currentScheduleType: SelectedScheduleType | undefined
  editData: ScheduleEditType[]
  currentTime: string
  setEditData: (data: ScheduleEditType[]) => void
  onClose: () => void
  getWorkerPerformance: (workerId: number) => 'good' | 'bad' | 'normal'
}

const isSelected = (data: ScheduleEditType, compare: SelectedScheduleType | undefined) => {
  return data.scheduleTypeId === compare?.scheduleTypeId && data.name === compare.name
}

const TeamWorkerReassign: React.FC<Props> = props => {
  const {
    isOpen,
    selectedWorkerIds,
    selectedScheduleType,
    currentScheduleType,
    editData,
    currentTime,
    setEditData,
    onClose,
    getWorkerPerformance,
  } = props
  const [invalid, setInvalid] = React.useState(false)
  const [openSupportConfirm, setOpenSupportConfirm] = React.useState(false)
  const [onApprove, setOnApprove] = React.useState<() => void>(() => {})
  const [noSkillWorkers, setNoSkillWorkers] = React.useState<Worker[]>([])
  // 直前の予定開始時刻を設定
  const [startHour, setStartHour] = React.useState('00')
  const [startMinute, setStartMinute] = React.useState('00')
  // 直前の予定開始時刻の１時間後を設定
  const [endHour, setEndHour] = React.useState('00')
  const [endMinute, setEndMinute] = React.useState('00')
  const [isEndTime, setEndTime] = React.useState(false)

  const { works } = useSelector(selectWorksStatus, shallowEqual)

  const { businessStartTime, businessEndTime } = useBusinessTime()

  const shifts = React.useMemo(
    () =>
      works
        .find(w => w.date === moment().format('YYYY-MM-DD'))
        ?.groups.flatMap(g =>
          g.workers.flatMap(w => w.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID))
        ),
    [works]
  )

  const roundedTime = React.useMemo(() => {
    const minutes = moment(currentTime, 'HH:mm').minutes()
    if (0 <= minutes && minutes < 15) {
      return moment(currentTime, 'HH:mm').minutes(0).format('HH:mm')
    } else if (15 <= minutes && minutes < 30) {
      return moment(currentTime, 'HH:mm').minutes(15).format('HH:mm')
    } else if (30 <= minutes && minutes < 45) {
      return moment(currentTime, 'HH:mm').minutes(30).format('HH:mm')
    }
    return moment(currentTime, 'HH:mm').minutes(45).format('HH:mm')
  }, [currentTime])

  React.useEffect(() => {
    setInvalid(false)
    setNoSkillWorkers([])
    setStartHour(('00' + moment(roundedTime, 'HH:mm').hours()).slice(-2))
    setStartMinute(('00' + moment(roundedTime, 'HH:mm').minutes()).slice(-2))
    setEndHour(('00' + moment(roundedTime, 'HH:mm').add(1, 'hours').hours()).slice(-2))
    setEndMinute(('00' + moment(roundedTime, 'HH:mm').add(1, 'hours').minutes()).slice(-2))
    setEndTime(false)
  }, [isOpen, roundedTime])

  // 選択したワークスペース情報
  const requiredSkills = React.useMemo(
    (): number[] => editData.find(data => isSelected(data, selectedScheduleType))?.requiredSkills || [],
    [editData, selectedScheduleType]
  )

  const workers = React.useMemo(
    (): Worker[] =>
      editData
        .find(data => isSelected(data, currentScheduleType))
        ?.workers.filter(w => selectedWorkerIds.includes(w.workerId)) || [],
    [currentScheduleType, editData, selectedWorkerIds]
  )

  const getUpdateTimes = (worker: Worker) => {
    const inputStartAt = moment(`${startHour}:${startMinute}`, 'HH:mm').utc().format()
    const inputDuration = moment(`${endHour}:${endMinute}`, 'HH:mm').utc().unix() - moment(inputStartAt).unix()
    const shift = _.chain(shifts).filter({ workerId: worker.workerId }).orderBy(['startAt'], ['asc']).value()
    return toFitDuringShiftTime(inputStartAt, inputDuration, shift, isEndTime)
  }

  const updateData = (targetWorkers: Worker[]) => {
    const update = editData.map(data => {
      if (isSelected(data, currentScheduleType)) {
        return {
          ...data,
          workers: data.workers.filter(w => !targetWorkers.map(worker => worker.workerId).includes(w.workerId)),
        }
      } else if (isSelected(data, selectedScheduleType)) {
        return { ...data, workers: data.workers.concat(targetWorkers) }
      }
      return data
    })
    setEditData(update)
    onClose()
  }

  const memberSave = (func: () => void) => {
    if (!selectedScheduleType?.color) {
      setOnApprove(() => func)
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const allWorkersSave = () => {
    // 全メンバーを移動して人員配置画面に戻る
    const updateWorkers: Worker[] = workers
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, status: WorkerCardStatuses.Changed }
      })
      .filter(w => w.duration > 0)
    updateData(updateWorkers)
  }

  const skillWorkersSave = () => {
    // 必須スキルもちのメンバーのみ移動して人員配置画面に戻る
    const skillWorkers: Worker[] = workers
      .filter(worker => requiredSkills.some(skill => worker.skillIds?.includes(skill)))
      .map(w => {
        const { startAt, duration } = getUpdateTimes(w)
        return { ...w, startAt, duration, status: WorkerCardStatuses.Changed }
      })
      .filter(w => w.duration > 0)
    updateData(skillWorkers)
  }

  const onSaveClick = () => {
    // 保存ボタンを押した時の処理
    const flag = moment(`${startHour}:${startMinute}`, 'HH:mm').isSameOrAfter(
      moment(`${endHour}:${endMinute}`, 'HH:mm')
    )
    setInvalid(flag)

    if (!flag) {
      // 時刻判定がvalidだった時必須スキルチェックをする
      const noSkill =
        requiredSkills.length === 0
          ? []
          : workers.filter(worker => requiredSkills.every(skill => !worker.skillIds?.includes(skill)))
      setNoSkillWorkers(noSkill)
      if (noSkill.length === 0) {
        memberSave(allWorkersSave)
      }
    }
  }

  const getWorkerPerformanceHandler = (workerId: number) => {
    return getWorkerPerformance(workerId)
  }

  const isAlert = React.useMemo(() => noSkillWorkers.length > 0, [noSkillWorkers])
  const title = React.useMemo(() => (isAlert ? '必須スキルを持っていません' : '配置変更時間の入力'), [isAlert])

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalBody className="font-large fw-bold">{title}</ModalBody>

        <ModalBody className="font-small">
          {isAlert ? (
            <>
              <CardText className="mx-3">
                以下の作業者は作業に設定されている必須スキルを持っていません。そのまま配置変更しますか？
              </CardText>
              <CardBody className="p-0">
                <Card className="p-2 mx-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {noSkillWorkers.map(worker => (
                      <Col key={`no-skill-worker-${worker.workerId}`} className="p-1">
                        <TeamWorkerCard
                          disabled={true}
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupColor={worker.groupColor}
                          groupName={worker.groupName}
                          performance={getWorkerPerformanceHandler(worker.workerId)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
            </>
          ) : (
            <>
              <CardBody>
                <Card className="p-2 mb-3">
                  <Row md={3} className={`g-0 ${styles.workerCards}`}>
                    {workers.map(worker => (
                      <Col key={`worker-${worker.workerId}`} className="p-1">
                        <TeamWorkerCard
                          disabled={true}
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupColor={worker.groupColor}
                          groupName={worker.groupName}
                          performance={getWorkerPerformanceHandler(worker.workerId)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </CardBody>
              <CardBody className="mb-3">
                <Row className="pb-3">
                  <Col>配置変更先:</Col>
                  <Col md={9} className="d-flex">
                    <BadgeLabel label={selectedScheduleType?.name || ''} color={selectedScheduleType?.color} />
                  </Col>
                </Row>
                <CardText>
                  上記のメンバーの配置変更を行います。配置変更の開始する時間と終了する時間を設定してください。
                </CardText>
              </CardBody>
              <CardBody>
                <FormGroup row>
                  <Label md={3}>開始時間</Label>
                  <Col md={8}>
                    <TimeSelect
                      hour={startHour}
                      minute={startMinute}
                      label="から"
                      onChange={(hour, minute) => {
                        setStartHour(hour)
                        setStartMinute(minute)
                      }}
                      start={businessStartTime}
                      end={businessEndTime}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3}>終了時間</Label>
                  <Col md={4}>
                    <TimeSelect
                      hour={endHour}
                      minute={endMinute}
                      label="まで"
                      onChange={(hour, minute) => {
                        setEndHour(hour)
                        setEndMinute(minute)
                      }}
                      invalid={isEndTime}
                      start={businessStartTime}
                      end={businessEndTime}
                    />
                  </Col>
                  <Label md={4} className="form-check">
                    <Input
                      className="form-check-input"
                      id="end-time"
                      checked={isEndTime}
                      type="checkbox"
                      onChange={e => setEndTime(e.target.checked)}
                    />
                    <Label className="form-check-label" for="end-time">
                      終業時間まで
                    </Label>
                  </Label>
                </FormGroup>
                {invalid && (
                  <CardText className="text-danger">終了時刻は開始時刻より後の時刻を設定してください</CardText>
                )}
              </CardBody>
            </>
          )}
        </ModalBody>

        {isAlert ? (
          <ModalFooter className="d-flex justify-content-between">
            <div className="flex-grow-1">
              <Button outline onClick={onClose}>
                キャンセル
              </Button>
            </div>
            <Button outline onClick={() => memberSave(skillWorkersSave)}>
              スキルを持たない人のみキャンセル
            </Button>
            <Button color="primary" className="ms-2" onClick={() => memberSave(allWorkersSave)}>
              そのまま配置変更
            </Button>
          </ModalFooter>
        ) : (
          <ModalFooter className="d-flex justify-content-between">
            <Button outline onClick={onClose}>
              キャンセル
            </Button>
            <Button color="primary" className="px-4" onClick={onSaveClick}>
              保存
            </Button>
          </ModalFooter>
        )}
      </Modal>

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={`${startHour}:${startMinute}`}
        end={`${endHour}:${endMinute}`}
        name={selectedScheduleType?.name || ''}
        onCancel={() => {
          onClose()
          setOpenSupportConfirm(false)
        }}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}

export default TeamWorkerReassign
