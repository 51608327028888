import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'

export type NotificationState = {
  showError: boolean
  showSuccess: boolean
  showWarning: boolean
  errorMessage: string | undefined
  successMessage: string | undefined
  warningMessage: string | undefined
}

const initialState: NotificationState = {
  showError: false,
  showSuccess: false,
  showWarning: false,
  errorMessage: undefined,
  successMessage: undefined,
  warningMessage: undefined,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<{ errorMessage: string } | undefined>) => {
      state.showError = true
      state.showSuccess = false
      state.showWarning = false
      state.errorMessage = action.payload?.errorMessage
      state.successMessage = undefined
      state.warningMessage = undefined
    },
    showSuccess: (state, action: PayloadAction<{ successMessage: string } | undefined>) => {
      state.showError = false
      state.showSuccess = true
      state.showWarning = false
      state.errorMessage = undefined
      state.successMessage = action.payload?.successMessage
      state.warningMessage = undefined
    },
    showWarning: (state, action: PayloadAction<{ warningMessage: string } | undefined>) => {
      state.showError = false
      state.showSuccess = false
      state.showWarning = true
      state.errorMessage = undefined
      state.successMessage = undefined
      state.warningMessage = action.payload?.warningMessage
    },
    notificationClear: state => {
      state.showError = false
      state.showSuccess = false
      state.showWarning = false
      state.errorMessage = undefined
      state.successMessage = undefined
      state.warningMessage = undefined
    },
  },
})

export const { showError, showSuccess, showWarning, notificationClear } = notificationSlice.actions

export const selectNotificationStatus = (state: RootState) => ({ ...state.notification })

export default notificationSlice.reducer
