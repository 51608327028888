import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'

import type { TenantApplicationSettingType } from 'api/tenants'

import { updateTenant, clearErrorMessage, selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { showError, showSuccess } from 'slices/notificationSlice'

import { List } from 'components/common'
import { MAGIQANNEAL_APPLICATION_ID, getTenantApplications } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import OptEngineSetting from './OptEngineSetting'

import styles from './styles.module.scss'

const TenantServiceCooperation: React.FC = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const [applicationId, setApplicationId] = React.useState<number>()
  const { tenants, tenantId, isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (tenantId) {
      dispatch(getWorkspaceList(tenantId))
    }
  }, [dispatch, tenantId])
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const tenant = React.useMemo(() => tenants.find(t => t.tenantId === tenantId), [tenantId, tenants])
  const applications = React.useMemo(() => getTenantApplications(tenant), [tenant])

  const listItems = React.useMemo(
    () =>
      applications.map(application => ({
        title: application.applicationName,
        id: application.applicationId,
      })),
    [applications]
  )
  const isServices = React.useMemo(() => listItems.length > 0, [listItems])
  React.useEffect(() => setApplicationId(isServices ? listItems[0].id : undefined), [isServices, listItems])
  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage) {
      dispatch(showError())
      dispatch(clearErrorMessage())
    } else {
      dispatch(showSuccess())
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, isRequesting, submitted])

  const handleUpdateTenant = React.useCallback(
    (update: TenantApplicationSettingType) => {
      if (!tenantId || !tenant) {
        return
      }
      const businessStartTime = tenant?.businessStartTime.split(':')
      const businessEndTime = tenant?.businessEndTime.split(':')
      const data = {
        name: tenant.name,
        salesOfficeName: tenant.salesOfficeName || '',
        postalCode: tenant.postalCode || '',
        prefecture: tenant.prefecture || '',
        municipality: tenant.municipality || '',
        otherAddress: tenant.otherAddress || '',
        phoneNumber: tenant.phoneNumber || '',
        personInCharge: tenant.personInCharge || '',
        businessStartHour: businessStartTime[0],
        businessStartMinute: businessStartTime[1],
        businessEndHour: businessEndTime[0],
        businessEndMinute: businessEndTime[1],
      }
      const existApplication = applications.some(app => app.applicationId === update.applicationId)
      const updateApplications = existApplication
        ? applications.map(app =>
            app.applicationId === update.applicationId ? { ...app, options: { ...update.options } } : app
          )
        : [update].concat(applications)
      setSubmitted(true)
      dispatch(updateTenant(tenantId, data, updateApplications))
    },
    [dispatch, tenant, tenantId, applications]
  )

  const selectedComponent = React.useMemo(() => {
    const application = applications.find(app => app.applicationId === applicationId)

    switch (application?.applicationId) {
      case MAGIQANNEAL_APPLICATION_ID:
        return <OptEngineSetting application={application} workspaces={workspaces} updateTenant={handleUpdateTenant} />

      default:
        return (
          <CardBody className="text-center">
            <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
            <div className="font-middle fw-bold py-4">サービスが選択されていません</div>
            <div>サービスを選択して、編集しましょう。</div>
          </CardBody>
        )
    }
  }, [applicationId, applications, handleUpdateTenant, workspaces])

  return (
    <Row className="g-0">
      <Col md={4}>
        <Card className={`position-sticky ${styles.cardContainer}`}>
          {isServices ? (
            <List
              items={listItems}
              selectedId={applicationId}
              onAction={setApplicationId as (selected: string | number) => void}
            />
          ) : (
            <CardBody className="text-center">
              <img
                className={`mx-auto my-auto d-block h-75 w-100 ${styles.placeholderImage}`}
                src={placeholder}
                alt=""
              />
              <div className="font-middle fw-bold py-4">連携可能なサービスはありません</div>
            </CardBody>
          )}
        </Card>
      </Col>
      <Col md={8}>
        <Card>{selectedComponent}</Card>
      </Col>
    </Row>
  )
}

export default TenantServiceCooperation
