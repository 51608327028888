import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment/moment'

import { getWorkByDate, selectWorksStatus } from 'slices/worksSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'
import { getTenant } from 'slices/tenantsSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getSkillList } from 'slices/skillsSlice'
import { getGroupList } from 'slices/groupsSlice'

import { roundedMoment } from 'components/common/utils'

import useWorkNotification from './useWorkNotification'
import useBusinessTime from './useBusinessTime'

import type { Moment } from 'moment/moment'

const useAssignment = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const params = useParams<'workspaceId'>()

  const { user, team } = useSelector(selectSessionStatus, shallowEqual)
  const { works } = useSelector(selectWorksStatus, shallowEqual)

  const { setSubmitted } = useWorkNotification()

  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())

  const workspaceId = React.useMemo(() => {
    return Number(params.workspaceId) || team.workspaceId
  }, [params, team])

  const { businessStartTime, businessEndTime } = useBusinessTime()
  const work = React.useMemo(() => works.find(w => w.date === moment().format('YYYY-MM-DD')), [works])
  const { startHour, startMinute } = React.useMemo(() => {
    const queryParams = new URLSearchParams(search)
    const qHour = queryParams.get('hour')
    const qMinute = queryParams.get('minute')

    if (!qHour || !qMinute) {
      const { hour, minute } = roundedMoment(false)
      return { startHour: hour, startMinute: minute }
    }
    return { startHour: qHour, startMinute: qMinute }
    // クエリがない時、TimeSelectの時刻を15分間隔で更新するためにopenMomentを追加
  }, [search, openMoment])

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getWorkByDate(workspaceId, moment().format('YYYY-MM-DD'), false, true))
    dispatch(getGroupList(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    if (startMinute && startHour) {
      setOpenMoment(moment(`${startHour}:${startMinute}`, 'HH:mm'))
    }
  }, [startHour, startMinute])

  const isBetween = React.useCallback(
    (startAt: string, duration: number): boolean => {
      const start = moment(startAt).local().format()
      const end = moment(startAt).add(duration, 'seconds').local().format()

      return openMoment.isBetween(start, end, 'minute', '[)')
    },
    [openMoment]
  )

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }

    // 15 分毎に openMoment を書き換えることで API を定期的に呼ぶ
    const timerId = setTimeout(
      () => {
        dispatch(getWorkByDate(workspaceId, moment().format('YYYY-MM-DD'), false, true))
        setOpenMoment(moment(`${startHour}:${startMinute}`, 'HH:mm'))
      },
      15 * 60 * 1000
    )
    return () => clearTimeout(timerId)
  }, [dispatch, workspaceId, openMoment, startHour, startMinute])

  return {
    startHour,
    startMinute,
    openMoment,
    businessStartTime,
    businessEndTime,
    workspaceId,
    work,
    isBetween,
    setSubmitted,
  }
}

export default useAssignment
