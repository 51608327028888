import * as React from 'react'
import { Col, Nav, NavItem, NavLink } from 'reactstrap'

import styles from './styles.module.scss'

type Item = {
  label: string
  type: string | number
}

type INavMenuProps = {
  type: string | number | undefined
  items: Item[]
  children: React.ReactElement
  onNavMenuClick: (type: string) => void
  name?: string //APMを判別するための文字列
  isOpenSidebar?: boolean
}

const NavMenu: React.FC<INavMenuProps> = ({ type, items, children, onNavMenuClick, name, isOpenSidebar = true }) => {
  return isOpenSidebar ? (
    <div className={styles.container}>
      <Col md={2} className={styles.sidebar}>
        <Nav vertical>
          {items.map(item => (
            <NavItem key={item.type} className={styles.item}>
              <NavLink
                onClick={() => onNavMenuClick(String(item.type))}
                className={item.type === type ? `text-primary ${styles.active}` : ''}
                name={name}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Col>
      <Col md={10} className={styles.childContainer}>
        {children}
      </Col>
    </div>
  ) : (
    <>{children}</>
  )
}
export default NavMenu
