import * as React from 'react'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Input } from 'reactstrap'

import styles from './SelectBox.module.scss'

export type Item = {
  key?: string | number
  value: string
}

type Props = {
  id: string
  items: Item[]
  value: string | undefined
  onSelect: (item: Item) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  invalid?: boolean
  placeholder?: string
  disabled?: boolean
  className?: string
}

export const SelectBox: React.FC<Props> = ({
  id,
  items = [],
  value = '',
  onSelect,
  onBlur = () => {},
  invalid = false,
  placeholder = '',
  disabled = false,
  className,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState<number | null>(null)

  const handleDropdownItemClick = (item: Item) => {
    onSelect(item)
    clean()
  }

  const clean = React.useCallback(() => {
    setActiveItem(null)
    setIsOpen(false)
  }, [])

  return (
    <Dropdown isOpen={isOpen} direction="down" toggle={() => setIsOpen(!isOpen)} className={className}>
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={isOpen} disabled={disabled}>
        <Input
          id={id}
          value={value}
          invalid={invalid}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={() => {}}
          disabled={disabled}
          className="pe-4"
        />
        {!invalid && <i className={`icf-double_carot font-large ${styles.icon}`} />}
      </DropdownToggle>
      <DropdownMenu style={{ maxHeight: '200px', minWidth: '100%', maxWidth: '100%', overflow: 'auto' }}>
        {items.map((item, index) => {
          return (
            <DropdownItem
              key={`${id}-${index}`}
              className={`${styles.item} ${index === activeItem && styles.activeItem} text-truncate`}
              onClick={() => handleDropdownItemClick(item)}
              onMouseOver={() => setActiveItem(index)}
            >
              {item.value}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
export default SelectBox
