import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectTenantsStatus } from 'slices/tenantsSlice'

// tenantsから営業時間を取得するため、このhooksを使用する前に一度getTenantsを実行する必要がある
// 複数回リクエストされないようにhooks内でリクエストは行わない
const useBusinessTime = () => {
  const { tenants } = useSelector(selectTenantsStatus, shallowEqual)

  const businessTime = React.useMemo(() => {
    const start = tenants[0]?.businessStartTime || '06:00'
    const end = tenants[0]?.businessEndTime || '20:00'
    return {
      businessStartTime: start,
      businessEndTime: end,
      businessDuration: (moment(end, 'HH:mm').unix() - moment(start, 'HH:mm').unix()) / 900,
    }
  }, [tenants])

  const { businessHourBlocks, business15MinBlocks } = React.useMemo(() => {
    const beginningOfDay = moment().startOf('day')
    const start = moment(businessTime.businessStartTime, 'HH:mm')
    const end = moment(businessTime.businessEndTime, 'HH:mm')
    const hourStart = Math.floor((start.unix() - beginningOfDay.unix()) / 3600)
    const hourEnd = Math.ceil((end.unix() - beginningOfDay.unix()) / 3600)
    const minOffset = Math.ceil((start.unix() - beginningOfDay.unix()) / 900)
    const minBlocks = Math.ceil((end.unix() - start.unix()) / 900)
    return {
      businessHourBlocks: [...Array(hourEnd - hourStart)].map((_, index) => hourStart + index),
      business15MinBlocks: [...Array(minBlocks)].map((_, index) => minOffset + index),
    }
  }, [businessTime])

  const getShiftBarXbyStartTime = React.useCallback(
    (startTime: string) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const businessStart = moment(startTime)
        .local()
        .hours(Number(splitTime[0]))
        .minutes(Number(splitTime[1]))
        .seconds(0)
      const start = moment(startTime).local()
      const delta = start.unix() - businessStart.unix() // businessStartTimeからの経過時間(秒)
      return delta / 900
    },
    [businessTime]
  )

  const getTimesByShiftBarX = React.useCallback(
    (x: number) => {
      const splitTime = businessTime.businessStartTime.split(':') as [string, string]
      const time = moment()
        .hours(Number(splitTime[0]))
        .minutes(Number(splitTime[1]))
        .seconds(0)
        .add(15 * x, 'minutes')
      return { hours: time.hours(), minutes: time.minutes() }
    },
    [businessTime]
  )

  return {
    ...businessTime,
    businessHourBlocks,
    business15MinBlocks,
    getShiftBarXbyStartTime,
    getTimesByShiftBarX,
  }
}

export default useBusinessTime
