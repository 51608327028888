import axios from 'axios'

import type { CommonParams } from 'slices/utils'

export type WorkResultsType = {
  scheduleTypeId: number
  hourlyValue: number[] //1時間ごとに24個の配列、インデックス0は営業開始時間の時
}

export type PutManualInputType = {
  workResults: WorkResultsType[]
}

export type GetManualInputResponse = {
  tenantId: number
  workDate: string // YYYY-MM-DD
  businessStartTime: string
  revision: number
  lastUpdate: string
  lastUpdater: string
  workResults: WorkResultsType[]
}

export type UpdateManualInputResponse = {
  revision: number
}

export type GetAggregateStatusResponse = {
  retryInterval: number
  result: boolean
}

export const getWorkResults = (params: CommonParams, workDate: string): Promise<GetManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/work-date/${workDate}/work-results`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const postWorkResults = (
  params: CommonParams,
  workDate: string,
  workResults: PutManualInputType
): Promise<UpdateManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/work-date/${workDate}/work-results`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, workResults, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getAggregateStatus = (
  params: CommonParams,
  workDate: string,
  revision: number
): Promise<GetAggregateStatusResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/work-date/${workDate}/work-results/revision/${revision}/aggregate-status`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const importWorkResults = (
  params: CommonParams,
  workDate: string,
  workResults: FormData
): Promise<UpdateManualInputResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/work-date/${workDate}/work-results-with-file`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
      'Content-Type': 'multipart/form-data',
    }
    axios
      .post(url, workResults, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
