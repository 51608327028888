import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { WorkerEditType } from 'components/Workers/types'
import { connectionTypes } from 'components/common/utils'

const initData: WorkerEditType = {
  name: undefined,
  wmsMemberId: undefined,
  workspace: undefined,
  group: undefined,
  groupLeader: false,
  hiredAt: undefined,
  skills: [],
  performanceIndices: {},
}
const useWorker = () => {
  const [editData, setEditData] = React.useState<WorkerEditType>(initData)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [wmsMemberIdValidity, setWmsMemberIdValidity] = React.useState(false)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const showPerformanceIndices = React.useMemo(
    () => workspaces.flatMap(w => w.scheduleTypes).some(s => s.connectionType !== connectionTypes.None),
    [workspaces]
  )

  const disabled = React.useMemo(() => {
    const performanceValidity = Object.keys(editData.performanceIndices).every(
      key => typeof editData.performanceIndices[key] === 'undefined' || !isNaN(Number(editData.performanceIndices[key]))
    )

    return !(nameValidity && wmsMemberIdValidity && performanceValidity)
  }, [editData, nameValidity, wmsMemberIdValidity])

  return {
    showPerformanceIndices,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    setWmsMemberIdValidity,
    initData,
  }
}
export default useWorker
