import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'

import { NavMenu, BadgeLabel, CustomButton } from 'components/common'

import ReportAverage from './ReportAverage'
import ReportProductivity from './ReportProductivity'
import ReportPlanAccuracy from './ReportPlanAccuracy'
import CsvExportDialog from './CsvExportDialog'

const Reports: React.FC = () => {
  const { workspaceId } = useParams<'workspaceId'>()

  const [openCsvExportDialog, setOpenCsvExportDialog] = React.useState(false)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = React.useState<number | undefined>(undefined)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  React.useEffect(() => {
    if (workspaceId) {
      setSelectedWorkspaceId(Number(workspaceId))
    } else {
      setSelectedWorkspaceId(workspaces[0]?.workspaceId)
    }
  }, [workspaceId, workspaces])
  const workspaceName = React.useMemo(
    () => workspaces.find(w => w.workspaceId === selectedWorkspaceId)?.name || '',
    [selectedWorkspaceId, workspaces]
  )

  const menuItems = React.useMemo(
    () =>
      _.chain(workspaces)
        .map(w => ({ type: w.workspaceId, label: w.name }))
        .sortBy('label')
        .value(),
    [workspaces]
  )

  return (
    <NavMenu
      type={selectedWorkspaceId}
      items={menuItems}
      onNavMenuClick={newWorkspaceId => navigate(`/reports/${newWorkspaceId}`)}
    >
      <div className="mt-3 mx-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={workspaceName} />
            </div>
          </div>
          <CustomButton outline onClick={() => setOpenCsvExportDialog(true)}>
            CSVエクスポート
          </CustomButton>
        </div>

        <ReportAverage workspaceId={selectedWorkspaceId} />
        <ReportProductivity workspaceId={selectedWorkspaceId} />
        <ReportPlanAccuracy workspaceId={selectedWorkspaceId} />
        <CsvExportDialog
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          currentWorkspaceId={selectedWorkspaceId}
        />
      </div>
    </NavMenu>
  )
}

export default Reports
