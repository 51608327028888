import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import type { TenantSummaryResponse } from 'api/dashboard'

import { selectTenantsStatus, getTenantSummary } from 'slices/tenantsSlice'
import { clearErrorMessage, selectUsersStatus, updateDashboardFilter } from 'slices/usersSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { PivotItem, PivotOuterIndex, CustomButton, FilteringButton } from 'components/common'
import type { ColorType } from 'components/common/types'
import { connectionTypes } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import ScheduleTypeProgressCard from './ScheduleTypeProgressCard'
import WorkspaceProgressCards from './WorkspaceProgressCards'
import PerformanceList from './PerformanceList'
import PerformanceGraph from './PerformanceGraph'
import WorkerCountsCard from './WorkerCountsCard'
import placeholderImage from './notSelectedWorkspace.svg'

import styles from './IndividualSummary.module.scss'

type Props = {
  tenantId: number
  workspaceId: number
  tenantSummary: TenantSummaryResponse | undefined
  selectedCards: number[]
  setSelectedCards: (props: number[]) => void
  date: string
}

const IndividualSummary: React.FC<Props> = ({
  tenantId,
  workspaceId,
  tenantSummary,
  selectedCards,
  setSelectedCards,
  date,
}) => {
  const [submitted, setSubmitted] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { workspaceSummary } = useSelector(selectTenantsStatus, shallowEqual)
  const { dashboardFilter, isRequesting, errorMessage } = useSelector(selectUsersStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { businessStartTime, businessEndTime } = useBusinessTime()

  const scheduleTypeProgresses = React.useMemo(() => {
    if (!tenantSummary) {
      return []
    }
    const workspaceData = tenantSummary.workspaceData.find(w => w.workspaceId === workspaceId)
    if (!workspaceData) {
      return []
    }

    const colors: { [key: number]: ColorType } = tenantSummary.hourlyWorkData.reduce((acc, value) => {
      return { ...acc, [value.scheduleTypeId]: value.scheduleTypeColor }
    }, {})
    return _.sortBy(workspaceData.data, 'scheduleTypeId')
      .filter(row => selectedCards.includes(row.scheduleTypeId))
      .map(row => ({
        ...row,
        scheduleTypeColor: colors[row.scheduleTypeId],
      }))
  }, [tenantSummary, workspaceId, selectedCards])

  const relatedWorkspaceProgresses = React.useMemo(() => {
    if (!tenantSummary || !workspaceSummary) {
      return []
    }

    const relatedWorkspaceIds = workspaceSummary.relatedWorkspaceData.map(w => w.workspaceId)
    return tenantSummary.workspaceData
      .filter(data => relatedWorkspaceIds.includes(data.workspaceId))
      .map(data => ({
        workspaceId: data.workspaceId,
        workspaceName: data.workspaceName,
        lastImportedAt: data.data?.[0]?.lastImportedAt,
        scheduleTypeProgresses: data.data.map(row => ({
          scheduleTypeName: row.scheduleTypeName,
          targetCount: row.targetCount,
          planningHour: row.planningHour,
          todayPlanningHour: row.todayPlanningHour,
          planCount: row.planCount,
          recordCount: row.recordCount,
        })),
      }))
  }, [tenantSummary, workspaceSummary])

  const [pivotIndex, setPivotIndex] = React.useState(0)
  const { pathname } = useLocation()
  React.useEffect(() => {
    const nextIndex = /^\/dashboard\/\d+\/performance-list$/.test(pathname)
      ? 1
      : /^\/dashboard\/\d+\/performance-graph\//.test(pathname)
        ? 2
        : /^\/dashboard\/\d+\/workspace$/.test(pathname)
          ? 3
          : 0
    setPivotIndex(nextIndex)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath =
      index === 1
        ? `/dashboard/${workspaceId}/performance-list`
        : index === 2
          ? `/dashboard/${workspaceId}/performance-graph/workspace`
          : index === 3
            ? `/dashboard/${workspaceId}/workspace`
            : `/dashboard/${workspaceId}`
    if (pathname !== nextPath) {
      const dateQuery = date ? `?date=${date}` : ''
      navigate(nextPath + dateQuery)
    }
  }
  const onClickGraphSaveButton = () => {
    setSubmitted(true)
    const updateWorkspaces = dashboardFilter.map(df => {
      if (df.workspaceId !== workspaceId) {
        return df
      }
      const scheduleTypes = df.scheduleTypes.map(scheduleType => ({
        ...scheduleType,
        isFilteredInEachWorkspace: selectedCards.includes(scheduleType.scheduleTypeId),
      }))
      return { workspaceId: workspaceId, scheduleTypes }
    })

    dispatch(updateDashboardFilter({ workspaces: updateWorkspaces }))
  }

  const filterItems = React.useMemo(
    () =>
      tenantSummary?.workspaceData
        .filter(o => o.workspaceId === workspaceId)
        .flatMap(o => o.data)
        .map(data => ({
          key: data.scheduleTypeId,
          label: data.scheduleTypeName,
          checked: selectedCards.includes(data.scheduleTypeId),
        })) || [],
    [tenantSummary?.workspaceData, selectedCards, workspaceId]
  )

  const viewProgressCardSelectButton = React.useMemo(
    () => !_.isEmpty(tenantSummary?.workspaceData.filter(o => o.workspaceId === workspaceId)),
    [tenantSummary, workspaceId]
  )

  React.useEffect(() => {
    const initSelectedCards = dashboardFilter
      .flatMap(df => (df.workspaceId === workspaceId ? df.scheduleTypes : []))
      .filter(scheduleType => scheduleType.isFilteredInEachWorkspace)
      .map(scheduleType => scheduleType.scheduleTypeId)
    setSelectedCards(initSelectedCards)
  }, [dashboardFilter, workspaceId, setSelectedCards])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])
  const handleChangeSelectedCards = (items: number[]) => {
    setSelectedCards(items)
    const diff = items.filter(s => !selectedCards.includes(s))

    if (_.isEmpty(diff)) {
      return
    }
    diff.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
  }

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])

  const tabs = React.useMemo(() => {
    const tabContents = [{ header: '作業別配置人数一覧', key: 'work' }]
    const connectionTypeAutoExists = workspace?.scheduleTypes.some(s => s.connectionType === connectionTypes.Auto)

    if (connectionTypeAutoExists) {
      tabContents.push({ header: 'パフォーマンスリスト', key: 'list' })
      tabContents.push({ header: 'パフォーマンスグラフ', key: 'graph' })
    }

    return tabContents.concat({ header: '連携ワークスペース', key: 'workspace' })
  }, [workspace])

  const showProgressCardField = React.useMemo(
    () => workspace?.scheduleTypes.some(s => s.connectionType !== connectionTypes.None),
    [workspace]
  )

  return (
    <>
      {showProgressCardField && (
        <>
          {viewProgressCardSelectButton && (
            <div className="d-flex justify-content-end pb-2">
              <div className="me-2">
                <FilteringButton
                  items={filterItems}
                  value={selectedCards}
                  onChange={items => handleChangeSelectedCards(items)}
                  label="表示作業の選択"
                  size="sm"
                ></FilteringButton>
              </div>
              <CustomButton outline icon="save" size="sm" onClick={() => onClickGraphSaveButton()}>
                表示作業の保存
              </CustomButton>
            </div>
          )}

          {_.isEmpty(scheduleTypeProgresses) ? (
            viewProgressCardSelectButton && (
              <div className={`d-flex ${styles.placeholderContainer}`}>
                <div className={styles.placeholderImageContainer}>
                  <img className={styles.placeholderImage} src={placeholderImage} alt="notSelectedWorkspace" />
                </div>
                <div className={`my-auto ${styles.placeholderTextContainer}`}>
                  <div className={`${styles.placeholderBoldText} mb-2`}>
                    {'表示する作業を選択してあなたの\nダッシュボードを完成させましょう｡'}
                  </div>
                  {'上の「表示作業の選択」から、ダッシュボードに表示する作業を選択してください。\n' +
                    '「表示作業の保存」を押すことで、あなたに最適なダッシュボードが完成します。\n' +
                    '表示作業はいつでも変更・保存が可能です。'}
                </div>
              </div>
            )
          ) : (
            <Row md={3} className="mb-3 ps-3">
              {scheduleTypeProgresses.map((scheduleTypeProgress, index) => (
                <Col key={`individual-col-${index}`} className="p-0">
                  <ScheduleTypeProgressCard scheduleTypeProgress={scheduleTypeProgress} />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}

      <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
        {tabs.map(({ header, key }) => (
          <PivotItem headerText={header} key={key}>
            {key === 'work' ? (
              <WorkerCountsCard tenantId={tenantId} />
            ) : key === 'list' ? (
              <PerformanceList workspaceId={workspaceId} workspaceSummaryData={workspaceSummary?.workspaceData} />
            ) : key === 'graph' ? (
              <PerformanceGraph
                workspaceId={workspaceId}
                workspaceSummaryData={workspaceSummary?.workspaceData}
                businessStartTime={businessStartTime}
                businessEndTime={businessEndTime}
              />
            ) : key === 'workspace' ? (
              <WorkspaceProgressCards workspaceProgresses={relatedWorkspaceProgresses} />
            ) : (
              <div>{header}のコンテンツ</div>
            )}
          </PivotItem>
        ))}
      </PivotOuterIndex>
    </>
  )
}

export default IndividualSummary
