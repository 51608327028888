import * as React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

type Props = {
  open: boolean
  content: React.ReactNode
  children: React.ReactNode
  direction?: 'up' | 'down' | 'start' | 'end'
  dropdownClassName?: string
  setOpen: () => void
}

export const DropdownList: React.FC<Props> = ({
  open = false,
  content,
  children,
  direction = 'down',
  dropdownClassName,
  setOpen,
}) => {
  return (
    <Dropdown isOpen={open} toggle={setOpen} direction={direction}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={open}>
        {content}
      </DropdownToggle>
      <DropdownMenu className={dropdownClassName}>{children}</DropdownMenu>
    </Dropdown>
  )
}
export default DropdownList
