import * as React from 'react'
import Select from 'react-select'
import moment from 'moment'

type Props = {
  hour: string
  minute: string
  label: string
  invalid?: boolean
  onChange: (hour: string, minute: string) => void
  start?: string
  end?: string
  menuZIndex?: number
  menuPosition?: 'auto' | 'bottom' | 'top'
}

const TimeSelect: React.FC<Props> = ({
  hour,
  minute,
  label,
  invalid,
  onChange,
  start,
  end,
  menuZIndex,
  menuPosition = 'auto',
}) => {
  const { businessStartIndex, businessEndIndex } = React.useMemo(() => {
    if (!start || !end) {
      return { businessStartIndex: undefined, businessEndIndex: undefined }
    }
    const now = moment().startOf('d')

    // eslint-disable-next-line no-shadow
    const businessStartIndex = start && moment(start, 'HH:mm:ss').diff(now, 'm') / 15
    // eslint-disable-next-line no-shadow
    const businessEndIndex = end && moment(end, 'HH:mm:ss').diff(now, 'm') / 15
    return { businessStartIndex, businessEndIndex }
  }, [start, end])
  const options = [...Array(96)]
    .map((_, index): { label: string; value: { hour: string; minute: string } } => {
      const hourLabel = Math.floor(index / 4)
        .toString()
        .padStart(2, '0')
      const minLabel = ((index % 4) * 15).toString().padStart(2, '0')

      return {
        value: { hour: hourLabel, minute: minLabel },
        label: `${hourLabel}:${minLabel}`,
      }
    })
    .filter((_, index) => {
      if (!businessStartIndex || !businessEndIndex) {
        return true
      }
      return index >= businessStartIndex && index <= businessEndIndex
    })

  const defaultValue = React.useMemo(() => {
    const target = options.find(val => val.value.hour === hour && val.value.minute === minute)
    return target || options[0]
  }, [options, hour, minute])

  return (
    <div className="d-flex align-items-center">
      <Select
        options={options}
        defaultValue={options[0]}
        value={defaultValue}
        isDisabled={invalid}
        onChange={val => val && onChange(val.value.hour, val.value.minute)}
        maxMenuHeight={200}
        required
        styles={{ menu: base => (menuZIndex ? { ...base, zIndex: menuZIndex } : base) }}
        menuPlacement={menuPosition}
        noOptionsMessage={() => '選択肢がありません'}
      />
      <span className="ms-2">{label}</span>
    </div>
  )
}
export default TimeSelect
