import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import type { WorkLiteResponse } from 'api/works_lite'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkLiteList, selectWorksLiteStatus } from 'slices/worksLiteSlice'

import { BadgeButton, Table } from 'components/common'
import type { BadgeItem, TableHeaderType, TableCellType } from 'components/common/types'

import styles from './TeamSchedules.module.scss'

const MAX_DATE = 20

const TeamSchedules: React.FC = () => {
  const [selectedBadges, setSelectedBadges] = React.useState<number[]>([])
  const {
    team: { workspaceId },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { works } = useSelector(selectWorksLiteStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const dispatch = useDispatch()
  React.useEffect(() => {
    const from = moment().format('YYYY-MM-DD')
    const to = moment().add(19, 'day').format('YYYY-MM-DD') // 当日から最大20日分
    dispatch(getWorkLiteList(workspaceId, from, to))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  const selectedDatas = React.useCallback(
    (work: WorkLiteResponse) => {
      const data: TableCellType[] = []
      selectedBadges.forEach(selected => {
        const workplan = work.workPlan.find(plan => plan.scheduleTypeId === selected)
        const targetValue = workplan?.targetValue || '-'
        const planValue = workplan ? Math.floor(workplan.planValue) : '-'
        const difference = workplan ? Math.floor(workplan.planValue - workplan.targetValue) : 0

        data.push({ value: targetValue.toLocaleString() })
        data.push({ value: planValue.toLocaleString() })
        data.push({
          value: difference === 0 ? '-' : difference.toLocaleString(),
          className: difference < 0 ? 'text-danger' : '',
        })
      })
      return data
    },
    [selectedBadges]
  )

  const tableData = React.useMemo((): TableCellType[][] => {
    return [...Array(MAX_DATE)].reduce((acc: TableCellType[][], _, index: number) => {
      const date = moment().add(index, 'day')
      const work = works.find(w => w.date === date.format('YYYY-MM-DD'))

      if (!work) {
        return acc
      }

      const data: TableCellType[] = [
        { value: date.format('YYYY/MM/DD(dddd)') },
        { value: work.workers || 0, className: 'border-end' },
      ]

      acc.push(data.concat(selectedDatas(work)))

      return acc
    }, [])
  }, [selectedDatas, works])

  React.useEffect(() => {
    const allScheduleTypeIds = scheduleTypes.filter(type => type.dataConnection).map(type => type.scheduleTypeId)
    setSelectedBadges(allScheduleTypeIds)
  }, [scheduleTypes])

  const badges = React.useMemo(
    (): BadgeItem[] =>
      scheduleTypes
        .filter(type => type.dataConnection)
        .map(type => ({ color: type.color, key: type.scheduleTypeId, label: type.name })),
    [scheduleTypes]
  )

  const header = React.useMemo((): TableHeaderType[] => {
    const tableHeader: TableHeaderType[] = [
      { value: '日付', width: '60%' },
      { value: 'メンバー数', width: '40%', className: 'border-end' },
    ]

    selectedBadges.forEach(key => {
      const badge = badges.find(b => b.key === key)
      if (badge) {
        tableHeader.push({ value: `目標 - ${badge.label}`, width: '128px' })
        tableHeader.push({ value: `計画 - ${badge.label}`, width: '128px' })
        tableHeader.push({ value: `差分 - ${badge.label}`, width: '128px' })
      }
    })
    return tableHeader
  }, [badges, selectedBadges])

  const navigate = useNavigate()
  const onRowClick = (index: number) => {
    const date = tableData[index][0].value as string
    const formatedDate = date.replace(/(\d{4})\/(\d{2})\/(\d{2}).*/g, '$1-$2-$3')
    navigate(`/team-schedules/${formatedDate}`)
  }

  return (
    <>
      <div className="px-4">
        <div className="font-x-large fw-bold py-4">作業計画一覧</div>
        <div className="font-x-small text-muted my-2">リストの表示項目の編集</div>
        <div className="d-flex row my-2">
          <BadgeButton items={badges} selected={selectedBadges} onChange={setSelectedBadges} />
        </div>
      </div>
      <div className="d-flex bg-white mw-100 mt-2">
        <div className="p-0 w-25">
          <Table header={header.slice(0, 2)} body={tableData.map(data => data.slice(0, 2))} onRowClick={onRowClick} />
        </div>
        <div className={styles.scTable}>
          <Table header={header.slice(2)} body={tableData.map(data => data.slice(2))} onRowClick={onRowClick} />
        </div>
      </div>
    </>
  )
}

export default TeamSchedules
