import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Row, Col } from 'reactstrap'

import type { WorkerEditDataType } from 'api/workers'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { getWorker, selectWorkersStatus, updateWorker } from 'slices/workersSlice'
import { selectWorkspacesStatus, getWorkspaceList, clearErrorMessage } from 'slices/workspacesSlice'

import { Notification, VerticalTable } from 'components/common'
import type { VerticalItem } from 'components/common/types'

import useWorker from 'hooks/useWorker'

import PerformanceIndicesInput from './PerformanceIndicesInput'

import styles from './WorkerDetail.module.scss'

const WorkerDetail: React.FC = () => {
  const params = useParams<'workerId'>()
  const workerId = Number(params.workerId)
  const [initialPerformanceIndices, setInitialPerformanceIndices] = React.useState<{
    [scheduleTypeId: string]: string | undefined
  }>({})
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { workers, isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const worker = React.useMemo(() => workers.find(w => w.workerId === workerId), [workers, workerId])

  const { showPerformanceIndices, disabled, editData, setEditData, setNameValidity, setWmsMemberIdValidity } =
    useWorker()

  const { performanceIndices } = editData

  const setPerformanceIndices = React.useCallback(
    (value: { [scheduleTypeId: string]: string | undefined }) =>
      setEditData(prev => ({ ...prev, performanceIndices: value })),
    [setEditData]
  )

  const [forecastColorScheduleTypeIds, setForecastColorScheduleTypeIds] = React.useState<number[]>([])

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    const date = moment().format('YYYY-MM-DD')
    dispatch(getWorker(workerId, false, date, date, false))
  }, [dispatch, workerId])

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const workspace = React.useMemo(
    () => workspaces.find(w => w.workspaceId === worker?.workspaceId),
    [workspaces, worker]
  )

  React.useEffect(() => {
    if (worker?.workspaceId) {
      dispatch(getGroupList(worker?.workspaceId, false))
    }
  }, [dispatch, worker])
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)
  const group = React.useMemo(() => groups.find(g => g.groupId === worker?.groupId), [groups, worker])

  React.useEffect(() => {
    if (!worker) {
      return
    }
    const newPerformanceIndices = worker.performanceIndices.reduce((acc, value) => {
      return { ...acc, [value.scheduleTypeId]: value.index?.toString() }
    }, {})
    setNameValidity(true)
    setWmsMemberIdValidity(true)
    setPerformanceIndices(newPerformanceIndices)
    setInitialPerformanceIndices(newPerformanceIndices) // unchangedの比較で使うため初期状態を保持する
  }, [worker, setPerformanceIndices, setNameValidity, setWmsMemberIdValidity])

  const unchanged = React.useMemo(
    () => _.isEqual(performanceIndices, initialPerformanceIndices),
    [performanceIndices, initialPerformanceIndices]
  )
  const workerInfoList: VerticalItem[] = React.useMemo(
    () => [
      { title: '名前', data: worker?.name },
      { title: '所属', data: workspace?.name },
      { title: '所属グループ', data: group?.name },
      { title: '役割', data: worker?.groupLeader ? 'グループリーダー' : '-' },
    ],
    [group?.name, worker?.groupLeader, worker?.name, workspace?.name]
  )

  const onSubmit = () => {
    if (!worker) {
      return
    }
    setSubmitted(true)

    // 人時生産性設定
    if (!_.isEqual(performanceIndices, initialPerformanceIndices)) {
      const newPerformanceIndices = workspaces
        .flatMap(w => w.scheduleTypes)
        .filter(scheduleType => scheduleType.dataConnection)
        .map(({ scheduleTypeId }) => ({
          scheduleTypeId: Number(scheduleTypeId),
          index: Number(performanceIndices[scheduleTypeId]),
        }))

      const data: WorkerEditDataType = {
        name: worker.name,
        wmsMemberId: worker.wmsMemberId,
        performanceIndices: newPerformanceIndices,
      }
      dispatch(updateWorker(worker.workerId, data))
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setShowSuccess(true)
      setInitialPerformanceIndices(performanceIndices)
      setForecastColorScheduleTypeIds([])
    } else {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        setShowError(true)
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, performanceIndices, dispatch])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">作業者詳細</div>
          <Notification
            success={showSuccess}
            error={showError}
            hide={() => {
              setShowError(false)
              setShowSuccess(false)
            }}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="font-middle fw-bold py-1 mt-3">作業者詳細</div>
          <div className="border-top border-end border-start">
            <VerticalTable items={workerInfoList} titleColumn={3} />
          </div>
          {showPerformanceIndices && (
            <div>
              <div className="font-middle fw-bold pt-5">人時生産性設定</div>
              <div className="py-2">
                全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
              </div>
              <PerformanceIndicesInput
                workerId={workerId}
                forecastColorScheduleTypeIds={forecastColorScheduleTypeIds}
                onFocus={setForecastColorScheduleTypeIds}
                performanceIndices={performanceIndices}
                onChange={setPerformanceIndices}
                initialPerformanceIndices={initialPerformanceIndices}
              />
            </div>
          )}
        </div>
      </div>
      <div className="bg-white border-top position-sticky fixed-bottom">
        <div className="d-flex justify-content-between mx-4 py-3">
          <div className="flex-grow-1">
            <Button outline onClick={() => navigate(-1)}>
              キャンセル
            </Button>
          </div>
          <Row xs="1" className="font-x-small text-muted text-end">
            <Col>最終編集：{worker?.updatedBy}</Col>
            <Col>{moment(worker?.updatedAt).format('YYYY年MM月DD日 HH:mm')}</Col>
          </Row>
          <Button color="primary" className="px-4 ms-3" disabled={unchanged || disabled} onClick={onSubmit}>
            保存
          </Button>
        </div>
      </div>
    </>
  )
}

export default WorkerDetail
