import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { selectSkillsStatus } from 'slices/skillsSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { FilteringButton, FilteringInputField } from 'components/common'
import type { FilterItem } from 'components/common/types'

import styles from './WorkPlanFilter.module.scss'

import type { EditGroupsType } from '../types'

type Props = {
  onChange: React.Dispatch<React.SetStateAction<EditGroupsType[]>>
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>
  shouldReset: boolean
  setShouldReset: React.Dispatch<React.SetStateAction<boolean>>
}
// 同じKeyになり、チェックボックスの未設定の動作に不具合が出るので、分ける
const SKILL_BLANK_ID = -2
const SKILL_BLANK_LABEL = '未設定'
const SCHEDULE_TYPE_BLANK_ID = -1
const SCHEDULE_TYPE_BLANK_LABEL = '未設定'
const WorkPlanFilter: React.FC<Props> = ({ onChange, onOpenChange, shouldReset, setShouldReset }) => {
  const [openFilter, setOpenFilter] = React.useState(false)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const [filterWord, setFilterWord] = React.useState('')
  const [selectedFilterScheduleTypes, setSelectedFilterScheduleTypes] = React.useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = React.useState<number[]>([])

  React.useEffect(
    () =>
      scheduleTypes &&
      setSelectedFilterScheduleTypes(scheduleTypes.map(g => g.scheduleTypeId).concat(SCHEDULE_TYPE_BLANK_ID)),
    [scheduleTypes]
  )

  React.useEffect(() => skills && setSelectedFilterSkills(skills.map(s => s.skillId).concat(SKILL_BLANK_ID)), [skills])

  React.useEffect(() => {
    if (!shouldReset) {
      return
    }
    skills && setSelectedFilterSkills(skills.map(s => s.skillId).concat(SKILL_BLANK_ID))
    scheduleTypes &&
      setSelectedFilterScheduleTypes(scheduleTypes.map(g => g.scheduleTypeId).concat(SCHEDULE_TYPE_BLANK_ID))
    setFilterWord('')
    setShouldReset(false)
  }, [shouldReset, setShouldReset, skills, scheduleTypes])

  const filterScheduleTypeItems = React.useMemo<FilterItem[]>(
    () =>
      scheduleTypes
        .map(s => ({
          key: s.scheduleTypeId,
          label: s.name,
          checked: selectedFilterScheduleTypes.includes(s.scheduleTypeId),
        }))
        .concat({
          key: SCHEDULE_TYPE_BLANK_ID,
          label: SCHEDULE_TYPE_BLANK_LABEL,
          checked: selectedFilterScheduleTypes.includes(SCHEDULE_TYPE_BLANK_ID),
        }),
    [scheduleTypes, selectedFilterScheduleTypes]
  )

  const filterSkillItems = React.useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.skillId, label: s.name, checked: selectedFilterSkills.includes(s.skillId) }))
        .concat({
          key: SKILL_BLANK_ID,
          label: SKILL_BLANK_LABEL,
          checked: selectedFilterSkills.includes(SKILL_BLANK_ID),
        }),
    [skills, selectedFilterSkills]
  )

  React.useEffect(() => {
    onChange(prev =>
      prev.map(g => {
        const workers = g.workers.map(w => {
          // 5まではシステムで予約された作業のため除く
          const workerSchedules = w.schedules.filter(s => s.scheduleTypeId > 5).map(s => s.scheduleTypeId)
          const hasBlankScheduleTypes =
            selectedFilterScheduleTypes.includes(SCHEDULE_TYPE_BLANK_ID) && _.isEmpty(workerSchedules)
          const hasScheduleTypes =
            hasBlankScheduleTypes || !_.isEmpty(_.intersection(selectedFilterScheduleTypes, workerSchedules))

          const hasBlankSkill = selectedFilterSkills.includes(SKILL_BLANK_ID) && _.isEmpty(w.skillIds)
          const hasSelectedSkill = hasBlankSkill || !_.isEmpty(_.intersection(selectedFilterSkills, w.skillIds))

          const hasFilterWord = filterWord === '' || w.name.includes(filterWord) || w.wmsMemberId.includes(filterWord)

          return { ...w, visible: hasScheduleTypes && hasSelectedSkill && hasFilterWord }
        })

        return { ...g, workers }
      })
    )
  }, [selectedFilterScheduleTypes, selectedFilterSkills, filterWord, onChange])
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="me-2">
          <FilteringInputField onChange={setFilterWord} placeholder="名前 / IDで検索" value={filterWord} />
        </div>
        <button
          className={`d-flex align-items-center justify-content-center  ${
            openFilter ? styles.openIconButton : styles.closeIconButton
          }`}
          color="secondary"
          onClick={() => {
            setOpenFilter(prev => {
              onOpenChange(!prev)
              return !prev
            })
          }}
        >
          <i className="icf-filter font-large" />
        </button>
      </div>
      {openFilter && (
        <div className="d-flex mt-2">
          <div className="me-2">
            <FilteringButton
              items={filterScheduleTypeItems}
              onChange={setSelectedFilterScheduleTypes}
              value={selectedFilterScheduleTypes}
              label="作業で絞り込み"
              disabled={_.isEmpty(scheduleTypes)}
            />
          </div>
          <FilteringButton
            items={filterSkillItems}
            onChange={setSelectedFilterSkills}
            value={selectedFilterSkills}
            label="スキルで絞り込み"
            disabled={_.isEmpty(skills)}
          />
        </div>
      )}
    </div>
  )
}

export default WorkPlanFilter
