import axios from 'axios'

export const getPostcodeList = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const url = `/postcode_list.csv`

    axios
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
