import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'

import { SinglePageCard } from 'components/common'

import useLogout from 'hooks/useLogout'

import styles from './styles.module.scss'

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()
  const { logout } = useLogout()

  return (
    <SinglePageCard showTitle={false} subtitle="ページが見つかりませんでした。">
      <Container>
        <Row>
          <Col className="text-center">お探しのページは見つかりませんでした。別の方法でお試しください。</Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button color="primary" className={styles.button} onClick={() => navigate(-1)}>
              戻る
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button color="link" className="text-decoration-none" onClick={() => logout()}>
              ログインに戻る
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default NotFoundPage
