import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/tenants'
import * as UserAPI from 'api/users'
import * as WorkerAPI from 'api/workers'
import * as DashboardAPI from 'api/dashboard'
import { UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'

import { commonParams } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type TenantState = API.TenantListResponse & {
  isRequesting: boolean
  errorMessage: string
  tenantId: number | null
  users: UserAPI.UserResponse[]
  workers: WorkerAPI.WorkerResponse[]
  apiStatus: API.APIStatusResponse | undefined
  dataConnectionInfo: API.DataConnectionInfoResponse | undefined
  forecastProductivity: API.ForecastProductivityResponse | undefined
  tenantSummary: DashboardAPI.TenantSummaryResponse | undefined
  workspaceSummary: DashboardAPI.WorkspaceSummaryResponse | undefined
  downloadUrl: string | undefined
}

const initialState: TenantState = {
  isRequesting: false,
  errorMessage: '',
  tenants: [],
  tenantId: null,
  users: [],
  workers: [],
  apiStatus: undefined,
  dataConnectionInfo: undefined,
  forecastProductivity: undefined,
  tenantSummary: undefined,
  workspaceSummary: undefined,
  downloadUrl: undefined,
}

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getTenantListSuccess: (state, action: PayloadAction<API.TenantListResponse>) => {
      state.isRequesting = false
      state.tenants = action.payload.tenants
      state.tenantId = null
    },
    getTenantSuccess: (state, action: PayloadAction<API.TenantResponse>) => {
      state.isRequesting = false
      const index = state.tenants.findIndex(tenant => tenant.tenantId === action.payload.tenantId)
      state.tenants.splice(index, 1, action.payload)
      state.tenantId = action.payload.tenantId
    },
    getTenantUserListSuccess: (state, action: PayloadAction<UserAPI.UserListResponse>) => {
      state.isRequesting = false
      state.users = _.orderBy(action.payload.users, ['name'], ['asc'])
    },
    createTenantUserSuccess: state => {
      state.isRequesting = false
    },
    getTenantWorkerListSuccess: (state, action: PayloadAction<WorkerAPI.WorkerListResponse>) => {
      state.isRequesting = false
      state.workers = _.orderBy(action.payload.workers, ['name'], ['asc'])
    },
    createTenantSuccess: (state, action: PayloadAction<API.TenantResponse>) => {
      state.isRequesting = false
      state.tenants.push(action.payload)
    },
    deleteTenantUserSuccess: (state, action: PayloadAction<{ userId: string }>) => {
      state.isRequesting = false
      const index = state.users.findIndex(user => user.userId === action.payload.userId)
      state.users.splice(index, 1)
    },
    getTenantAPIStatusSuccess: (state, action: PayloadAction<API.APIStatusResponse>) => {
      state.isRequesting = false
      state.apiStatus = action.payload
    },
    getDataConnectionInfoSuccess: (state, action: PayloadAction<API.DataConnectionInfoResponse>) => {
      state.isRequesting = false
      state.dataConnectionInfo = action.payload
    },
    getForecastProductivitySuccess: (state, action: PayloadAction<API.ForecastProductivityResponse>) => {
      state.isRequesting = false
      state.forecastProductivity = action.payload
    },
    getTenantSummarySuccess: (state, action: PayloadAction<DashboardAPI.TenantSummaryResponse>) => {
      state.isRequesting = false
      if (!state.tenantSummary) {
        state.tenantSummary = action.payload
      }
      const hourlyWorkData = action.payload.hourlyWorkData.reduce((acc, cur) => {
        if (acc.some(hwd => hwd.scheduleTypeId === cur.scheduleTypeId)) {
          return acc.map(data => (data.scheduleTypeId === cur.scheduleTypeId ? cur : data))
        }
        acc.push(cur)
        return acc
      }, state.tenantSummary.hourlyWorkData)

      // ワークスペースの編集によりデータ変更された場合にstate.tenantSummary.workspaceDataを更新するための変数
      const updateWorkspaceData = action.payload.workspaceData.map(wd => {
        const target = state.tenantSummary?.workspaceData.find(w => w.workspaceId === wd.workspaceId)
        if (!target) {
          return wd
        }

        const data = wd.data.map(d => {
          const targetData = target.data.find(td => td.scheduleTypeId === d.scheduleTypeId)
          if (!targetData) {
            return d
          }
          return { ...targetData, scheduleTypeName: d.scheduleTypeName }
        })
        return { data, workspaceId: wd.workspaceId, workspaceName: wd.workspaceName }
      })

      const updateScheduleTypeIds = action.payload.hourlyWorkData.map(hwd => hwd.scheduleTypeId)
      const workspaceData = updateWorkspaceData.map(wd => {
        const data = wd.data.map(d =>
          updateScheduleTypeIds.includes(d.scheduleTypeId)
            ? action.payload.workspaceData.flatMap(w => w.data).find(w => w.scheduleTypeId === d.scheduleTypeId) || d
            : d
        )
        return { ...wd, data }
      })
      state.tenantSummary = { hourlyWorkData, workspaceData }
    },
    getWorkspaceSummarySuccess: (state, action: PayloadAction<DashboardAPI.WorkspaceSummaryResponse>) => {
      state.isRequesting = false
      state.workspaceSummary = action.payload
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<API.CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
    clearTenantSummary: state => (state.tenantSummary = undefined),
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getTenantListSuccess,
  getTenantSuccess,
  getTenantUserListSuccess,
  createTenantUserSuccess,
  getTenantWorkerListSuccess,
  createTenantSuccess,
  deleteTenantUserSuccess,
  getTenantAPIStatusSuccess,
  getDataConnectionInfoSuccess,
  getForecastProductivitySuccess,
  getTenantSummarySuccess,
  getWorkspaceSummarySuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
  clearTenantSummary,
} = tenantsSlice.actions

export const getTenantList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getTenantList(commonParams(getState))
    .then((res: API.TenantListResponse) => dispatch(getTenantListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getTenant =
  (tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenant(commonParams(getState), tenantId)
      .then((res: API.TenantResponse) => dispatch(getTenantSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantUserList =
  (tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenantUserList(commonParams(getState), tenantId)
      .then((res: UserAPI.UserListResponse) => dispatch(getTenantUserListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createTenantUser =
  (tenantId: number, data: UserAPI.CreateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createTenantUser(commonParams(getState), tenantId, data)
      .then(() => dispatch(createTenantUserSuccess()))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantWorkerList =
  (tenantId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    WorkerAPI.getWorkerList(_.merge(commonParams(getState), { tenantId }), false)
      .then((res: WorkerAPI.WorkerListResponse) => dispatch(getTenantWorkerListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createTenant =
  (data: API.TenantEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createTenant(commonParams(getState), data)
      .then((res: API.TenantResponse) => dispatch(createTenantSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenant =
  (tenantId: number, data: API.TenantEditDataType, applications?: API.TenantApplicationSettingType[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenant(commonParams(getState), tenantId, data, applications)
      .then((res: API.TenantResponse) => dispatch(getTenantSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateTenantStatus =
  (tenantId: number, status: API.TenantStatusType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateTenantStatus(commonParams(getState), tenantId, status)
      .then((res: API.TenantResponse) => dispatch(getTenantSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteTenantUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    UserAPI.deleteUser(commonParams(getState), userId)
      .then(() => dispatch(deleteTenantUserSuccess({ userId })))
      .catch((res: AxiosError) => {
        dispatch(apiFailure({ errorMessage: makeErrorMessage(res) }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getTenantAPIStatus =
  (tenantId: number, from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getTenantAPIStatus(commonParams(getState), tenantId, from, to)
      .then((res: API.APIStatusResponse) => dispatch(getTenantAPIStatusSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getForecastProductivity =
  (workerId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getForecastProductivity(commonParams(getState), workerId)
      .then((res: API.ForecastProductivityResponse) => dispatch(getForecastProductivitySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getDataConnectionInfo = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getDataConnectionInfo(commonParams(getState))
    .then((res: API.DataConnectionInfoResponse) => dispatch(getDataConnectionInfoSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getExportDataUrl =
  (data: API.CreateExportDataInfo): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    dispatch(Spinner.start())
    try {
      const { requestId } = await API.createExportData(commonParams(getState), data)
      if (!requestId) {
        return
      }
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const exportDataResponse = await API.getExportData(commonParams(getState), requestId)
        if (exportDataResponse?.downloadUrl) {
          return dispatch(getExportDataUrlSuccess(exportDataResponse))
        }
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const getTenantSummary =
  (date: string, filteringData: DashboardAPI.TenantSummaryFilteringData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    DashboardAPI.getTenantSummary(commonParams(getState), date, filteringData)
      .then((res: DashboardAPI.TenantSummaryResponse) => dispatch(getTenantSummarySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorkspaceSummary =
  (workspaceId: number, date: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    DashboardAPI.getWorkspaceSummary(commonParams(getState), workspaceId, date)
      .then((res: DashboardAPI.WorkspaceSummaryResponse) => dispatch(getWorkspaceSummarySuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectTenantsStatus = (state: RootState) => ({ ...state.tenants })

export default tenantsSlice.reducer
