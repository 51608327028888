import moment from 'moment/moment'
import React from 'react'
import { useLocation } from 'react-router-dom'

const usePathQuery = () => {
  const { search } = useLocation()
  const date = React.useMemo(() => new URLSearchParams(search).get('date') || moment().format('YYYY-MM-DD'), [search])

  return { date }
}

export default usePathQuery
