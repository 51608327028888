import _ from 'lodash'
import moment from 'moment'

import type { WorkspaceResponse } from 'api/workspaces'
import type { WorkspaceSummaryGroup } from 'api/dashboard'

import type { ColorType, TimeReducerType } from 'components/common/types'
import { roundedMoment, ColorTypes, timeDataReducer, connectionTypes } from 'components/common/utils'

export const NULL_GROUP_ID = 0 // groupId=nullの場合は未所属の扱い
export const NULL_GROUP_NAME = '未所属'

export const colorTypeToCode = (color: ColorType): string => {
  switch (color) {
    case ColorTypes.Silver:
      return '#868b8d'
    case ColorTypes.Twilight:
      return '#7080d4'
    case ColorTypes.Sky:
      return '#7ac4e5'
    case ColorTypes.Emerald:
      return '#00be8a'
    case ColorTypes.Magenta:
      return '#e242ad'
    case ColorTypes.Bengal:
      return '#fb8049'
    case ColorTypes.Mustard:
      return '#f3b11b'
    case ColorTypes.Rose:
      return '#f36f8f'
    case ColorTypes.Violet:
      return '#945ecf'
    case ColorTypes.Grass:
      return '#61b326'
    default:
      return '#eeeeee'
  }
}

export const createXAxis = (fromTime: string, toTime: string, every15mins = false, date?: string): string[] => {
  const targetDate = date || moment().format('YYYY-MM-DD')

  // fromTim〜toTimeをカバーする0分始点、終点のx軸のデータを生成する。
  const fixStartTime = moment(`${targetDate} ${fromTime}`, 'YYYY-MM-DD HH:mm').startOf('hour')
  const endTime = moment(`${targetDate} ${toTime}`, 'YYYY-MM-DD HH:mm')
  const fixEndTime = endTime.minute() === 0 ? endTime : endTime.add(1, 'hour').startOf('hour')
  const fixEndHour = fixEndTime.hour() === 0 ? 24 : fixEndTime.hour()

  if (every15mins) {
    return _.range(fixStartTime.hour(), fixEndHour)
      .flatMap(hour => {
        const time = fixStartTime.hour(hour).startOf('hour')
        return [
          time.toISOString(),
          time.add(15, 'minute').toISOString(),
          time.add(15, 'minute').toISOString(),
          time.add(15, 'minute').toISOString(),
        ]
      })
      .concat(fixEndTime.toISOString())
  }
  // 1時間間隔
  return _.range(fixStartTime.hour(), fixEndHour)
    .map(hour => fixStartTime.hour(hour).startOf('hour').toISOString())
    .concat(fixEndTime.toISOString())
}

export type WorkerPerformance = {
  workerId: number
  workerName: string
  workerData: { [key: string]: TimeReducerType }
}

export type GroupPerformance = {
  groupId: number
  groupName: string
  groupData: { [key: string]: TimeReducerType }
  workerDataList: WorkerPerformance[]
}

// パフォーマンスリスト、メンバー詳細画面で使うデータを集計する
export const summarizeWorkspaceSummaryGroup = (groups: WorkspaceSummaryGroup[]): GroupPerformance[] => {
  return groups.map(group => {
    const workerDataList = group.workers
      .map(worker => {
        // worker内のスケジュール毎のデータを整形して1つの配列にまとめる
        const timeDataList = worker.hourlyWorkerData.flatMap(hourlyData =>
          hourlyData.data.map<TimeReducerType>(d => ({
            time: moment(d.time).format('H:mm'),
            planCount: d.planCount,
            recordCount: d.recordCount,
            rate: null,
          }))
        )

        // 同じtimeのデータを集計
        const workerData: { [key: string]: TimeReducerType } = timeDataList.reduce(timeDataReducer, {})

        return {
          workerId: worker.workerId,
          workerName: worker.workerName,
          workerWmsMemberId: worker.workerWmsMemberId,
          workerData,
        }
      })
      .sort((a, b) => a.workerWmsMemberId.localeCompare(b.workerWmsMemberId, 'ja'))

    // group内のworker毎のデータを集計
    const groupData: { [key: string]: TimeReducerType } = workerDataList
      .flatMap(d => Object.values(d.workerData))
      .reduce(timeDataReducer, {})

    return {
      groupId: group.groupId ? group.groupId : NULL_GROUP_ID,
      groupName: group.groupName ? group.groupName : NULL_GROUP_NAME,
      groupData,
      workerDataList,
    }
  })
}

export const getPlanTime = (isCeil = true) => {
  const now = roundedMoment(isCeil)
  return `${now.hour}:${now.minute}`
}
const ceilWithAdjustment = (value: number) => (Object.is(value, -0) ? 0 : value)

type CalculateProductivityType = {
  targetCount: number | null // 目標値
  todayPlanCount: number | null // 1日分の合計計画値
  planCountQuarterHourAgo: number | null // 15分単位で切り下げた計画値
  planningHour: number | null // 15分単位で切り下げた計画時間の合計
  todayPlanningHour: number | null // 当日の計画時間の合計
  recordCountQuarterHourAgo: number | null // 15分単位で切り下げた実績値
}
export const calculateProductivityMetrics = (data: CalculateProductivityType) => {
  const currentProductivity =
    data.recordCountQuarterHourAgo && data.planningHour
      ? ceilWithAdjustment(Math.ceil(data.recordCountQuarterHourAgo / data.planningHour))
      : 0

  const productivityDifferenceRatio =
    data.planCountQuarterHourAgo && data.planningHour
      ? ceilWithAdjustment(
          Math.ceil((currentProductivity / (data.planCountQuarterHourAgo / data.planningHour) - 1) * 100)
        )
      : 0

  const planGoalForecasting =
    data.todayPlanCount && data.targetCount
      ? ceilWithAdjustment(
          Math.ceil(((100 + productivityDifferenceRatio) / 100) * data.todayPlanCount) - data.targetCount
        )
      : 0

  const forecastShortfallHour =
    data.todayPlanCount && data.todayPlanningHour
      ? ceilWithAdjustment(_.floor(planGoalForecasting / Math.ceil(data.todayPlanCount / data.todayPlanningHour), 1))
      : 0

  return { currentProductivity, productivityDifferenceRatio, planGoalForecasting, forecastShortfallHour }
}

export const getManualInputWorkspaces = (workspaces: WorkspaceResponse[]) =>
  workspaces
    .reduce((acc, cur) => {
      const scheduleTypes = cur.scheduleTypes.filter(s => s.connectionType === connectionTypes.Manual)
      return _.isEmpty(scheduleTypes) ? acc : acc.concat({ ...cur, scheduleTypes })
    }, [] as WorkspaceResponse[])
    .filter(w => !_.isEmpty(w))
