import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'reactstrap'

import { Role } from 'api/users'
import type { EditWorkspaceProps } from 'api/workspaces'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenantSummary, getTenantUserList, selectTenantsStatus } from 'slices/tenantsSlice'
import { selectWorkspacesStatus, getWorkspace, updateWorkspace, clearErrorMessage } from 'slices/workspacesSlice'

import { InputFormat, Notification, SelectBoxFormat, SubmitFooter } from 'components/common'
import type { SuggestionItem } from 'components/common/FormFormat/Suggestion'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import WorkspaceAdminEdit from './WorkspaceAdminEdit'
import RelatedWorkspaceEdit from './RelatedWorkspaceEdit'
import WorkspaceDelete from './WorkspaceDelete'

import styles from './WorkspaceEdit.module.scss'

type WorkspaceUpdateType = {
  name: string
  admins: SuggestionItem[]
  members: SuggestionItem[]
  relatedWorkspaces: { id: number; value: string }[]
  autoArchiveTime: string
}

const WorkspaceEdit: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [editData, setEditData] = React.useState<WorkspaceUpdateType>({
    name: '',
    admins: [],
    members: [],
    relatedWorkspaces: [],
    autoArchiveTime: '08:00',
  })
  const [initData, setInitData] = React.useState<WorkspaceUpdateType>({
    name: '',
    admins: [],
    members: [],
    relatedWorkspaces: [],
    autoArchiveTime: '08:00',
  })
  const [nameValidity, setNameValidity] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const navigate = useNavigate()

  const {
    user: { tenants },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { users } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces, isRequesting, errorMessage } = useSelector(selectWorkspacesStatus, shallowEqual)

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getTenantUserList(tenants[0].tenantId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, tenants, workspaceId])
  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])

  React.useEffect(() => {
    if (!workspace) {
      return
    }

    const admins = workspace.memberIds
      .map(memberId => users.find(user => user.userId === memberId))
      .flatMap(user => user ?? [])
      .filter(user => user.role === Role.Admin)
      .map(user => ({ id: user.userId, value: user.name }))
    const members = workspace.memberIds
      .map(memberId => users.find(user => user.userId === memberId))
      .flatMap(user => user ?? [])
      .filter(user => user.role === Role.ProcessAdmin)
      .map(user => ({ id: user.userId, value: user.name }))
    const relatedWorkspaces = workspace.relatedWorkspaceIds
      .map(relatedWorkspaceId => workspaces.find(w => w.workspaceId === relatedWorkspaceId))
      .flatMap(w => w ?? [])
      .map(w => ({ id: w.workspaceId, value: w.name }))

    setEditData({
      name: workspace.name,
      admins,
      members,
      relatedWorkspaces: relatedWorkspaces,
      autoArchiveTime: workspace.autoArchiveTime ? workspace.autoArchiveTime : '',
    })
    setInitData({
      name: workspace.name,
      admins,
      members,
      relatedWorkspaces: relatedWorkspaces,
      autoArchiveTime: workspace.autoArchiveTime ? workspace.autoArchiveTime : '',
    })
  }, [workspace, users, workspaces])

  const disabled = React.useMemo(() => !nameValidity, [nameValidity])

  const unchanged = React.useMemo(() => {
    const edit = {
      name: editData.name,
      memberIds: editData.admins
        .concat(editData.members)
        .map(member => member.id)
        .sort(),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(({ id }) => id).sort(),
      autoArchiveTime: editData.autoArchiveTime,
    }
    const initial = {
      name: initData.name,
      memberIds: initData.admins
        .concat(initData.members)
        .map(member => member.id)
        .sort(),
      relatedWorkspaceIds: initData.relatedWorkspaces.map(({ id }) => id).sort(),
      autoArchiveTime: initData.autoArchiveTime,
    }
    return _.isEqual(edit, initial)
  }, [editData, initData])

  // eslint-disable-next-line no-shadow
  const archiveTimeItems = [...Array(48)].map((_, i) => {
    const h = moment()
      .startOf('day')
      .add(i * 30, 'minutes')
      .format('HH:mm')
    return { value: h }
  })

  const onSubmit = () => {
    if (!workspace) {
      return
    }

    setSubmitted(true)

    const data: EditWorkspaceProps = {
      name: editData.name,
      memberIds: editData.admins.concat(editData.members).map(member => member.id.toString()),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(({ id }) => id),
      autoArchiveTime: editData.autoArchiveTime,
    }
    dispatch(updateWorkspace(workspaceId, data))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      navigate('/workspaces')
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setModalErrorMessage('すでにこの名前のワークスペースは存在しています。')
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, navigate])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">ワークスペースの設定</div>
          <Notification
            errorMessage={modalErrorMessage}
            error={!!modalErrorMessage}
            hide={() => setModalErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle fw-bold">ワークスペースの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名称※"
            placeholder="ワークスペース名を入力"
            value={editData.name}
            maxLength={100}
            onChange={value => setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />

          <div className="font-middle fw-bold py-1 mt-5">ワークスペース管理者の登録</div>
          <div className="mt-2 mb-1">
            ワークスペースの管理者を登録します。オーナーは初期状態で管理者として登録されています。オーナーを管理者から除外することはできません。
          </div>
          <WorkspaceAdminEdit
            admins={editData.admins}
            members={editData.members}
            onChange={(admins, members) => setEditData({ ...editData, admins, members })}
          />

          <div className="font-middle fw-bold py-1 mt-5">連携ワークスペースの設定</div>
          <div className="mt-2 mb-1">
            このワークスペースの前工程となるワークスペース、後工程となるワークスペースを設定しておくことで、ダッシュボードから設定したワークスペースの進捗を確認することができます。
          </div>
          <div className="mt-3">連携するワークスペース</div>
          <RelatedWorkspaceEdit
            workspaceId={workspaceId}
            relatedWorkspaces={editData.relatedWorkspaces}
            onChange={relatedWorkspaces => setEditData({ ...editData, relatedWorkspaces })}
          />

          <div className="font-middle fw-bold py-1 mt-5">作業計画の自動アーカイブ</div>
          <div className="mt-2 mb-1">
            作業計画を自動アーカイブすることで、アーカイブされた作業計画と最終的な作業計画の変更割合を計画変更率として算出します。計画変更率は「レポート」で確認できます。デフォルトでは8:00が設定されています。
          </div>
          <div className="mt-3 mb-5">
            <SelectBoxFormat
              label="自動アーカイブ時刻"
              value={editData.autoArchiveTime}
              size="short"
              items={archiveTimeItems}
              onChange={e => setEditData({ ...editData, autoArchiveTime: e.value })}
            />
          </div>

          <div className="font-middle fw-bold py-1 mt-5">ワークスペースの削除</div>
          <div className="mt-2 mb-1">
            ワークスペースを削除すると、登録してある作業情報などはすべて失われ、復旧できません。
          </div>
          <Button outline color="danger" className="mt-3" onClick={() => setOpenDelete(true)}>
            ワークスペースを削除
          </Button>
        </div>
      </div>
      <SubmitFooter
        onCancel={() => navigate(-1)}
        onSubmit={onSubmit}
        submitDisabled={disabled || unchanged}
        updatedBy={workspace?.updatedBy}
        updatedAt={workspace?.updatedAt}
      />

      <WorkspaceDelete
        isOpen={openDelete}
        workspaceId={workspaceId!}
        onSuccess={() => {
          navigate('/workspaces')
          // ワークスペース削除後にダッシュボードへ遷移すると
          // tenantSummary.workspaceDataに含まれるワークスペースのworker_countsを取得する
          // ただし、削除されたワークスペースも含まれるため、エラーが発生
          // これを防ぐために、削除後にtenantSummaryを更新する
          dispatch(getTenantSummary(moment().format('YYYY-MM-DD'), { dashboardFilter: true }))
        }}
        onCancel={() => setOpenDelete(false)}
      />
    </>
  )
}

export default WorkspaceEdit
