import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/users'
import { UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'

import { commonParams } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type UserListState = API.UserListResponse & {
  isRequesting: boolean
  errorMessage: string
  dashboardFilter: API.DashboardFilterType[]
}
const initialState: UserListState = {
  isRequesting: false,
  errorMessage: '',
  users: [],
  dashboardFilter: [],
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getUserListSuccess: (state, action: PayloadAction<API.UserListResponse>) => {
      state.isRequesting = false
      state.users = action.payload.users
    },
    createUserSuccess: state => {
      state.isRequesting = false
    },
    updateUserRoleSuccess: (state, action: PayloadAction<API.UserResponse>) => {
      state.isRequesting = false
      state.users = state.users.map(user =>
        user.userId === action.payload.userId ? { ...user, ...action.payload } : user
      )
    },
    deleteUserSuccess: (state, action: PayloadAction<{ userId: string }>) => {
      state.isRequesting = false
      const index = state.users.findIndex(user => user.userId === action.payload.userId)
      state.users.splice(index, 1)
    },
    requestPasswordResetSuccess: state => {
      state.isRequesting = false
    },
    confirmPasswordResetSuccess: state => {
      state.isRequesting = false
    },
    dashboardFilterSuccess: (state, action: PayloadAction<API.DashboardFilterResponse>) => {
      state.isRequesting = false
      state.dashboardFilter = action.payload.workspaces
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getUserListSuccess,
  createUserSuccess,
  updateUserRoleSuccess,
  deleteUserSuccess,
  requestPasswordResetSuccess,
  confirmPasswordResetSuccess,
  dashboardFilterSuccess,
} = usersSlice.actions

export const getUserList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getUserList(commonParams(getState))
    .then((res: API.UserListResponse) => dispatch(getUserListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const createUser =
  (data: API.CreateUserProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createUser(commonParams(getState), data)
      .then(() => dispatch(createUserSuccess()))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteUser =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteUser(commonParams(getState), userId)
      .then(() => dispatch(deleteUserSuccess({ userId })))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateUserRole =
  (userId: string, role: API.RoleType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateUserRole(commonParams(getState), userId, role)
      .then((res: API.UserResponse) => dispatch(updateUserRoleSuccess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const requestPasswordReset =
  (data: API.RequestPasswordResetProps): AppThunk =>
  dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    API.requestPasswordReset(data)
      .then(() => dispatch(requestPasswordResetSuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const confirmPasswordReset =
  (data: API.ConfirmPasswordResetProps): AppThunk =>
  dispatch => {
    dispatch(startRequest())
    dispatch(Spinner.start())
    API.confirmPasswordReset(data)
      .then(() => dispatch(confirmPasswordResetSuccess()))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getDashboardFilter = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getDashboardFilter(commonParams(getState))
    .then((res: API.DashboardFilterResponse) => dispatch(dashboardFilterSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const updateDashboardFilter =
  (data: API.DashboardFilterProps): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateDashboardFilter(commonParams(getState), data)
      .then((res: API.DashboardFilterResponse) => dispatch(dashboardFilterSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }
export const selectUsersStatus = (state: RootState) => ({ ...state.users })

export default usersSlice.reducer
