import * as React from 'react'
import { Card } from 'reactstrap'
import Popup from 'reactjs-popup'

import styles from './SingleBar.module.scss'

export type SingleBarItem = {
  label: string
  rate: number
  borderRate: number
  tooltipDiff: number
  tooltipWarning: boolean
}

const SingleBar: React.FC<SingleBarItem> = ({ label, rate, borderRate, tooltipDiff, tooltipWarning }) => {
  const barStyle = {
    width: `${rate}%`,
    backgroundColor: Math.abs(borderRate - rate) >= 10 ? '#e33840' : '#98c3eb',
  }
  const borderStyle = {
    left: borderRate >= 100 ? 'calc(100% - 1px)' : `${borderRate}%`,
  }
  const tooltipNumberStyle = {
    color: tooltipWarning ? '#e33840' : '#ffffff',
  }

  return (
    <Card className="m-2 p-2">
      <div>
        <div>{label}</div>
        <div className="d-flex align-items-center">
          <Popup
            trigger={
              <div className={styles.barContainer}>
                <div className={styles.mainBar} style={barStyle}></div>
                <div className={styles.border} style={borderStyle}></div>
              </div>
            }
            position="bottom center"
            on="hover"
            arrowStyle={{ color: 'black' }}
          >
            <span className="text-white bg-black p-1 px-3 rounded font-small">
              計画との差 <span style={tooltipNumberStyle}>{tooltipDiff}</span>
            </span>
          </Popup>

          <div className={styles.rate}>{rate}%</div>
        </div>
      </div>
    </Card>
  )
}

export default SingleBar
