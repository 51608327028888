import * as React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap'

import { CustomButton, CheckboxGroup } from 'components/common'
import type { FilterItem } from 'components/common/types'

import styles from './GraphSelectButton.module.scss'

type Item = { label: string; filterItems: FilterItem[] }

type Props = {
  items: Item[]
  onChange: (filterItems: number[]) => void
  selectedGraphs: number[]
  disabled?: boolean
}

const GraphSelectButton: React.FC<Props> = ({ items, selectedGraphs, onChange, disabled }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const handleCheckboxChange = (filterItems: FilterItem[]) => {
    const newSelectedGraphs = filterItems.reduce((acc, cur) => {
      if (cur.checked && !acc.includes(cur.key)) {
        return acc.concat(cur.key)
      }
      if (!cur.checked && acc.includes(cur.key)) {
        return acc.filter(key => key !== cur.key)
      }
      return acc
    }, selectedGraphs)
    onChange(newSelectedGraphs)
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle tag="div" disabled={disabled}>
        <CustomButton outline icon="filter" size="sm" className="me-2" disabled={disabled}>
          表示作業の選択
        </CustomButton>
      </DropdownToggle>
      <DropdownMenu end={true}>
        {items.map(({ filterItems, label }) => (
          <React.Fragment key={label}>
            <UncontrolledDropdown setActiveFromChild direction="end">
              <DropdownToggle tag="div" className={`px-3 py-2 d-flex ${styles.dropdownItem}`}>
                <div className="px-1 text-nowrap">{label}</div>
                <i className="font-large d-flex justify-content-end align-items-center w-100 icf-carot_right" />
              </DropdownToggle>
              <DropdownMenu key={label}>
                <div className={styles.checkboxGroup}>
                  <CheckboxGroup filterItems={filterItems} onChange={handleCheckboxChange} />
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default GraphSelectButton
