import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'

import { TimeScale, ShiftBar, ShiftPopover } from 'components/common'
import { getRandomNumber, getShiftBarWidthByDuration, SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './WorkerSchedule.module.scss'

import type { EditShiftsType, WorkPlanSchedulesType } from '../Schedules/types'

type Props = {
  date: string
  editGroups: EditShiftsType[]
  onEditGroupsChange: (items: EditShiftsType[]) => void
}

const WorkerSchedule: React.FC<Props> = ({ date, editGroups, onEditGroupsChange }) => {
  const dispatch = useDispatch()

  // ShiftBar の表示に必要なbusinessStartTime, businessEndTimeを取得する
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])
  const { businessStartTime, businessDuration, getTimesByShiftBarX, getShiftBarXbyStartTime } = useBusinessTime()

  const isPast = React.useMemo(() => moment().isAfter(date, 'day'), [date])
  const isOpen = (group: string) => editGroups.find(g => g.name === group)?.isOpen

  const handleGroupNameClick = (group: string) => {
    const newEditGroups = editGroups.map(g => (g.name === group ? { ...g, isOpen: !g.isOpen } : g))
    onEditGroupsChange(newEditGroups)
  }

  const onDeleteWorkerScheduleType = (
    groupId: number | null,
    scheduleId: number | null,
    workerId: number | undefined
  ) => {
    const newEditGroups = editGroups.map(group => {
      if (group.groupId !== groupId) {
        return group
      }
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s => w.workerId !== workerId || s.scheduleId !== scheduleId),
      }))
      return {
        ...group,
        schedules: group.schedules,
        isOpen: group.isOpen,
        workers,
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const handleShiftBarAdd = (groupId: number | null, startPos: number, endPos: number, workerId?: number) => {
    if (!groupId && !workerId) {
      return
    }

    const time = getTimesByShiftBarX(startPos)
    const startAt = moment(`${date} ${time.hours}:${time.minutes}`, 'YYYY-MM-DD HH:mm').utc().format()
    const addItem: WorkPlanSchedulesType = {
      scheduleId: getRandomNumber(),
      scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt,
      duration: (endPos - startPos) * 900,
      editable: true,
    }

    const newEditGroups = editGroups.map(group => {
      if (group.groupId !== groupId) {
        return group
      }
      return {
        ...group,
        schedules: group.schedules,
        workers: group.workers.map(w => {
          const getSchedules = () => {
            if (w.workerId === workerId) {
              return [...w.schedules, addItem]
            }
            return w.schedules
          }
          return {
            ...w,
            schedules: getSchedules(),
          }
        }),
      }
    })

    onEditGroupsChange(newEditGroups)
  }

  const getSingleWorkerEditGroup = (
    groupId: number | null,
    workerId: number,
    index: number,
    startAt: string,
    duration: number
  ) => {
    return editGroups.map(group => {
      if (group.groupId !== groupId) {
        return group
      }

      const workers = group.workers.map(w => {
        if (w.workerId !== workerId) {
          return w
        }
        return {
          ...w,
          schedules: w.schedules
            .filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
            .map((s, idx) => {
              if (idx === index) {
                return { ...s, startAt, duration }
              }
              return s
            }),
        }
      })
      return {
        ...group,
        schedules: group.schedules,
        workers,
      }
    })
  }

  const handleWorkerShiftBarChange = (
    groupId: number | null,
    workerId: number,
    index: number,
    x: number,
    width: number
  ) => {
    const time = getTimesByShiftBarX(x)
    const startAt = moment(`${date} ${time.hours}:${time.minutes}`, 'YYYY-MM-DD HH:mm').utc().format()
    const duration = width * 900
    const newEditGroups = getSingleWorkerEditGroup(groupId, workerId, index, startAt, duration)

    onEditGroupsChange(newEditGroups)
  }

  const getShiftBarItems = (groupId: number | null, schedules: WorkPlanSchedulesType[], workerId?: number) =>
    schedules.map((schedule, index) => {
      const startAt = moment(schedule.startAt).local()
      const endAt = moment(startAt).add(schedule.duration, 'second').local()
      const x = getShiftBarXbyStartTime(schedule.startAt)
      const width = getShiftBarWidthByDuration(schedule.duration)
      const id = schedule.scheduleId || getRandomNumber()

      return {
        id: id.toString(),
        content: (
          <ShiftPopover
            label={`勤務時間${index + 1}`}
            time={`${startAt.format('H:mm')}〜${endAt.format('H:mm')}`}
            deleteKey={id.toString()}
            onDelete={
              isPast ? undefined : () => workerId && onDeleteWorkerScheduleType(groupId, schedule.scheduleId, workerId)
            }
          />
        ),
        x,
        width,
      }
    })

  return (
    <div className={styles.tableWrapper}>
      <table>
        <thead>
          <tr className={styles.timeHeader}>
            <td className={`bg-secondary-pale px-4 ${styles.tableHeader}`}>名前</td>
            <td className="p-0">
              <TimeScale />
            </td>
          </tr>
        </thead>
        {editGroups.map(({ groupId, name, workers }, index) => (
          <tbody key={`group-${groupId}-${index}`}>
            <tr className={styles.tableRow}>
              <td className={styles.groupContent}>
                <Popup
                  trigger={
                    <div className="d-flex align-items-center px-4" onClick={() => handleGroupNameClick(name)}>
                      <i className={`icf-carot_${isOpen(name) ? 'down' : 'right'} me-1`} />
                      <div className="text-truncate">{name}</div>
                    </div>
                  }
                  position="bottom center"
                  on="hover"
                  arrowStyle={{ color: 'black' }}
                >
                  <span className="text-white bg-black p-1 px-3 rounded font-small">
                    {name}:{workers.length}人
                  </span>
                </Popup>
              </td>
            </tr>
            {isOpen(name) &&
              _.sortBy(workers, o => o.workerId).map((worker, i) => (
                <tr key={`worker-${worker.workerId}-${i}`} className={styles.tableRow}>
                  <td className={`${styles.tableHeader} ${styles.workerName}`}>
                    <div className="d-flex align-items-center">
                      <div className="text-truncate me-auto">{worker.name}</div>
                    </div>
                  </td>

                  <td className={styles.tableContent}>
                    <ShiftBar
                      items={getShiftBarItems(groupId, worker.schedules, worker.workerId)}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      onAdd={(startPos, endPos) => handleShiftBarAdd(groupId, startPos, endPos, worker.workerId)}
                      onChange={(idx, x, width) => handleWorkerShiftBarChange(groupId, worker.workerId, idx, x, width)}
                      disabled={isPast}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ))}
      </table>
    </div>
  )
}

export default WorkerSchedule
