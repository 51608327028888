import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/skills'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import { commonParams } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type SkillState = API.SkillListResponse & {
  isRequesting: boolean
  errorMessage: string
}

const initialState: SkillState = {
  isRequesting: false,
  errorMessage: '',
  skills: [],
}

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getSkillListSuccess: (state, action: PayloadAction<API.SkillListResponse>) => {
      state.isRequesting = false
      state.skills = action.payload.skills
    },
    createSkillSuccess: (state, action: PayloadAction<API.SkillResponse>) => {
      state.isRequesting = false
      state.skills.push(action.payload)
    },
    updateSkillSuccess: (state, action: PayloadAction<API.SkillResponse>) => {
      state.isRequesting = false
      state.skills = state.skills.map(skill => (skill.skillId === action.payload.skillId ? action.payload : skill))
    },
    deleteSkillSuccess: (state, action: PayloadAction<number>) => {
      state.isRequesting = false
      state.skills = state.skills.filter(skill => skill.skillId !== action.payload)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getSkillListSuccess,
  createSkillSuccess,
  updateSkillSuccess,
  deleteSkillSuccess,
} = skillsSlice.actions

export const getSkillList = (): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getSkillList(commonParams(getState))
    .then((res: API.SkillListResponse) => dispatch(getSkillListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const createSkill =
  (name: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createSkill(commonParams(getState), name)
      .then((res: API.SkillResponse) => dispatch(createSkillSuccess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateSkill =
  (skillId: number, name: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateSkill(commonParams(getState), skillId, name)
      .then((res: API.SkillResponse) => dispatch(updateSkillSuccess(res)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteSkill =
  (skillId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteSkill(commonParams(getState), skillId)
      .then(() => dispatch(deleteSkillSuccess(skillId)))
      .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
      .finally(() => dispatch(Spinner.stop()))
  }

export const selectSkillsStatus = (state: RootState) => ({ ...state.skills })

export default skillsSlice.reducer
