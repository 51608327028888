import * as React from 'react'
import { DropdownItem, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

import { CustomButton } from 'components/common/CustomButton/CustomButton'

type Props = {
  items: { label: string; onClick: () => void }[]
  name?: string // APMを判別するための文字列
}

const DropdownButton: React.FC<Props> = ({ items, name }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} name={name}>
      <DropdownToggle tag="div">
        <CustomButton icon="carot_down" iconPosition="right" outline>
          移動
        </CustomButton>
      </DropdownToggle>
      <DropdownMenu>
        {items.map(item => (
          <React.Fragment key={item.label}>
            <DropdownItem onClick={item.onClick}>{item.label}</DropdownItem>
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropdownButton
