import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { clearErrorMessage, deleteTenantUser, selectTenantsStatus } from 'slices/tenantsSlice'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  userId: string
  onSuccess: () => void
  onCancel: () => void
}

const TenantUserDelete: React.FC<Props> = ({ isOpen, userId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)
  const dispatch = useDispatch()

  const handleCancelClick = () => {
    setModalErrorMessage(undefined)
    setSubmitted(false)
    onCancel()
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('削除できませんでした。')
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  const handleDeleteClick = () => {
    setSubmitted(true)
    dispatch(deleteTenantUser(userId))
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title="ユーザーアカウント削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">このユーザーを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default TenantUserDelete
