import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Button, Col, Card, CardBody, CardTitle } from 'reactstrap'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { clearErrorMessage, getGroupList, selectGroupsStatus, updateGroup } from 'slices/groupsSlice'

import { List, InputFormat, Notification, SubmitFooter, CustomButton, ColorPickerFormat } from 'components/common'
import { ColorTypes } from 'components/common/utils'
import type { ListItem, ColorType } from 'components/common/types'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import placeholderImage from 'images/allEmpty.svg'

import GroupCreate from './GroupCreate'
import GroupDelete from './GroupDelete'

import styles from './GroupEdit.module.scss'

const GroupEdit: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [groupNameValidity, setGroupNameValidity] = React.useState(false)
  const [groupName, setGroupName] = React.useState<string | undefined>(undefined)
  const [groupColor, setGroupColor] = React.useState<ColorType>(ColorTypes.Silver)
  const [selectedGroupId, setSelectedGroupId] = React.useState<number | undefined>(undefined)
  const [openCreate, setOpenCreate] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getGroupList(workspaceId, false))
  }, [dispatch, workspaceId])
  const groupsStatus = useSelector(selectGroupsStatus, shallowEqual)
  const { groups, isRequesting, errorMessage } = groupsStatus

  const hasGroups = React.useMemo(() => groups.length > 0, [groups])
  const selected = React.useMemo(
    () => groups.find(group => group.groupId === selectedGroupId),
    [selectedGroupId, groups]
  )

  React.useEffect(() => {
    setGroupName(selected?.name || groups[0]?.name)
    setGroupColor(selected?.color || groups[0]?.color || ColorTypes.Silver)
    setSelectedGroupId(selected?.groupId || groups[0]?.groupId)
  }, [groups, selectedGroupId, selected])

  const groupItems: ListItem[] = React.useMemo(
    () =>
      hasGroups
        ? groups.map(group => ({
            id: group.groupId,
            title: group.name,
            color: group.color,
            option: <div className={`${styles.square} bg-${group.color}`} />,
          }))
        : [],
    [groups, hasGroups]
  )

  const onDetailClick = () => {
    // TODO グループについての外部リンクに別タブで移動
  }

  const disabled = React.useMemo(
    () => !(groupName || groupNameValidity || groupColor),
    [groupName, groupNameValidity, groupColor]
  )

  const unchanged = React.useMemo(
    () => groupName === selected?.name && groupColor === selected?.color,
    [selected, groupName, groupColor]
  )

  const onSubmit = () => {
    if (selectedGroupId && groupName) {
      setSubmitted(true)
      dispatch(updateGroup(workspaceId, selectedGroupId, groupName, groupColor))
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setShowSuccess(true)
    } else {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        setShowError(true)
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, showSuccess, showError, dispatch])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">グループ管理</div>
          <Notification
            success={showSuccess}
            error={showError}
            hide={() => {
              setShowError(false)
              setShowSuccess(false)
            }}
          />
        </div>
        <div className={`${styles.mainContainer} mx-auto mt-3`}>
          <div className="font-middle fw-bold py-1">グループ管理</div>
          <p className="mt-2 mb-3">
            ワークスペース内のメンバーをグループ、熟練度などでまとめて管理する事ができます。グループに属する作業者に一括で作業を予定したり、グループ毎の生産性を見ることもできます。
          </p>
          <div className="d-flex justify-content-end">
            <CustomButton outline icon="plus" onClick={() => setOpenCreate(true)}>
              グループの追加
            </CustomButton>
          </div>

          <Row className="py-3">
            <Col md={4}>
              <Card className={`position-sticky ${styles.list}`}>
                {hasGroups ? (
                  <List
                    items={groupItems}
                    selectedId={selectedGroupId}
                    onAction={((id: number) => setSelectedGroupId(id)) as (s: string | number) => void}
                  />
                ) : (
                  <CardBody className={`${styles.placeholder} text-center my-5`}>
                    <img src={placeholderImage} alt="" />
                    <h3 className="mt-4">グループがまだ登録されていません</h3>
                    <p>
                      {/*TODO 文言調整*/}
                      まずは最初のグループを登録してみましょう。
                    </p>
                    {/*TODO クリック時の遷移実装*/}
                    <Button color="secondary" size="sm" outline onClick={onDetailClick}>
                      グループについてもっと詳しく
                    </Button>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                {selectedGroupId ? (
                  <>
                    <CardBody>
                      <CardTitle className="font-large fw-bold">グループ情報</CardTitle>
                      <InputFormat
                        label="名前※"
                        placeholder="名前を入力"
                        value={groupName}
                        validations={[Rules.Required]}
                        maxLength={100}
                        size="middle"
                        onChange={value => setGroupName(value)}
                        onValidate={setGroupNameValidity}
                      />
                      <ColorPickerFormat
                        label="グループカラー"
                        color={groupColor}
                        size="middle"
                        onChange={setGroupColor}
                      />
                    </CardBody>

                    <CardBody>
                      <CardTitle className="font-large fw-bold">グループの削除</CardTitle>
                      <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                        このグループを削除
                      </Button>
                    </CardBody>
                  </>
                ) : (
                  <CardBody className={`${styles.placeholder} text-center my-5`}>
                    <img src={placeholderImage} alt="" />
                    <h3 className="mt-4">グループが選択されていません</h3>
                    <p>グループを選択して、詳細情報を編集しましょう。</p>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <SubmitFooter
        onCancel={() => navigate(-1)}
        onSubmit={onSubmit}
        submitDisabled={disabled || unchanged}
        updatedBy={selected?.updatedBy}
        updatedAt={selected?.updatedAt}
      />

      <GroupCreate
        isOpen={openCreate}
        workspaceId={workspaceId}
        onSuccess={() => {
          setShowSuccess(true)
          setOpenCreate(false)
        }}
        onCancel={() => setOpenCreate(false)}
      />

      {selectedGroupId && (
        <GroupDelete
          isOpen={openDelete}
          workspaceId={workspaceId}
          groupId={selectedGroupId}
          onSuccess={() => {
            setShowSuccess(true)
            setOpenDelete(false)
          }}
          onCancel={() => setOpenDelete(false)}
        />
      )}
    </>
  )
}

export default GroupEdit
