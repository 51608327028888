import * as React from 'react'

import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  name: string
  start: string
  end: string
  onCancel: () => void
  onApprove: () => void
}

const SupportConfirm: React.FC<Props> = ({ isOpen, name, start, end, onCancel, onApprove }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      title="他のワークスペースへの一時的移動"
      approveLabel="移動させる"
      onCancel={onCancel}
      onApprove={onApprove}
      onHideNotification={() => null}
    >
      <div className="pb-4">
        <div>この作業者を他のワークスペースに一時的に移動させますか？</div>
        <div>移動先：{name}</div>
        <div>
          時間：{start}~{end}
        </div>
        <div className="pt-4">
          また、他のワークスペースのメンバーが選択されている場合、予定入力と予定変更はされません。
        </div>
      </div>
    </CustomModal>
  )
}

export default SupportConfirm
