import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardTitle, FormGroup } from 'reactstrap'

import type { WorkspaceResponse } from 'api/workspaces'
import type { UpdateWorkSchedule } from 'api/works'

import { showError, showSuccess } from 'slices/notificationSlice'
import { clearErrorMessage, selectWorkersStatus, updateWorkerShifts } from 'slices/workersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { getWorkByDate, selectWorksStatus } from 'slices/worksSlice'
import { selectGroupsStatus } from 'slices/groupsSlice'

import type { EditGroupsWorkerType, EditShiftsType } from 'components/Schedules/types'
import { List, DatePicker, CardSubmitFooter, CustomButton } from 'components/common'
import { getOriginalSchedulesFromWork, hasOverlappedSchedule, SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import WorkerSchedule from './WorkerSchedule'
import ShiftImport from './ShiftImport'

import styles from './Shifts.module.scss'

type Props = {
  selectedWorkspace: WorkspaceResponse | undefined
  onWorkspaceChange: (items: WorkspaceResponse | undefined) => void
  date: string
  onDateChange: (items: string) => void
}
const Shifts: React.FC<Props> = ({ selectedWorkspace, onWorkspaceChange, date, onDateChange }) => {
  const [shiftImportOpen, setShiftImportOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [editGroups, setEditGroups] = React.useState<EditShiftsType[]>([])
  const [initGroups, setInitGroups] = React.useState<EditShiftsType[]>([])
  const [needToChangeParamsFromPath, setNeedToChangeParamsFromPath] = React.useState(false)
  // 再レンダリングにより、保存中の画面から変更したスケジュールが変更前の状態で表示されるためuseStateではなくuseRefを使う
  const editGroupsBeforeSaving = React.useRef<EditShiftsType[]>([])
  // useStateの場合、再レンダリングにより、複数回APIが呼ばれるためuseRefを使う
  const needWorkFlag = React.useRef(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams<'workspaceId' | 'date'>()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { workers, isUpdateFailed, isUpdateDone } = useSelector(selectWorkersStatus, shallowEqual)
  const { works } = useSelector(selectWorksStatus, shallowEqual)
  const { groups } = useSelector(selectGroupsStatus, shallowEqual)

  const work = React.useMemo(() => works.find(w => moment(date).isSame(w.date, 'day')), [date, works])
  const workspaceId = React.useMemo(
    () => (selectedWorkspace ? selectedWorkspace.workspaceId : undefined),
    [selectedWorkspace]
  )

  React.useEffect(() => {
    needWorkFlag.current = true
  }, [workspaceId, date])

  // シフト更新後、workを更新することで、表示しているworkspaceのシフトを最新の状態にする
  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    if (needWorkFlag.current) {
      needWorkFlag.current = false
      dispatch(getWorkByDate(workspaceId, date, true))
      return
    }
    if (!isUpdateDone) {
      return
    }
    dispatch(getWorkByDate(workspaceId, date, true))
  }, [dispatch, workspaceId, date, isUpdateDone])

  const handleWorkspaceIdListChange = (selectedId: string | number) => {
    const workspace = workspaces.find(w => w.workspaceId === selectedId)
    onWorkspaceChange(workspace)
    navigate(`/shifts/${selectedId}/${date}`, { replace: true })
    setNeedToChangeParamsFromPath(true)
  }

  const createEditGroupWorkers = React.useCallback(
    (groupId: number | null) => {
      const target = work?.groups.find(g => g.groupId === groupId)?.workers || []
      return workers
        .filter(worker => worker.workspaceId === workspaceId && worker.groupId === groupId)
        .map<EditGroupsWorkerType>(worker => {
          const shiftSchedules =
            target
              .find(w => w.workerId === worker.workerId)
              ?.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
              .map(schedule => ({
                ...schedule,
                editable: true,
              })) || []
          return {
            ...worker,
            schedules: shiftSchedules,
          }
        })
    },
    [workers, workspaceId, work]
  )

  React.useEffect(() => {
    if (!groups) {
      return
    }

    const selectedGroups: EditShiftsType[] = groups
      .map<{ groupId: number | null; name: string }>(({ groupId, name }) => ({ groupId, name }))
      .concat([{ groupId: null, name: '未所属' }])
      .map(g => ({
        ...g,
        schedules: [],
        supportedWorkspaceId: null,
        supportedWorkspaceName: null,
        workers: createEditGroupWorkers(g.groupId),
        isOpen: editGroupsBeforeSaving.current.find(editGroup => editGroup.groupId === g.groupId)?.isOpen || true,
      }))
      .filter(g => !_.isEmpty(g.workers))

    setInitGroups(selectedGroups)
    if (isUpdateFailed) {
      setEditGroups(editGroupsBeforeSaving.current)
    } else {
      setEditGroups(selectedGroups)
    }
  }, [groups, createEditGroupWorkers, isUpdateFailed])

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  React.useEffect(() => {
    if (needToChangeParamsFromPath) {
      setNeedToChangeParamsFromPath(false)
      return
    }
    const workspace = workspaces.find(w => w.workspaceId === Number(params.workspaceId))
    if (workspace) {
      onWorkspaceChange(workspace)
    }
    params.date && onDateChange(params.date)
  }, [params, workspaces, onWorkspaceChange, onDateChange, needToChangeParamsFromPath])

  const listItems = React.useMemo(
    () =>
      workspaces.map((workspace: WorkspaceResponse) => {
        return {
          id: workspace.workspaceId,
          title: workspace.name,
        }
      }),
    [workspaces]
  )

  const onSubmit = () => {
    if (!workspaceId) {
      return
    }

    setSubmitted(true)
    const schedules = editGroups.flatMap(cur => {
      const initGroup = initGroups.find(ig => ig.name === cur.name && !_.isEqual(ig, cur))

      if (!initGroup) {
        return []
      }

      // worker schedules の追加or更新
      const updateWorker = cur.workers.flatMap(w =>
        w.schedules.reduce((sacc: UpdateWorkSchedule[], scur) => {
          const isUnChanged = initGroup.workers.some(
            init => init.workerId === w.workerId && init.schedules.some(s => _.isEqual(s, scur))
          )

          if (!isUnChanged) {
            sacc.push({
              scheduleId: !scur.scheduleId || scur.scheduleId < 1 ? null : scur.scheduleId,
              schedule: {
                scheduleTypeId: scur.scheduleTypeId,
                supportWorkspaceId: scur.supportWorkspaceId,
                startAt: scur.startAt,
                duration: scur.duration,
                workerId: w.workerId,
                groupId: null,
              },
            })
          }
          return sacc
        }, [])
      )

      // worker schedules の削除データ
      const curWorkerScheduleIds = cur.workers.flatMap(w => w.schedules.map(s => s.scheduleId))
      const deleteWorkerSchedule: UpdateWorkSchedule[] = initGroup.workers
        .flatMap(init =>
          init.schedules.filter(s => !curWorkerScheduleIds.includes(s.scheduleId)).map(s => s.scheduleId)
        )
        .map(scheduleId => ({ scheduleId, schedule: null }))

      return updateWorker.concat(deleteWorkerSchedule)
    })

    const originalSchedules = getOriginalSchedulesFromWork(schedules, work).filter(
      s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
    )
    editGroupsBeforeSaving.current = editGroups
    dispatch(updateWorkerShifts({ schedules, originalSchedules }, workspaceId, date))
  }

  const onCancel = () => {
    setEditGroups(initGroups)
  }

  const handleShiftImport = () => {
    setShiftImportOpen(false)
    dispatch(
      showSuccess({
        successMessage: 'シフト情報のアップロードに成功しました。反映までしばらく時間がかかる場合があります。',
      })
    )
  }

  const handleDatePickerChange = (input: string | undefined) => {
    const formatDate = moment(input).local().format('YYYY-MM-DD')
    if (!input || formatDate === date) {
      return
    }
    onDateChange(formatDate)
    navigate(`/shifts/${workspaceId}/${formatDate}`, { replace: true })
    setNeedToChangeParamsFromPath(true)
  }

  const unchanged = React.useMemo(
    () =>
      _.isEqual(
        initGroups.flatMap(g => g.workers),
        editGroups.flatMap(g => g.workers)
      ),
    [initGroups, editGroups]
  )

  const disabled = React.useMemo(
    () =>
      !selectedWorkspace ||
      editGroups.some(editGroup => editGroup.workers.some(worker => hasOverlappedSchedule(worker.schedules, true))),
    [editGroups, selectedWorkspace]
  )
  const handleWorkPlanButtonClick = () => navigate(`/schedules/${workspaceId}/${work?.workId}`)

  return (
    <>
      <div className={`d-flex justify-content-between ${styles.topContents}`}>
        <div className="font-x-large fw-bold align-self-center">シフト管理</div>
        <CustomButton icon="plus" outline onClick={() => setShiftImportOpen(true)}>
          シフトインポート
        </CustomButton>
      </div>
      <Row className={`${styles.row} p-3`}>
        <Col md={4} className="h-100">
          <Card className={`h-100 ${styles.list}`}>
            {listItems.length > 0 ? (
              <List
                items={listItems}
                selectedId={workspaceId}
                onAction={((id: number) => handleWorkspaceIdListChange(id)) as (selected: string | number) => void}
              />
            ) : (
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <img className={`mx-auto ${styles.placeholderImage}`} src={placeholder} alt="" width="80%" />
                  <div className="font-middle fw-bold py-4">メンバーがいません</div>
                  <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col md={8} className="h-100">
          <Card className="h-100">
            {!selectedWorkspace ? (
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                  <div className="font-middle fw-bold py-4">メンバーが選択されていません</div>
                  <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                </div>
              </CardBody>
            ) : (
              <div className="h-100">
                <div className={styles.shiftDetail}>
                  <CardBody className="h-100">
                    <div className={styles.top}>
                      <CardTitle className="font-large fw-bold">シフト情報</CardTitle>
                      <FormGroup row className="pe-3">
                        <Col md={6} className="d-flex align-items-center">
                          <div className="text-nowrap me-3">日付を選択</div>
                          <DatePicker id="dateSelect" value={date} onChange={handleDatePickerChange} />
                        </Col>
                        <Col className="p-0">
                          <CustomButton
                            icon="schedule"
                            iconPosition="left"
                            outline
                            onClick={handleWorkPlanButtonClick}
                            className="w-100 d-flex justify-content-end p-0"
                            disabled={!work}
                            name="shifts-move-work-plan"
                          >
                            作業計画
                          </CustomButton>
                        </Col>
                      </FormGroup>
                    </div>
                    <div className={`${styles.workerSchedule} my-3`}>
                      <WorkerSchedule
                        date={date}
                        editGroups={editGroups}
                        onEditGroupsChange={setEditGroups}
                      ></WorkerSchedule>
                    </div>
                  </CardBody>
                </div>
                <CardSubmitFooter
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  cancelDisabled={unchanged}
                  submitDisabled={unchanged || disabled}
                  updatedAt={work?.updatedAt}
                  updatedBy={work?.updatedBy}
                />
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <ShiftImport isOpen={shiftImportOpen} onSuccess={handleShiftImport} onCancel={() => setShiftImportOpen(false)} />
    </>
  )
}

export default Shifts
