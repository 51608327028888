import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'

import type { WorkerCount } from 'api/workspaces'

import { selectTenantsStatus } from 'slices/tenantsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { AssignedNumberTable } from 'components/common'
import type { TableItemType, ItemScheduleType } from 'components/common/types'

const getCounts = (data: WorkerCount[]): Map<number, number> => {
  const counts: [number, number][] = data.map(d => [moment(d.time).hours(), d.workerCount])
  return new Map<number, number>(counts)
}
const getWorkspaceCounts = (schedules: ItemScheduleType[]): Map<number, number> => {
  return schedules.reduce((acc, cur) => {
    Array.from(cur.counts.entries()).forEach(([key, value]) => acc.set(key, (acc.get(key) || 0) + value))
    return acc
  }, new Map<number, number>())
}

type Props = {
  tenantId: number
  close?: boolean
  total?: boolean
}
const WorkerCountsCard: React.FC<Props> = props => {
  const { tenantId, close, total } = props

  const { tenants } = useSelector(selectTenantsStatus, shallowEqual)
  const { workerCounts } = useSelector(selectWorkspacesStatus, shallowEqual)
  const tenant = React.useMemo(() => tenants.find(t => t.tenantId === tenantId), [tenants, tenantId])

  const correctionBusinessTime = React.useMemo(() => {
    const round = tenant && moment(tenant.businessEndTime, 'HH:mm').minutes() > 0 ? 1 : 0
    const start = tenant ? `${tenant.businessStartTime.slice(0, 2)}:00` : '06:00'
    // 24:00 が 00:00 にならないように number で計算する
    const end = tenant ? `${Number(tenant.businessEndTime.slice(0, 2)) + round}:00` : '20:00'
    return { start, end }
  }, [tenant])

  const lastImportedAt = React.useMemo(() => moment().format('YYYY/MM/DD HH:mm:ss'), [])
  const items = React.useMemo(
    (): TableItemType[] =>
      workerCounts.map(counts => {
        const schedules = counts.data.map(schedule => ({
          id: schedule.scheduleTypeId,
          name: schedule.scheduleTypeName,
          color: schedule.scheduleTypeColor,
          counts: getCounts(schedule.data),
        }))
        return {
          id: counts.workspaceId,
          name: counts.workspaceName,
          counts: getWorkspaceCounts(schedules),
          schedules,
        }
      }),
    [workerCounts]
  )

  return (
    <Card className="my-3">
      <CardBody>
        <CardTitle className="fw-bold">作業別配置人数一覧</CardTitle>

        <AssignedNumberTable
          businessStartTime={correctionBusinessTime.start}
          businessEndTime={correctionBusinessTime.end}
          items={items}
          close={close}
          total={total}
        ></AssignedNumberTable>
      </CardBody>

      <CardBody className="d-flex text-muted align-self-end pt-0">
        {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
        <small>{lastImportedAt}</small>
      </CardBody>
    </Card>
  )
}

export default WorkerCountsCard
