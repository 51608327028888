import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store'

export type SpinnerState = {
  show: boolean
  count: number
}

const initialState: SpinnerState = {
  show: false,
  count: 0,
}

export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    start: state => {
      state.show = true
      state.count += 1
    },
    stop: state => {
      if (state.count <= 1) {
        state.show = false
      }
      state.count -= 1
    },
  },
})

export const { start, stop } = spinnerSlice.actions

export const selectSpinnerStatus = (state: RootState) => ({ ...state.spinner })

export default spinnerSlice.reducer
