import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import type { ScheduleTypeResponse } from 'api/schedule_types'

import { getForecastProductivity, selectTenantsStatus } from 'slices/tenantsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import type { PerformanceIndicesType } from 'components/Workers/types'
import type { Props } from 'components/Workers/PerformanceIndicesInput'
import { connectionTypes } from 'components/common/utils'

const usePerformanceIndicesInput = ({
  workerId,
  forecastColorScheduleTypeIds,
  onFocus,
  performanceIndices,
  onChange,
  initialPerformanceIndices,
}: Props) => {
  const { forecastProductivity } = useSelector(selectTenantsStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (workerId) {
      dispatch(getForecastProductivity(workerId))
    }
  }, [dispatch, workerId])

  const createPerformanceIndices = React.useCallback(
    (
      value: string,
      initPerformanceIndices: PerformanceIndicesType,
      editPerformanceIndices: PerformanceIndicesType,
      scheduleTypeId: number
    ) => {
      const isNumber = !isNaN(parseInt(value))
      const keyExistsInInitPerformanceIndices = _.has(initPerformanceIndices, scheduleTypeId)
      const keyExistsInEditPerformanceIndices = _.has(editPerformanceIndices, scheduleTypeId)

      if (!isNumber) {
        // 初期値に存在しない人時生産性をフォーカスしたとき、差分が出ないようにする対応
        if (!keyExistsInInitPerformanceIndices && !keyExistsInEditPerformanceIndices) {
          return editPerformanceIndices
        }
        // 人時生産性入力欄が空文字の状態でフォーカスしたとき、差分が出ないようにする対応
        if (!keyExistsInInitPerformanceIndices && keyExistsInEditPerformanceIndices) {
          return _.omit(editPerformanceIndices, scheduleTypeId)
        }
        // 人時生産性入力欄の初期値が空文字で、入力後文字を削除したとき、差分が出ないようにする対応
        if (keyExistsInInitPerformanceIndices && !keyExistsInEditPerformanceIndices) {
          if (initPerformanceIndices[scheduleTypeId] === undefined) {
            return { ...editPerformanceIndices, [scheduleTypeId]: undefined }
          }
          return _.omit(editPerformanceIndices, scheduleTypeId)
        }
      }
      return {
        ...editPerformanceIndices,
        [scheduleTypeId]: value || undefined,
      }
      // return editPerformanceIndices
    },
    []
  )

  const forecastPerformanceIndicesDisabled = React.useMemo(() => !workerId, [workerId])

  const forecastPerformanceIndices = React.useMemo(() => {
    if (!forecastProductivity) {
      return {}
    }
    return forecastProductivity.workspaces
      .flatMap(workspace => workspace.scheduleTypes.map(scheduleType => scheduleType))
      .reduce(
        (acc, cur) =>
          cur.productivity !== null ? { ...acc, [cur.scheduleTypeId]: Math.floor(cur.productivity).toString() } : acc,
        {}
      )
  }, [forecastProductivity])

  const connectionTypeAutoExistsInTenant = React.useMemo(
    () => workspaces.some(workspace => workspace.scheduleTypes.some(s => s.connectionType === connectionTypes.Auto)),
    [workspaces]
  )

  const performanceList = React.useMemo(
    () =>
      workspaces
        .filter(workspace => workspace.scheduleTypes.some(st => st.dataConnection))
        .map(w => {
          const scheduleTypes = w.scheduleTypes.filter(st => st.dataConnection)
          return { ...w, scheduleTypes }
        }),
    [workspaces]
  )

  const getBackgroundColor = React.useCallback(
    (scheduleType: ScheduleTypeResponse) =>
      forecastColorScheduleTypeIds?.includes(scheduleType.scheduleTypeId) ? 'rgba(0,117,227, 0.1)' : undefined,
    [forecastColorScheduleTypeIds]
  )

  const onSetForecastPerformanceIndices = () => {
    // 自動入力したinputの背景色を変更
    onFocus && onFocus(Object.keys(forecastPerformanceIndices || {}).map(key => Number(key)))

    onChange({
      ...performanceIndices,
      ...forecastPerformanceIndices,
    })
  }

  const handleChange = (value: string, scheduleType: ScheduleTypeResponse) => {
    onChange(
      createPerformanceIndices(value, initialPerformanceIndices || {}, performanceIndices, scheduleType.scheduleTypeId)
    )
  }

  const handleFocus = (scheduleTypeId: number) => {
    if (onFocus && forecastColorScheduleTypeIds) {
      onFocus(forecastColorScheduleTypeIds.filter(id => id !== scheduleTypeId))
    }
  }

  return {
    performanceList,
    forecastPerformanceIndices,
    connectionTypeAutoExistsInTenant,
    forecastPerformanceIndicesDisabled,
    getBackgroundColor,
    onSetForecastPerformanceIndices,
    handleChange,
    handleFocus,
  }
}
export default usePerformanceIndicesInput
