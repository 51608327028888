import moment from 'moment'
import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { getReportProductivity, selectReportsStatus } from 'slices/reportsSlice'

import { Pivot, PivotItem, PeriodSelect } from 'components/common'
import { PERIOD_LIST } from 'components/common/utils'
import { NULL_GROUP_NAME } from 'components/Dashboard/utils'

import ProductivityGroupGraph from './ProductivityGroupGraph'
import ProductivityWorkerGraph, { TabType } from './ProductivityWorkerGraph'

import type { GraphProductivityWorker } from './ProductivityWorkerGraph'

const NO_WORKSPACE_ID = 0

type Props = {
  workspaceId?: number
}

const ReportProductivity: React.FC<Props> = ({ workspaceId }) => {
  const [period, setPeriod] = React.useState<{ start: Date; end: Date }>({
    start: moment().subtract(PERIOD_LIST.DAYS_7, 'days').toDate(),
    end: moment().subtract(1, 'days').toDate(),
  })

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    const from = moment(period.start).format('YYYY-MM-DD')
    const to = moment(period.end).format('YYYY-MM-DD')
    dispatch(getReportProductivity(workspaceId, from, to))
  }, [dispatch, workspaceId, period])
  const { productivity } = useSelector(selectReportsStatus, shallowEqual)

  const currentWorkers = React.useMemo(() => {
    if (!productivity) {
      return []
    }
    return productivity.groups
      .filter(group => group.supportedWorkspaceId === null)
      .flatMap(group =>
        group.workers.map<GraphProductivityWorker>(worker => ({
          ...worker,
          workspaceId: productivity.workspaceId,
          workspaceName: productivity.workspaceName,
          groupId: group.groupId ?? 0,
          groupName: group.groupName || NULL_GROUP_NAME,
        }))
      )
  }, [productivity])

  const otherWorkers = React.useMemo(() => {
    if (!productivity) {
      return []
    }
    return productivity.groups
      .filter(group => group.supportedWorkspaceId !== null)
      .flatMap(group =>
        group.workers.map<GraphProductivityWorker>(worker => ({
          ...worker,
          workspaceId: group.supportedWorkspaceId ?? NO_WORKSPACE_ID,
          workspaceName: group.supportedWorkspaceName ?? '',
          groupId: group.groupId ?? 0,
          groupName: group.groupName || NULL_GROUP_NAME,
        }))
      )
  }, [productivity])

  return (
    <Card className="mb-3">
      <CardBody className="d-flex justify-content-between align-items-center">
        <div className="fw-bold">生産性の推移</div>
        <PeriodSelect period={period} onChange={(start, end) => setPeriod({ start, end })} />
      </CardBody>

      <CardBody className="py-0">
        <Pivot>
          {[
            { header: 'ワークスペース/グループ', key: 'group' },
            { header: 'メンバー', key: TabType.Current },
            { header: '他のワークスペースのメンバー', key: TabType.Other },
          ].map(({ header, key }) => (
            <PivotItem headerText={header} key={key}>
              {key === 'group' ? (
                <ProductivityGroupGraph productivity={productivity} start={period.start} end={period.end} />
              ) : key === TabType.Current ? (
                <ProductivityWorkerGraph
                  graphProductivityWorkers={currentWorkers}
                  start={period.start}
                  end={period.end}
                  type={key}
                />
              ) : key === TabType.Other ? (
                <ProductivityWorkerGraph
                  graphProductivityWorkers={otherWorkers}
                  start={period.start}
                  end={period.end}
                  type={key}
                />
              ) : null}
            </PivotItem>
          ))}
        </Pivot>
      </CardBody>
    </Card>
  )
}

export default ReportProductivity
