import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CardBody, CardText, Card } from 'reactstrap'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { updateTargetValues, clearErrorMessage, selectWorksStatus } from 'slices/worksSlice'

import { CustomModal, InputGroupFormat } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import styles from './TargetValuesUpdate.module.scss'

type TargetValuesType = {
  scheduleTypeId: number
  targetValue: string | undefined
  name: string
  unit: string
}

type Props = {
  isOpen: boolean
  workspaceId: number
  workId: number
  isPast: boolean
  onSuccess: () => void
  onCancel: () => void
}

const TargetValuesUpdate: React.FC<Props> = props => {
  const { isOpen, workspaceId, workId, isPast, onSuccess, onCancel } = props

  const [updateValues, setUpdateValues] = React.useState<TargetValuesType[]>([])
  const [initUpdateValues, setInitUpdateValues] = React.useState<TargetValuesType[]>([])
  const [validities, setValidities] = React.useState<boolean[]>([])
  const [disabled, setDisabled] = React.useState(true)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { works, isRequesting, errorMessage } = useSelector(selectWorksStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('保存できませんでした。')
      clearErrorMessage()
    }
    setSubmitted(false)
  }, [errorMessage, isRequesting, onSuccess, submitted])

  React.useEffect(() => {
    const nextDisabled = !(updateValues.every(t => t.targetValue) && validities.every(validity => validity))
    setDisabled(nextDisabled)
  }, [updateValues, validities])

  React.useEffect(() => {
    const workPlans = works.find(w => w.workId === workId)?.workPlan || []
    const newTargetValues = workPlans.reduce((acc: TargetValuesType[], cur) => {
      const schedule = scheduleTypes.filter(s => s.dataConnection).find(s => s.scheduleTypeId === cur.scheduleTypeId)
      if (schedule) {
        acc.push({
          scheduleTypeId: cur.scheduleTypeId,
          targetValue: cur.targetValue.toString(),
          name: schedule.name,
          unit: schedule.unit!,
        })
      }
      return acc
    }, [])
    const sortedTargetValues = _.sortBy(newTargetValues, 'name')
    setUpdateValues(sortedTargetValues)
    setInitUpdateValues(sortedTargetValues)
    setValidities(sortedTargetValues.map(() => true)) // eslint-disable-line no-shadow
    setModalErrorMessage(undefined)
  }, [workId, works, scheduleTypes, isOpen])

  const workspaceName = React.useMemo(
    () => workspaces.find(w => w.workspaceId === workspaceId)?.name || '',
    [workspaces, workspaceId]
  )

  const handleUpdateValue = (index: number, targetValue: string) => {
    const newTargetValues = updateValues.map((target, i) => (i === index ? { ...target, targetValue } : target))
    setUpdateValues(newTargetValues)
  }

  const handleValidate = (index: number, validity: boolean) => {
    if (validities[index] !== validity) {
      setValidities(validities.map((v, i) => (i === index ? validity : v)))
    }
  }

  const handleSaveClick = () => {
    setSubmitted(true)
    const targetValues = updateValues.map(t => ({
      scheduleTypeId: t.scheduleTypeId,
      targetValue: Number(t.targetValue),
    }))
    dispatch(updateTargetValues(workspaceId, workId, { targetValues }))
  }

  const unchanged = React.useMemo(() => _.isEqual(initUpdateValues, updateValues), [initUpdateValues, updateValues])

  return (
    <CustomModal
      isOpen={isOpen}
      title="目標設定"
      approveDisabled={disabled || unchanged}
      showApprove={!isPast}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <p>この日の各作業の目標を設定します。</p>
      <Card className={styles.cardContainer}>
        <CardBody>
          <CardText>{workspaceName}</CardText>
          {updateValues.map((target, index) => (
            <InputGroupFormat
              key={target.scheduleTypeId}
              label={target.name}
              value={target.targetValue}
              addonText={target.unit}
              maxLength={10}
              disabled={isPast}
              onChange={value => handleUpdateValue(index, value)}
              validations={[Rules.Required, Rules.PositiveInteger]}
              onValidate={validity => handleValidate(index, validity)}
            />
          ))}
        </CardBody>
      </Card>
    </CustomModal>
  )
}

export default TargetValuesUpdate
