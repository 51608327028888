import * as React from 'react'
import { Input } from 'reactstrap'

import styles from './FilteringInputField.module.scss'

type Props = {
  placeholder?: string
  value: string
  onChange: (value: string) => void
}
const FilteringInputField: React.FC<Props> = ({
  placeholder = 'メンバー名もしくはID・識別番号で検索',
  onChange,
  value,
}) => {
  return (
    <div className="position-relative">
      <i className={`icf-search font-large text-muted ${styles.searchIcon}`} />
      <Input
        value={value}
        placeholder={placeholder}
        className="border-secondary"
        style={{ paddingLeft: '38px' }}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  )
}
export default FilteringInputField
