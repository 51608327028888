import axios from 'axios'

import type { ScheduleTypeResponse } from 'api/schedule_types'

import type { CommonParams } from 'slices/utils'

import type { ColorType } from 'components/common/types'

export type WorkspaceResponse = {
  workspaceId: number
  name: string
  memberIds: string[]
  relatedWorkspaceIds: number[]
  autoArchiveTime: string | null
  scheduleTypes: ScheduleTypeResponse[]
  createdAt: string
  updatedAt: string
  updatedBy: string
}

export type WorkerCountsResponse = {
  workspaceId: number
  workspaceName: string
  date: string
  data: ScheduleTypeWithWorkerCounts[]
}
export type ScheduleTypeWithWorkerCounts = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  totalCount: number
  data: WorkerCount[]
}
export type WorkerCount = {
  time: string
  workerCount: number
}

export type WorkspaceListResponse = {
  workspaces: WorkspaceResponse[]
}

export type EditWorkspaceProps = {
  name: string
  memberIds: string[]
  relatedWorkspaceIds: number[]
  autoArchiveTime: string
}

// idがnullの場合は新規追加
// schedulesがnullの場合は削除
export type EditTemplateProps = {
  id: number | null
  schedules: (number | null)[] | null // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  name?: string
}

export type TemplateListResponse = {
  templates: {
    id: number
    name: string
  }[]
}

export type TemplateDataResponse = {
  id: number
  name: string
  schedules: (number | null)[] // 24時間を15分ごとに分割した96の配列にscheduleTypeIdを格納する
  updatedAt: string
  updatedBy: string
}

export const getWorkspaceList = (params: CommonParams, tenantId?: number): Promise<WorkspaceListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${tenantId ?? params.tenantId}/workspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkspace = (params: CommonParams, workspaceId: number): Promise<WorkspaceResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkerCounts = (
  params: CommonParams,
  workspaceId: number,
  date: string
): Promise<WorkerCountsResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/date/${date}/worker_counts`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createWorkspace = (params: CommonParams, data: EditWorkspaceProps): Promise<WorkspaceResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateWorkspace = (
  params: CommonParams,
  workspaceId: number,
  data: EditWorkspaceProps
): Promise<WorkspaceResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteWorkspace = (params: CommonParams, workspaceId: number): Promise<void> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTemplateList = (params: CommonParams, workspaceId: number): Promise<TemplateListResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/templates`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getTemplateData = (
  params: CommonParams,
  workspaceId: number,
  templateId: number
): Promise<TemplateDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/templates/${templateId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateTemplateData = (
  params: CommonParams,
  workspaceId: number,
  data: EditTemplateProps
): Promise<TemplateDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/templates`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
