import { createSlice } from '@reduxjs/toolkit'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'

export type NetworkErrorPayload = {
  code: string
  errorMessage?: string
}

export type NetworkErrorDialogState = {
  show: boolean
  code: string
  errorMessage: string
}

const initialState: NetworkErrorDialogState = {
  show: false,
  code: '',
  errorMessage: '',
}

export const networkErrorDialogSlice = createSlice({
  name: 'networkErrorDialog',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<NetworkErrorPayload>) => {
      state.show = true
      state.code = action.payload.code
      state.errorMessage = action.payload?.errorMessage ?? ''
    },
    close: state => {
      state.show = false
      state.code = ''
      state.errorMessage = ''
    },
  },
})

export const { open, close } = networkErrorDialogSlice.actions

export const selectNetworkErrorDialogStatus = (state: RootState) => ({ ...state.networkErrorDialog })

export default networkErrorDialogSlice.reducer
