import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Row, Col, Card, CardColumns } from 'reactstrap'
import { shallowEqual, useSelector } from 'react-redux'

import { compactAverage } from 'api/utils'
import type { ReportProductivityResponse, DailyWorkRow } from 'api/reports'

import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { BadgeButton, Chart, List } from 'components/common'
import type { BadgeItem, Series } from 'components/common/types'
import { createLineChartOptions, ColorTypes, connectionTypes } from 'components/common/utils'
import { colorTypeToCode, NULL_GROUP_ID, NULL_GROUP_NAME } from 'components/Dashboard/utils'

import { createXAxis } from './utils'

import styles from './ProductivityGroupGraph.module.scss'

const LIST_WORKSPACE_ID = -1 // 必ずリストの先頭にworkspaceが入るため、groupIdに衝突しない値を定義

type ScheduleTypeListRow = {
  scheduleTypeId: number
  scheduleTypeName: string
  hourlyAvgProductivity: number
  unit: string
}

type ProductivityBadgeItem = BadgeItem & {
  unit: string
}

type DailyWorkArrayRow = {
  date: string
  dailyAvgPlanCount: Array<number | null>
  dailyAvgRecordCount: Array<number | null>
}

type Props = {
  productivity: ReportProductivityResponse | undefined
  start: Date
  end: Date
}

const ProductivityGroupGraph: React.FC<Props> = props => {
  const { productivity, start, end } = props
  const [selectedId, setSelectedId] = React.useState(LIST_WORKSPACE_ID)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const listItems = React.useMemo(() => {
    if (!productivity) {
      return []
    }
    const groupItems = productivity.groups.map(group => ({
      id: group.groupId || NULL_GROUP_ID,
      title: group.groupName || NULL_GROUP_NAME,
    }))
    return [{ id: LIST_WORKSPACE_ID, title: productivity.workspaceName }, ...groupItems]
  }, [productivity])

  const selectedName = React.useMemo(
    () => listItems.find(listItem => listItem.id === selectedId)?.title || '',
    [selectedId, listItems]
  )

  const dailyWorkData = React.useMemo(() => {
    if (!productivity) {
      return []
    }

    const targetGroups =
      selectedId === LIST_WORKSPACE_ID
        ? productivity.groups
        : selectedId === NULL_GROUP_ID
          ? productivity.groups.filter(group => group.groupId === null)
          : productivity.groups.filter(group => group.groupId === selectedId)
    const workerDailyWorkData = targetGroups.flatMap(g => g.workers).flatMap(w => w.dailyWorkData)

    return selectedId === LIST_WORKSPACE_ID
      ? workerDailyWorkData.concat(productivity.dailyWorkData)
      : workerDailyWorkData
  }, [productivity, selectedId])

  const badgeItems = React.useMemo(() => {
    const items = _.chain(dailyWorkData)
      .map<ProductivityBadgeItem>(dailyWork => ({
        color: dailyWork.scheduleTypeColor,
        key: dailyWork.scheduleTypeId,
        label: dailyWork.scheduleTypeName,
        unit: dailyWork.unit,
      }))
      .uniqBy('key')
      .sortBy('key')
      .value()

    if (selectedId === LIST_WORKSPACE_ID) {
      return items
    }

    const target = workspaces.find(w => w.workspaceId === productivity?.workspaceId)
    if (!target) {
      return items
    }
    const scheduleTypeIds = target.scheduleTypes
      .filter(s => s.connectionType === connectionTypes.Auto)
      .map(s => s.scheduleTypeId)
    return items.filter(i => scheduleTypeIds.includes(i.key))
  }, [dailyWorkData, selectedId, productivity?.workspaceId, workspaces])

  React.useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const createSummaryRows = React.useCallback(
    (badgeKey: number) => {
      const dailyWorkRows = dailyWorkData
        .filter(dailyWork => dailyWork.scheduleTypeId === badgeKey)
        .flatMap(dailyWork => dailyWork.data.map(row => row))
      // 1日ごとに平均を出すためにいったん配列で集計する
      const summaryArrayRows = dailyWorkRows.reduce((acc, cur) => {
        const target = acc.find(row => row.date === cur.date)
        if (!target) {
          return acc.concat([
            {
              date: cur.date,
              dailyAvgPlanCount: [cur.dailyAvgPlanCount],
              dailyAvgRecordCount: [cur.dailyAvgRecordCount],
            },
          ])
        }
        target.dailyAvgPlanCount.push(cur.dailyAvgPlanCount)
        target.dailyAvgRecordCount.push(cur.dailyAvgRecordCount)
        return acc
      }, [] as DailyWorkArrayRow[])

      // compactAverage で null を除いた平均に置き換える
      return summaryArrayRows.map((row): DailyWorkRow => {
        return {
          date: row.date,
          dailyAvgPlanCount: compactAverage(row.dailyAvgPlanCount),
          dailyAvgRecordCount: compactAverage(row.dailyAvgRecordCount),
        }
      })
    },
    [dailyWorkData]
  )

  const hourlyAvgProductivityList = React.useMemo(() => {
    const xAxisData = createXAxis(start, end)
    const valueList = dailyWorkData
      .filter(dailyWork => dailyWork.hourlyAvgProductivity !== null) // nullは平均値算出から除外
      .flatMap<ScheduleTypeListRow>(dailyWork => ({
        scheduleTypeId: dailyWork.scheduleTypeId,
        scheduleTypeName: dailyWork.scheduleTypeName,
        hourlyAvgProductivity: dailyWork.hourlyAvgProductivity!,
        unit: dailyWork.unit,
      }))

    const averageList = Object.values(_.groupBy(valueList, 'scheduleTypeId'))
      .filter(values => values.length > 0)
      .map<ScheduleTypeListRow>(values => {
        const summaryRows = createSummaryRows(values[0].scheduleTypeId)

        // areaの上にlineを表示するためにareaを先にしておく
        const recordData = xAxisData
          .map(date => {
            // パフォーマンス改善のために日付を直接文字列として比較する
            const target = summaryRows.find(row => row.date === date)
            return target ? target.dailyAvgRecordCount : null
          })
          .filter(data => data)

        const average = Math.floor(_.sum(recordData) / recordData.length)

        return {
          scheduleTypeId: values[0].scheduleTypeId,
          scheduleTypeName: values[0].scheduleTypeName,
          hourlyAvgProductivity: average,
          unit: values[0].unit,
        }
      })
    return _.sortBy(averageList, 'scheduleTypeId')
  }, [dailyWorkData, createSummaryRows, start, end])

  const chartOptions = React.useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))
    const xAxisData = createXAxis(start, end)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)
      const summaryRows = createSummaryRows(graphBadge.key)
      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)
        return target ? target.dailyAvgRecordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)
        return target ? target.dailyAvgPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter() {
          return (
            '<div style="text-align:center">' +
            [
              moment(this.x).format('YYYY/MM/DD'),
              this.series.name,
              `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
            ].join('<br>') +
            '</div>'
          )
        },
      },
      xAxis: {
        tickInterval: Math.ceil(xAxisData.length / 7),
      },
    })
    return options
  }, [badgeItems, selectedBadgeKeys, start, end, createSummaryRows])

  return (
    <Row>
      <Col md={4}>
        <div className={styles.list}>
          <List
            items={listItems}
            selectedId={selectedId}
            onAction={((id: number) => setSelectedId(id)) as (selected: string | number) => void}
          />
        </div>
      </Col>
      <Col md={8}>
        <Chart options={chartOptions} />
        <div className="d-flex row mx-2 mt-4">
          <BadgeButton
            items={badgeItems}
            selected={selectedBadgeKeys}
            onChange={selected => setSelectedBadgeKeys(selected)}
          />
        </div>
        <hr color={ColorTypes.Silver} />
        <div className="font-middle fw-bold py-2">{selectedName}の生産性（日別）の指定期間の平均</div>
        {hourlyAvgProductivityList.map(hourlyAvgProductivity => (
          <Card key={hourlyAvgProductivity.scheduleTypeId} className="bg-light-gray my-2">
            <CardColumns className="py-2 px-3 d-flex justify-content-between">
              <span>{hourlyAvgProductivity.scheduleTypeName}</span>
              <span>
                {Math.floor(hourlyAvgProductivity.hourlyAvgProductivity).toLocaleString()}
                {hourlyAvgProductivity.unit} /1時間
              </span>
            </CardColumns>
          </Card>
        ))}
      </Col>
    </Row>
  )
}

export default ProductivityGroupGraph
