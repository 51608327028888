import _ from 'lodash'
import * as React from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Row, FormGroup, Label } from 'reactstrap'

import { Role } from 'api/users'
import type { TenantResponse, TenantEditDataType } from 'api/tenants'

import { showError, showSuccess } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { clearErrorMessage, getTenant, selectTenantsStatus, updateTenant } from 'slices/tenantsSlice'
import { getPostcodeList, selectPostcodeStatus } from 'slices/postcodeSlice'

import {
  NavMenu,
  InputFormat,
  PostalcodeInputForm,
  SelectBoxFormat,
  TimeSelect,
  CardSubmitFooter,
} from 'components/common'
import { prefectureItems } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'

const CompanyEdit: React.FC = () => {
  const [initEditData, setInitEditData] = React.useState<TenantEditDataType>({
    name: '',
    salesOfficeName: '',
    postalCode: '',
    prefecture: '',
    municipality: '',
    otherAddress: '',
    phoneNumber: '',
    personInCharge: '',
    businessStartHour: '',
    businessStartMinute: '',
    businessEndHour: '',
    businessEndMinute: '',
  })
  const [editData, setEditData] = React.useState<TenantEditDataType>(initEditData)
  const [submitted, setSubmitted] = React.useState(false)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [phoneNumberValidity, setPhoneNumberValidity] = React.useState(false)
  const [postalCodeValidity, setPostalCodeValidity] = React.useState(false)
  const [prefectureValidity, setPrefectureValidity] = React.useState(false)
  const [municipalityValidity, setMunicipalityValidity] = React.useState(false)
  const [otherAddressValidity, setOtherAddressValidity] = React.useState(false)
  const [businessTimeValidity, setBusinessTimeValidity] = React.useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = React.useState(false)

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const tenantId = user.tenants[0].tenantId
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTenant(tenantId))
    dispatch(getPostcodeList())
  }, [dispatch, tenantId])

  const { tenants, isRequesting, errorMessage } = useSelector(selectTenantsStatus, shallowEqual)
  const tenant = React.useMemo(
    (): TenantResponse | undefined => tenants.find(t => t.tenantId === tenantId),
    [tenants, tenantId]
  )

  React.useEffect(() => {
    const businessStartTime = tenant?.businessStartTime.split(':')
    const businessEndTime = tenant?.businessEndTime.split(':')

    setInitEditData({
      name: tenant?.name || '',
      salesOfficeName: tenant?.salesOfficeName || '',
      postalCode: tenant?.postalCode || '',
      prefecture: tenant?.prefecture || '',
      municipality: tenant?.municipality || '',
      otherAddress: tenant?.otherAddress || '',
      phoneNumber: tenant?.phoneNumber || '',
      personInCharge: tenant?.personInCharge || '',
      businessStartHour: businessStartTime?.[0] || '08',
      businessStartMinute: businessStartTime?.[1] || '00',
      businessEndHour: businessEndTime?.[0] || '18',
      businessEndMinute: businessEndTime?.[1] || '00',
    })

    setEditData({
      name: tenant?.name || '',
      salesOfficeName: tenant?.salesOfficeName || '',
      postalCode: tenant?.postalCode || '',
      prefecture: tenant?.prefecture || '',
      municipality: tenant?.municipality || '',
      otherAddress: tenant?.otherAddress || '',
      phoneNumber: tenant?.phoneNumber || '',
      personInCharge: tenant?.personInCharge || '',
      businessStartHour: businessStartTime?.[0] || '08',
      businessStartMinute: businessStartTime?.[1] || '00',
      businessEndHour: businessEndTime?.[0] || '18',
      businessEndMinute: businessEndTime?.[1] || '00',
    })
  }, [tenant])

  React.useEffect(() => {
    const start = editData.businessStartHour + editData.businessStartMinute
    const end = editData.businessEndHour + editData.businessEndMinute
    setBusinessTimeValidity(start < end)
  }, [editData.businessStartHour, editData.businessStartMinute, editData.businessEndHour, editData.businessEndMinute])

  const disabled = React.useMemo(
    () =>
      !(
        nameValidity &&
        postalCodeValidity &&
        prefectureValidity &&
        municipalityValidity &&
        otherAddressValidity &&
        phoneNumberValidity &&
        businessTimeValidity
      ),
    [
      nameValidity,
      postalCodeValidity,
      prefectureValidity,
      municipalityValidity,
      otherAddressValidity,
      phoneNumberValidity,
      businessTimeValidity,
    ]
  )

  const unchanged = React.useMemo(() => _.isEqual(editData, initEditData), [editData, initEditData])

  const onSubmit = () => {
    setSubmitted(true)
    dispatch(updateTenant(tenantId, editData))
  }

  const { postcodeList } = useSelector(selectPostcodeStatus, shallowEqual)
  const foundPostalCode = React.useMemo(
    () => postcodeList.find(p => p.postcode === editData.postalCode),
    [editData.postalCode, postcodeList]
  )
  const onAddressAutoInput = () => {
    setEditData({
      ...editData,
      prefecture: foundPostalCode?.address1 || '',
      municipality: foundPostalCode?.address2 || '',
      otherAddress: foundPostalCode?.address3 || '',
    })
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)
    setEditData(initEditData)
  }

  const onNavMenuClick = (menuType: string) => {
    if (menuType === 'information') {
      navigate('/account/edit')
    }
  }

  const menuItems = [
    {
      type: 'information',
      label: 'アカウント情報',
    },
  ]
  if (user.role === Role.Admin) {
    menuItems.push({
      type: 'company',
      label: 'ご登録企業情報',
    })
  }

  return (
    <>
      <NavMenu type={'company'} items={menuItems} onNavMenuClick={onNavMenuClick}>
        <div className="m-3">
          <Row className="g-0 mb-3">
            <Col className="font-x-large fw-bold">ご登録企業情報</Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <div className="d-flex justify-content-between py-1">
                    <div className="font-middle fw-bold">ご登録企業の詳細</div>
                    <small>※必須項目</small>
                  </div>
                  <div className="mt-2 mb-4">
                    ご登録時に設定いただいた組織情報が設定されております。変更があった際には下記で編集をお願いいたします。
                  </div>

                  <InputFormat
                    label="企業名※"
                    placeholder="企業名を入力"
                    maxLength={200}
                    value={editData.name}
                    onChange={value => setEditData({ ...editData, name: value })}
                    validations={[Rules.Required]}
                    onValidate={setNameValidity}
                  />
                  <InputFormat
                    label="事業所名"
                    placeholder="事業所名を入力"
                    value={editData.salesOfficeName}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, salesOfficeName: value })}
                  />
                  <InputFormat
                    label="電話番号※"
                    placeholder="電話番号を入力"
                    value={editData.phoneNumber}
                    size="short"
                    maxLength={20}
                    onChange={value => setEditData({ ...editData, phoneNumber: value })}
                    validations={[Rules.Required, Rules.PhoneNumber]}
                    onValidate={setPhoneNumberValidity}
                  />

                  <div className="font-middle fw-bold mt-5 mb-3">事業所住所</div>
                  <PostalcodeInputForm
                    label="郵便番号※"
                    value={editData.postalCode}
                    onChange={value => setEditData({ ...editData, postalCode: value })}
                    validations={[Rules.Required, Rules.PostalCode]}
                    onValidate={setPostalCodeValidity}
                    onAddressAutoInput={onAddressAutoInput}
                    disabledAddressAutoInput={!foundPostalCode}
                  />
                  <SelectBoxFormat
                    label="都道府県※"
                    placeholder="都道府県を選択"
                    value={editData.prefecture}
                    size="short"
                    items={prefectureItems}
                    onChange={e => setEditData({ ...editData, prefecture: e.value })}
                    validations={[Rules.Required]}
                    onValidate={setPrefectureValidity}
                  />
                  <InputFormat
                    label="市区町村※"
                    placeholder="市区町村を入力"
                    value={editData.municipality}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, municipality: value })}
                    validations={[Rules.Required]}
                    onValidate={setMunicipalityValidity}
                  />
                  <InputFormat
                    label="それ以降の住所※"
                    value={editData.otherAddress}
                    maxLength={100}
                    onChange={value => setEditData({ ...editData, otherAddress: value })}
                    validations={[Rules.Required]}
                    onValidate={setOtherAddressValidity}
                  />

                  <div className="font-middle fw-bold mt-5 mb-3">営業時間</div>
                  <div className="mt-2 mb-4">
                    事業所の営業時間を設定してください。設定した営業時間内で予定を設定する事ができます。早朝の出勤や残業なども考慮して設定していただく事をお勧めします。
                  </div>
                  <FormGroup row>
                    <Label md={4}>営業開始時間※</Label>
                    <Col md={8}>
                      <TimeSelect
                        hour={editData.businessStartHour}
                        minute={editData.businessStartMinute}
                        label="から"
                        onChange={(hour, minute) =>
                          setEditData({ ...editData, businessStartHour: hour, businessStartMinute: minute })
                        }
                        menuPosition="top"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={4}>営業終了時間※</Label>
                    <Col md={8}>
                      <TimeSelect
                        hour={editData.businessEndHour}
                        minute={editData.businessEndMinute}
                        label="まで"
                        invalid={!businessTimeValidity}
                        onChange={(hour, minute) =>
                          setEditData({ ...editData, businessEndHour: hour, businessEndMinute: minute })
                        }
                        menuPosition="top"
                      />
                      <div className={`invalid-feedback ${!businessTimeValidity && 'd-block'}`}>
                        営業終了時間は営業開始時間より後の時間を設定してください
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            <CardSubmitFooter
              onCancel={() => setOpenEditChangesDiscardDialog(true)}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
            />
          </Card>
        </div>
      </NavMenu>

      <EditChangesDiscardDialog
        isOpen={openEditChangesDiscardDialog}
        onCancel={() => setOpenEditChangesDiscardDialog(false)}
        onDiscard={handleEditChangesDiscard}
      />
    </>
  )
}

export default CompanyEdit
