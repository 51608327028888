import * as React from 'react'

import { CELL_WIDTH } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

import styles from './TimeScale.module.scss'

type Props = {
  round?: boolean
}
export const TimeScale: React.FC<Props> = ({ round }) => {
  const { business15MinBlocks, businessHourBlocks } = useBusinessTime()
  const blocks = business15MinBlocks.length
  const borderStyle = (time: number, index: number) => {
    if (time % 4 === 3) {
      return index === blocks - 1 ? '' : styles.borderRight
    }
    if (time % 4 === 0) {
      return index === 0 ? '' : styles.borderLeft
    }
    return ''
  }
  const getTime = (i: number, index: number) => {
    if (i % 4 === 0 && index < 4 * Math.floor(blocks / 4)) {
      return `${i / 4}:00`
    }
    return ''
  }

  return (
    <table>
      <tbody>
        {round ? (
          <tr className={styles.container} style={{ width: CELL_WIDTH * 4 * businessHourBlocks.length }}>
            {businessHourBlocks.map(time => (
              <td key={time} className={`${styles.box} ${styles.hourBorderRight}`} style={{ width: CELL_WIDTH * 4 }}>
                {`${time}:00`}
              </td>
            ))}
          </tr>
        ) : (
          <tr className={styles.container} style={{ width: CELL_WIDTH * blocks }}>
            {business15MinBlocks.map((time, i) => (
              <td key={time} className={`${styles.box} ${borderStyle(time, i)} p-0`} style={{ width: CELL_WIDTH }}>
                <div className={styles.timeText}>{getTime(time, i)}</div>
              </td>
            ))}
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default TimeScale
