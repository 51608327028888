import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, CardBody, CardTitle } from 'reactstrap'

import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { createScheduleType, clearErrorMessage, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { getDataConnectionInfo } from 'slices/tenantsSlice'

import {
  InputFormat,
  ColorPickerFormat,
  SelectBoxFormat,
  Notification,
  InputGroupFormat,
  SubmitFooter,
  ItemEdit,
} from 'components/common'
import type { ConnectionType, SuggestionItem } from 'components/common/types'
import { ColorTypes, connectionTypes } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'

import useScheduleType from 'hooks/useScheduleType'

import FilterEdit from './FilterEdit'
import ConnectionTypeButton from './ConnectionTypeButton'

import styles from './ScheduleTypeCreate.module.scss'

const ScheduleTypeCreate: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)

  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { isRequesting, errorMessage } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    setNameValidity,
    setTargetDatabaseValidity,
    setTargetColumnValidity,
    setUnitValidity,
    setDefaultPerformanceIndexValidity,
    disabled,
    editData,
    setEditData,
    scheduleTypeUpdateData,
    targetDatabases,
    targetColumns,
    filterColumns,
  } = useScheduleType()

  React.useEffect(() => {
    setEditData({
      name: undefined,
      color: 'silver',
      requiredSkills: [],
      connectionType: connectionTypes.None,
      dataConnection: false,
      targetDatabase: undefined,
      targetColumn: undefined,
      unit: undefined,
      filter: [],
    })
  }, [setEditData])

  React.useEffect(() => {
    dispatch(getDataConnectionInfo())
    dispatch(getSkillList())
  }, [dispatch])

  const handleConnectionTypeChange = (value: ConnectionType) => {
    const dataConnection = value !== connectionTypes.None
    editData &&
      setEditData({
        ...editData,
        connectionType: value,
        dataConnection,
        targetDatabase: undefined,
        targetColumn: undefined,
        unit: undefined,
        filter: [],
        defaultPerformanceIndex: undefined,
      })
  }

  const onSubmit = () => {
    setSubmitted(true)
    scheduleTypeUpdateData && dispatch(createScheduleType(workspaceId, scheduleTypeUpdateData))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      navigate(`/workspaces/${workspaceId}`)
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setModalErrorMessage('すでにこの名前の作業は存在しています。')
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, navigate, workspaceId])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const requiredSkills = skills.filter(s => items.some(i => i.id === s.skillId))
    editData && setEditData({ ...editData, requiredSkills })
  }

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large fw-bold text-center border-bottom py-3 bg-white">作業の登録</div>
          <Notification
            errorMessage={modalErrorMessage}
            error={!!modalErrorMessage}
            hide={() => setModalErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-1">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle fw-bold">作業の詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名称※"
            placeholder="作業名を入力"
            value={editData?.name}
            size="middle"
            maxLength={100}
            onChange={value => editData && setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />
          <ColorPickerFormat
            label="キーカラー"
            color={editData?.color || ColorTypes.Silver}
            size="middle"
            onChange={color => editData && setEditData({ ...editData, color })}
          />

          <div className="font-middle fw-bold py-1 mt-5">必須スキル設定</div>
          <div className="mt-2 mb-1">
            作業の必須スキルを設定しておくことで、対象のスキルを持っていないメンバーの予定に作業が入れられた際にアラートを出します。
            （アラートは出ますが予定に入れることは可能です。）
          </div>
          <ItemEdit
            items={skills.map(s => ({ id: s.skillId, value: s.name }))}
            selectedItems={editData?.requiredSkills.map(s => ({ id: s.skillId, value: s.name })) || []}
            label="作業に必須スキルを追加"
            itemName="スキル"
            onChange={handleSkillEdit}
          />
          <div className="font-middle fw-bold py-1 mt-5">実績入力方法</div>
          <div className="mt-2 mb-1">
            実績をSmile Board Connectに入力する方法を選択してください
            <div className="text-danger">登録後の実績入力方法の変更はできません。</div>
          </div>
          <div className="my-3">
            <ConnectionTypeButton
              value={editData?.connectionType || connectionTypes.None}
              onChange={handleConnectionTypeChange}
            />
          </div>
          {editData?.connectionType !== connectionTypes.None && (
            <>
              {editData?.connectionType === connectionTypes.Auto && (
                <div>
                  <div className="mb-2">抽出条件の設定</div>
                  <Card>
                    <CardBody>
                      <SelectBoxFormat
                        label="参照するデータベース※"
                        placeholder="データベースを選択"
                        value={editData.targetDatabase}
                        size="middle"
                        items={targetDatabases}
                        onChange={e =>
                          setEditData({
                            ...editData,
                            targetDatabase: e.key?.toString(),
                            filter: [],
                          })
                        }
                        validations={[Rules.Required]}
                        onValidate={setTargetDatabaseValidity}
                      />
                    </CardBody>
                    <hr className="m-0"></hr>
                    <CardBody>
                      <SelectBoxFormat
                        label="実績が記載されている列※"
                        placeholder="列を選択"
                        value={targetColumns.find(t => t.key === editData.targetColumn)?.key}
                        size="middle"
                        items={targetColumns}
                        onChange={e =>
                          setEditData({
                            ...editData,
                            targetColumn: e.key?.toString(),
                          })
                        }
                        validations={[Rules.Required]}
                        onValidate={setTargetColumnValidity}
                      />
                      <InputFormat
                        label="実績の単位※"
                        placeholder="単位を入力"
                        value={editData.unit}
                        size="middle"
                        maxLength={10}
                        onChange={value => setEditData({ ...editData, unit: value })}
                        validations={[Rules.Required]}
                        onValidate={setUnitValidity}
                      />
                    </CardBody>
                    <hr className="m-0"></hr>
                    <CardBody>
                      <CardTitle className="font-large fw-bold">フィルター</CardTitle>
                      <div className="pb-3">伝票種別などに応じて、より詳細にフィルタリングすることができます。</div>
                      <FilterEdit
                        filterColumns={filterColumns}
                        filter={editData?.filter || []}
                        onChange={filter => setEditData({ ...editData, filter })}
                      />
                    </CardBody>
                  </Card>
                </div>
              )}
              {editData?.connectionType === connectionTypes.Manual && (
                <div>
                  <InputFormat
                    label="実績の単位※"
                    placeholder="単位を入力"
                    value={editData.unit}
                    size="middle"
                    maxLength={10}
                    onChange={value => setEditData({ ...editData, unit: value })}
                    validations={[Rules.Required]}
                    onValidate={setUnitValidity}
                  />
                </div>
              )}
              <div className="font-middle fw-bold py-1 mt-5">デフォルトの人時生産性設定</div>
              <div className="mt-2 mb-1">
                この作業の過去実績がない場合に設定されるデフォルトの人時生産性を設定できます。
                過去実績がなくても作業者管理で各作業者に手動で人時生産性を設定することもできます。
              </div>
              <div className="mt-3 mb-5">
                <InputGroupFormat
                  label="デフォルト値※"
                  addonText={`${editData?.unit || '-'}/時間`}
                  value={editData?.defaultPerformanceIndex}
                  maxLength={10}
                  onChange={value =>
                    editData &&
                    setEditData({
                      ...editData,
                      defaultPerformanceIndex: value,
                    })
                  }
                  validations={[Rules.Required, Rules.PositiveInteger]}
                  onValidate={setDefaultPerformanceIndexValidity}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <SubmitFooter onCancel={() => navigate(-1)} onSubmit={onSubmit} submitDisabled={disabled} />
    </>
  )
}

export default ScheduleTypeCreate
