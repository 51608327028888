import * as React from 'react'

type Props = {
  isOpenSidebar: boolean
  onClick: () => void
}

const SidebarButton: React.FC<Props> = ({ isOpenSidebar, onClick }) => {
  return (
    <div className="d-flex align-items-center font-middle text-muted" onClick={onClick}>
      <i className={`${isOpenSidebar ? 'icf-larger' : 'icf-smaller'} px-2 font-large`} />
      {isOpenSidebar ? '拡大表示' : 'サイドバー表示'}
    </div>
  )
}

export default SidebarButton
