import * as React from 'react'
import { ModalBody, ModalFooter, Button } from 'reactstrap'

import { Modal } from 'components/common'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onDiscard: () => void
}

const EditChangesDiscardDialog: React.FC<Props> = ({ isOpen, onCancel, onDiscard }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody className="font-large fw-bold">変更のキャンセル</ModalBody>

      <ModalBody className="pb-4">これまでの変更内容を破棄して最新の状態に更新します。よろしいですか？</ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          キャンセル
        </Button>
        <Button color="primary" onClick={onDiscard}>
          変更を破棄して更新
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditChangesDiscardDialog
